import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as Muicon from "@mui/icons-material";

const DashboardInfoBoxes = ({ data, title }) => {
  // common styles for all boxes
  const displaying = {
    display: "flex",
    alignItems: "center !important",
    color: "white",
    width: "100%",
  };

  // get icon from mui by its name(from jde)
  const GenerateIcon = (variation, props = {}) => {
    const IconName = Muicon[variation];
    return (
      <IconName
        sx={{ width: "80%", height: "80%", color: "lightgreen" }}
        {...props}
      />
    );
  };

  const isLBP = data?.BCPAYMENTTYPE === "LBP";

  const formatCurrency = (number, currency) => {
    const formattedNumber = number
      .toLocaleString("en-US", {
        style: "currency",
        currency: currency,
        currencyDisplay: "code",
      })
      .replace(currency, "");
    return formattedNumber;
  };

  let totalLBP = 0;
  let totalUSD = 0;

  if (title === "Total") {
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.BCPAYMENTTYPE === "LBP") {
        // Add the price property of the current object to the total
        totalLBP += Number(data[i].BCCOLLECTED);
      } else if (data[i]?.BCPAYMENTTYPE === "USD") {
        totalUSD += data[i].BCPAYMENTCAT1;
      }
    }
  }

  return (
    <Box
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
      sx={{
        width: "17%",
        backgroundColor: title === "Total" ? "#5d778f" : "#064987",
        borderRadius: "7.5px",
        padding: "0.5%",
        marginBottom: "3%",
        marginRight: "2%",
      }}
    >
      <Grid container sx={displaying}>
        {/* display the icon */}
        <Grid item xs={2} sx={displaying}>
          {title !== "Total"
            ? data?.BCPAYMENTCAT2 === "cash"
              ? GenerateIcon("Payments")
              : GenerateIcon("CreditCard")
            : GenerateIcon("Functions")}
        </Grid>
        <Grid item xs={0.5}></Grid>
        {/* display the count and the description */}
        <Grid item xs={9.5}>
          <Grid
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {title}
          </Grid>
          <Grid
            sx={{
              color: !title?.includes("USD") ? "transparent" : "#c1c1c1",
              display:
                title?.includes("Total") || !title?.includes("USD")
                  ? "none"
                  : "block",
            }}
          >
            Sayrafa:{" "}
            {data?.BCSAYRAFARATE
              ? formatCurrency(Number(data?.BCSAYRAFARATE), "LBP")
              : "0"}
          </Grid>
          {!title?.includes("Total") ? (
            <Grid sx={{ color: "#c1c1c1" }}>
              {isLBP ? "Collected" : "Payment"}:{" "}
              {isLBP
                ? data?.BCCOLLECTED
                  ? formatCurrency(Number(data?.BCCOLLECTED), "LBP")
                  : "0"
                : data?.BCPAYMENTCAT1
                ? formatCurrency(Number(data?.BCPAYMENTCAT1?.toFixed(2)), "USD")
                : "$0"}
            </Grid>
          ) : (
            <Grid>
              <Grid>
                LBP:{" "}
                {formatCurrency(
                  totalLBP + totalUSD * data?.[0]?.BCSAYRAFARATE,
                  "LBP"
                )}
              </Grid>
              <Grid>
                USD:{" "}
                {formatCurrency(
                  Number(totalUSD + totalLBP / data?.[0]?.BCSAYRAFARATE),
                  "USD"
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardInfoBoxes;
