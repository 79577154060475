import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import LeaveRequestFilter from "./Filter";

const LeaveRequestComponent = () => {
  const dispatch = useDispatch();
  // get socket io
  const socketHR = useSelector((state) => state.menu.socketHR);
  // check loading when get data
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box>
      {socketHR !== null && (
        <Grid>
          <LeaveRequestFilter setIsLoading={setIsLoading} />
          {/* {!isLoading ? (
            <BodyHRComponent />
          ) : (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )} */}
        </Grid>
      )}
    </Box>
  );
};

export default LeaveRequestComponent;
