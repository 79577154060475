import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import {
  setLeaveRequestsList,
  setSearchRequests,
} from "../../../../../../Services/redux/reducers/superMyHRReducer";
import { MUICheckbox } from "../../../Styles/overrides";

const LeaveRequestFilter = ({
  setIsLoading,
  setOpen,
  setOpenAddressNbrSearch,
  getBalance,
  type,
}) => {
  const leaveTypes = useSelector((state) => state.superMyHR.leaveTypes);
  const leaveReasons = useSelector((state) => state.superMyHR.leaveReasons);
  const searchRequests = useSelector((state) => state.superMyHR.searchRequests);
  const userInfo = useSelector((state) => state.menu.userInfo);
  const socketHR = useSelector((state) => state.menu.socketHR);

  const alignmenmt = {
    display: "flex",
    alignItems: "center",
    ".css-sde65v-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "36.7px",
    },
    ".css-11fo197": {
      color: "white",
    },
    ".css-vamk89-MuiFormControl-root": { width: "100%" },
  };

  const heightForm = {
    '.css-11do72n-MuiGrid-root input[type="text"], .css-11do72n-MuiGrid-root, input[type="password"], .css-11do72n-MuiGrid-root, .css-1dxv3pf-MuiGrid-root, .css-11do72n-MuiGrid-root, .react-tel-input, .css-11do72n-MuiGrid-root, .flag-dropdown, .css-11do72n-MuiGrid-root .css-1dxv3pf-MuiGrid-root, .css-11do72n-MuiGrid-root, .react-tel-input, .css-11do72n-MuiGrid-root, .form-control, .css-11do72n-MuiGrid-root, label[data-shrink=false]+.MuiInputBase-formControl, .css-11do72n-MuiGrid-root, .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
      {
        minHeight: "100%",
      },
  };

  const dispatch = useDispatch();
  // handle change for the filter
  const handleChange = (e, item, leave) => {
    if (leave) {
      const length =
        e?.length - 1 ===
        (item === "leaveType" ? leaveTypes?.length : leaveReasons?.length);
      if (e?.includes("all") && !length) {
        dispatch(
          setSearchRequests({
            ...searchRequests,
            [item]: (item === "leaveType" ? leaveTypes : leaveReasons)?.map(
              (l) => l?.UVVALUE
            ),
          })
        );
      } else if (length) {
        dispatch(
          setSearchRequests({
            ...searchRequests,
            [item]: [],
          })
        );
      } else {
        dispatch(
          setSearchRequests({
            ...searchRequests,
            [item]: e,
          })
        );
      }
    } else {
      dispatch(setSearchRequests({ ...searchRequests, [item]: e }));
    }
  };
  // get the list of leave requests
  function getData() {
    setIsLoading(true);
    socketHR.emit(
      "dspinternal:web:hr:spv:v1",
      {
        request: "hrForms",
        hrForms: {
          type: "leaveData",
          leaveData: {
            action: "getData",
            addressNbr: searchRequests?.addressNbr?.split(","),
            leaveType: searchRequests?.leaveType,
            reqReason: searchRequests?.leaveReason,
            fromDate: searchRequests?.fromDate,
            toDate: searchRequests?.toDate,
          },
        },
      },
      (response) => {
        setIsLoading(false);
        dispatch(setLeaveRequestsList(response?.data));
      }
    );
  }
  // to open a new request pop up
  const openNewReqPopUp = () => {
    if (type === "myHR") {
      getBalance([userInfo?.ABLINEID]);
    }
    setOpen(true);
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={alignmenmt}>
        <Grid
          container
          sx={{
            marginBottom: "1%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <TextField
            sx={{ width: "8%" }}
            autoComplete="off"
            value={searchRequests?.addressNbr ? searchRequests?.addressNbr : ""}
            onChange={(e) => handleChange(e.target.value, "addressNbr")}
            // onBlur={(e) => onCheckAddressNbr(e?.target?.value)}
            fullWidth
            label="No."
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={() =>
                      setOpenAddressNbrSearch({
                        isOpen: true,
                        type: "filterSearch",
                      })
                    }
                    sx={{ width: "auto", minWidth: "0px" }}
                  >
                    <SearchIcon sx={{ color: "#064987" }} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <FormControl
            style={{
              width: "15%",
            }}
            sx={heightForm}
            variant="outlined"
            size="small"
          >
            <InputLabel>Leave Type</InputLabel>
            <Select
              multiple
              sx={{ width: "100%" }}
              width="100%"
              value={searchRequests?.leaveType ? searchRequests?.leaveType : []}
              onChange={(e) => handleChange(e.target.value, "leaveType", true)}
              input={<OutlinedInput label="Leave Type" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    searchRequests?.leaveType?.length === leaveTypes?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {leaveTypes?.map((lt) => (
                <MenuItem key={lt?.UVVALUE} value={lt?.UVVALUE}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      searchRequests?.leaveType?.indexOf(lt?.UVVALUE) > -1
                    }
                  />
                  <ListItemText primary={`${lt?.UVVALUE} - ${lt?.UVDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{
              width: "15%",
            }}
            sx={heightForm}
            variant="outlined"
            size="small"
          >
            <InputLabel>Leave Reasons</InputLabel>
            <Select
              multiple
              sx={{ width: "100%" }}
              width="100%"
              value={
                searchRequests?.leaveReason ? searchRequests?.leaveReason : []
              }
              onChange={(e) =>
                handleChange(e.target.value, "leaveReason", true)
              }
              input={<OutlinedInput label="Leave Reasons" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    searchRequests?.leaveReason?.length === leaveReasons?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {leaveReasons?.map((lt) => (
                <MenuItem key={lt?.UVVALUE} value={lt?.UVVALUE}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      searchRequests?.leaveReason?.indexOf(lt?.UVVALUE) > -1
                    }
                  />
                  <ListItemText primary={`${lt?.UVVALUE} - ${lt?.UVDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid sx={{ width: "20%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{
                  textField: {
                    size: "small",
                    id: "fromdate",
                    label: "From Date",
                  },
                }}
                maxDate={dayjs(searchRequests?.toDate)}
                format="YYYY-MM-DD"
                value={dayjs(searchRequests?.fromDate)}
                onChange={(e) =>
                  handleChange(moment(e?.$d).format("YYYY-MM-DD"), "fromDate")
                }
                sx={{
                  ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                    color: "black",
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid sx={{ width: "20%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{
                  textField: { size: "small", id: "toDate", label: "To Date" },
                }}
                minDate={dayjs(searchRequests?.fromDate)}
                sx={{
                  ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                    color: "black",
                  },
                  width: "100%",
                }}
                format="YYYY-MM-DD"
                value={dayjs(searchRequests?.toDate)}
                onChange={(e) =>
                  handleChange(moment(e?.$d).format("YYYY-MM-DD"), "toDate")
                }
              />
            </LocalizationProvider>
          </Grid>
          <Button
            sx={{
              backgroundColor: "#064987",
              width: "5%",
              color: "white",
            }}
            size="small"
            onClick={() => getData()}
          >
            Search
          </Button>
          <Button
            sx={{
              backgroundColor: "#064987",
              width: "10%",
              color: "white",
            }}
            onClick={openNewReqPopUp}
          >
            New Request
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeaveRequestFilter;
