import React from "react";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  setHRFilter,
  setHolidaysList,
} from "../../../../../../Services/redux/reducers/HRReducer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

const FilterHolidaysReturn = ({ filterInfo, setIsLoading, setSearched }) => {
  const alignmenmt = {
    display: "flex",
    alignItems: "center",
    ".css-sde65v-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "36.7px",
    },
    ".css-11fo197": {
      color: "white",
    },
    ".css-vamk89-MuiFormControl-root": { width: "100%" },
  };

  const dispatch = useDispatch();
  const socketHR = useSelector((state) => state.menu.socketHR);
  const HRFilter = useSelector((state) => state.hr.HRFilter);

  const handleChange = (e, item) => {
    dispatch(
      setHRFilter({
        ...HRFilter,
        [item]: e,
      })
    );
  };

  function getData() {
    setIsLoading(true);
    socketHR.emit(
      "dspinternal:web:hr:admin:v1",
      {
        request: "hrData",
        hrData: {
          type: "holidaysData",
          holidaysData: {
            action: "getData",
            fromDate: filterInfo?.fromdate,
            toDate: filterInfo?.todate,
            search: filterInfo?.search,
          },
        },
      },
      (response) => {
        dispatch(setHolidaysList(response?.hrData?.holidaysData?.getData));
        setSearched(true);
        setIsLoading(false);
      }
    );
  }

  return (
    <Grid container sx={alignmenmt}>
      <Grid container sx={{ marginBottom: "1%" }}>
        <Grid item xs={3} sx={alignmenmt}>
          <TextField
            fullWidth
            id="search"
            value={HRFilter.search}
            onChange={(e) => handleChange(e.target.value, "search")}
            label="Search"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter Search Text.."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={1.5 / 3}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={dayjs(filterInfo?.todate)}
              slotProps={{
                textField: {
                  size: "small",
                  id: "fromdate",
                  label: "From Date",
                },
              }}
              format="YYYY-MM-DD"
              value={dayjs(filterInfo?.fromdate)}
              onChange={(e) =>
                handleChange(moment(e?.$d).format("YYYY-MM-DD"), "fromdate")
              }
              sx={{
                ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1.5 / 3}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              minDate={dayjs(filterInfo?.fromdate)}
              slotProps={{
                textField: { size: "small", id: "todate", label: "To Date" },
              }}
              sx={{
                ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
              format="YYYY-MM-DD"
              value={dayjs(filterInfo?.todate)}
              onChange={(e) =>
                handleChange(moment(e?.$d).format("YYYY-MM-DD"), "todate")
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1.5 / 3}></Grid>
        <Grid item xs={1} sx={alignmenmt}>
          <Button
            sx={{
              backgroundColor: "#064987",
              width: "100%",
              color: "white",
            }}
            onClick={() => getData()}
          >
            Search
          </Button>
        </Grid>{" "}
        {/* <Grid item xs={0.5}></Grid>
        <Grid item xs={1.5} sx={alignmenmt}>
          <Button
            sx={{
              backgroundColor: "#064987",
              width: "100%",
              color: "white",
            }}
            // onClick={() => getData(filterInfo)}
          >
            New Holiday
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default FilterHolidaysReturn;
