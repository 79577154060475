import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style/loginFrm.css";

import AuthService from "../../../Services/http/auth.service";
import {
  setUserInfo,
  setUserMenu,
  setUserAll,
  setUserData,
  setUser,
  setSocket,
  setSnackBarForgetPassword,
  setSocketMRBC,
  setSocketHR,
  setSocketAdmin,
  setSocketLabels,
} from "../../../Services/redux/reducers/userMenuReducer";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";

import { jwtToken } from "./../../../Services/GlobalVar/commonVar";
import Cookies from "universal-cookie";
import SocketIO from "../../../Services/SocketIO";
import CustomizedSnackbars from "../Main/components/NotificationAlert";

function LoginForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const setUser = props.setUser;
  const [httpError, setHttpError] = useState("");
  const cookies = new Cookies();
  const snackbarForgetPassword = useSelector(
    (state) => state.menu.snackbarForgetPassword
  );

  const handleCookie = (name, data) => {
    cookies.set(name, data, {
      path: "/",
      expires: new Date(Date.now() + 2592000),
    });
  };

  // Handling button to submit login info
  const handleSubmit = (event) => {
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    AuthService.login(uname.value, pass.value).then(
      (response) => {
        if (response?.data?.reply === "userdata") {
          jwtToken[0] = response?.data?.userdata?.token;
          const socket = SocketIO(
            response?.data?.userdata?.token,
            process.env.REACT_APP_API_SOCKETIO_PATH,
            true
          );
          var y = response;
          console.log("Y : ");
          console.log(y);
          localStorage.setItem("user", JSON.stringify(y));

          var _userMenu = response?.data?.userdata?.usermenu;
          var _userInfo = response?.data?.userdata?.userinfo;
          var _userAll = {
            userid: response?.data?.userid,
            token: response?.data?.userdata?.token,
            ABFULLNAME: response?.data?.userdata?.userinfo?.ABFULLNAME,
          };

          var i;
          var j;
          var k;
          var _uniquePagesLabel = [];
          var _labels = [];

          for (i = 0; i < _userMenu?.length; i++) {
            for (j = 0; j < _userMenu?.[i]?.sections?.length; j++) {
              for (
                k = 0;
                k < _userMenu?.[i]?.sections?.[j]?.pages?.length;
                k++
              ) {
                _labels[i] =
                  _userMenu?.[i]?.sections?.[j]?.pages?.[k]?.BJLABELS;
                _uniquePagesLabel = _labels.reduce((unique, o) => {
                  if (!unique.some((obj) => obj === o)) {
                    unique.push(o);
                  }
                  return unique;
                }, []);
              }
            }
          }
          const filterLabels = (label) => {
            const filtered = _uniquePagesLabel.filter((e) => {
              if (e?.indexOf(",") > -1) {
                const isTrue =
                  e?.split(", ")?.[0] === label ||
                  e?.split(", ")?.[1] === label;
                return isTrue;
              } else {
                return e?.includes(label);
              }
            });
            return filtered?.length > 0;
          };

          if (filterLabels("mrbc")) {
            const socketmrbc = SocketIO(
              response?.data?.userdata?.token,
              process.env.REACT_APP_API_SOCKETIOMRBC_PATH,
              false
            ).connect();
            dispatch(setSocketMRBC(socketmrbc));
          }
          if (filterLabels("hr")) {
            const socketHR = SocketIO(
              response?.data?.userdata?.token,
              process.env.REACT_APP_API_SOCKETIOHR_PATH,
              false
            ).connect();
            dispatch(setSocketHR(socketHR));
          }
          if (filterLabels("admin")) {
            const socketadmin = SocketIO(
              response?.data?.userdata?.token,
              process.env.REACT_APP_API_SOCKETIOADMIN_PATH,
              false
            ).connect();
            dispatch(setSocketAdmin(socketadmin));
          }

          localStorage.setItem("_userMenu", JSON.stringify(_userMenu));
          localStorage.setItem("_userData", JSON.stringify(y?.data));

          handleCookie("_userMenu", JSON.stringify(_userMenu));
          handleCookie("_userData", y?.data);
          handleCookie("_socketLabels", _uniquePagesLabel);
          handleCookie("_userAll", _userAll);
          handleCookie("_userInfo", _userInfo);
          handleCookie("_selectedPage", _userMenu?.[0]?.AIDESC);
          handleCookie("_selectedApp", _userMenu?.[0]?.AIDESC);
          handleCookie("_currpagedesc", _userMenu?.[0]?.AIDESC);
          handleCookie("_user", true);
          handleCookie("_location", "/main");

          dispatch(setUserMenu(_userMenu));
          dispatch(setSocketLabels(_uniquePagesLabel));
          dispatch(setUserData(y?.data));
          dispatch(setUserAll(_userAll));
          dispatch(setUserInfo(_userInfo));
          dispatch(setUser(true));
          dispatch(setSocket(socket));
        } else if (response?.data?.reply === "usererror") {
          setHttpError("Invalid username or password.");
        }
      },
      (error) => {
        console.log("error login");
        console.log(error);
      }
    );
  };

  // Handling button to forget password page
  const handleForget = () => {
    navigate("/forgetPassword");
  };

  // JSX code for login form interface
  const renderForm = (
    <div className="formDiv">
      <form className="form">
        <div>
          <img alt="" className="logo001" src="./logmrad.png" />
        </div>
        <div className="formDiv001">
          <div className="title">Sign In</div>
          {httpError.length > 0 && <div className="error">{httpError}</div>}
          <div className="input-container">
            <label>Username </label>
            <input type="text" name="uname" required />
          </div>
          <div className="input-container">
            <label>Password </label>
            <input type="password" name="pass" required />
          </div>
          <div className="input-container">
            <button
              className="forget-button"
              onClick={handleForget}
              type="button"
            >
              Forget Password?
            </button>
          </div>
          <div className="button-container">
            <button
              className="submit-button"
              onClick={handleSubmit}
              type="submit"
            >
              Sign In
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <Box widht="100%" height="100vh">
      {/* <div className="app">{renderForm}</div> */}

      {/* {localStorage.getItem("user") ? ( */}
      <div className="app">{renderForm}</div>
      {/* ) : (
        <div></div>
      )} */}
      {snackbarForgetPassword?.open && (
        <CustomizedSnackbars
          open={snackbarForgetPassword?.open}
          handleClose={() =>
            dispatch(
              setSnackBarForgetPassword({
                open: false,
                severity: "",
                message: "",
              })
            )
          }
          severity={snackbarForgetPassword?.severity}
          message={snackbarForgetPassword?.message}
        />
      )}
    </Box>
  );
}

export default LoginForm;
