import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterComponent from "../Filter";
import { useDispatch, useSelector } from "react-redux";
import allData from "../../../../../../Services/Data";
import MIPopUp from "../map&imagePopUp/MIPopup";
import AlertDialog from "../../../components/AlertDialog";
import {
  setNotificationSnackBar,
  setUser,
} from "../../../../../../Services/redux/reducers/userMenuReducer";
import gettingData from "../Services/supervisorsAccess";

const ControlsComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const divisionData = useSelector((state) => state.menu.divisionData);
  const [controlData, setControlData] = useState([]);
  const [openRoundData, setOpenRoundData] = useState([]);
  const [lockReadingData, setLockReadingData] = useState([]);

  const [typeControl, setTypeControl] = useState("");
  const [lockType, setLockType] = useState("");
  const [open, setOpen] = useState(false);
  const [openRound, setOpenRound] = useState(false);
  const [lockReading, setLockReading] = useState(false);
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.menu.socket);

  useEffect(() => {
    gettingData.superAccessData(socket, dispatch);
  }, [socket]);

  const handleClose = () => {
    setOpen(false);
    setOpenRound(false);
    setLockReading(false);
  };

  function getControlsData(req, x, isLocation, isLockRound) {
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        req,
        x,
        "mrbc/spv/v1/control"
      )
      .then(
        (response) => {
          setControlData(response);
          if (isLocation === true) {
            setOpen(true);
          } else if (isLockRound) {
            setLockReading(false);
            dispatch(
              setNotificationSnackBar({
                open: true,
                message: "Readings Locked Successfully!",
                type: "success",
              })
            );
          } else {
            setOpenRound(false);
            dispatch(
              setNotificationSnackBar({
                open: true,
                message: "Round Opened Successfully!",
                type: "success",
              })
            );
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
        }
      );
  }

  const controlsJSON = [
    {
      title: "Open Round",
      content: (
        <Grid>
          <FilterComponent
            divisionData={divisionData}
            getData={(x) => {
              setOpenRoundData(x);
              setOpenRound(true);
            }}
            type={"controls"}
            typeControl={typeControl}
            setTypeControl={setTypeControl}
            wichControl={"openRound"}
          />
          <AlertDialog
            open={openRound}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() =>
              getControlsData("openround", openRoundData, false)
            }
          />
        </Grid>
      ),
    },
    {
      title: "Get Location",
      content: (
        <Grid>
          <FilterComponent
            divisionData={divisionData}
            getData={(x) => getControlsData("crlivecoordinates", x, true)} //crlivecoordinates
            type={"controls"}
            wichControl={"getLocation"}
            setOpen={setOpen}
          />
          <MIPopUp
            open={open}
            handleClose={handleClose}
            isMap={true}
            openPath={false}
            detailsData={controlData?.data?.crlivecoordinates}
          />
        </Grid>
      ),
    },
    {
      title: "Lock Reading",
      content: (
        <Grid>
          <FilterComponent
            divisionData={divisionData}
            getData={(x) => {
              setLockReadingData(x);
              setLockReading(true);
            }}
            type={"controls"}
            typeControl={lockType}
            setTypeControl={setLockType}
            wichControl={"lockReading"}
          />
          <AlertDialog
            open={lockReading}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() =>
              getControlsData("lockReadings", lockReadingData, false, true)
            }
          />
        </Grid>
      ),
    },
  ];

  return (
    <Box>
      {controlsJSON.map((data, index) => (
        <Box key={index}>
          <Grid
            sx={{
              backgroundColor: "#064987",
              color: "white",
              height: "30px",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {data.title}
          </Grid>
          {data.content}
        </Box>
      ))}
    </Box>
  );
};

export default ControlsComponent;
