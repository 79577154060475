import React, { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  OutlinedInput,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TitlesJSON } from "../../../subUsers/JSONFiles/titles";
import { useSelector } from "react-redux";

const AddAdminContent = (props) => {
  const handlingDetails = props.handlingDetails;
  const details = props.details;
  const errorMessage = props.errorMessage;
  const fullnameRegex = /^[A-Za-z]+$/;
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const idRegex = /^[0-9\b]+$/;
  const businessUnits = useSelector((state) => state.admins.businessUnits);
  const departments = useSelector((state) => state.admins.departments);
  const positions = useSelector((state) => state.admins.positions);

  const [searchTerm, setSearchTerm] = useState({
    bu: "",
    dept: "",
    post: "",
  });

  const handleSearchChange = (event, item) => {
    setSearchTerm({
      ...searchTerm,
      [item]: event.target.value.toLowerCase(),
    });
    // setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredBUOptions = businessUnits?.filter((option) =>
    option.UVDESC.toLowerCase().includes(searchTerm?.bu)
  );
  const filteredDeptOptions = departments?.filter((option) =>
    option.UVDESC.toLowerCase().includes(searchTerm?.dept)
  );
  const filteredPostOptions = positions?.filter((option) =>
    option.UVDESC.toLowerCase().includes(searchTerm?.post)
  );

  return (
    <Grid
      container
      sx={{
        marginTop: "1%",
        'input[type="text"], input[type="password"],.css-1dxv3pf-MuiGrid-root, .react-tel-input, .flag-dropdown,.css-1dxv3pf-MuiGrid-root, .react-tel-input, .form-control, label[data-shrink=false]+.MuiInputBase-formControl, .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
          {
            backgroundColor: "#e5e5e5",
            borderRadius: "8px",
          },
        ".css-112ysrj-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled": {
          color: "#757474",
        },
      }}
    >
      <Grid container>
        <Grid item xs={1.5}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Title</InputLabel>
            <Select
              label="Title"
              width="100%"
              value={details.title}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "title",
                  "Title",
                  e.target.value !== ""
                )
              }
            >
              {TitlesJSON.map((data) => (
                <MenuItem key={data.value} value={data.value}>
                  {data.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1 / 3}></Grid>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            id="addressNumber"
            label="Address Number"
            helperText={errorMessage.addressNumber}
            value={details.addressNumber ? details.addressNumber : ""}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "addressNumber",
                "Address Number",
                e.target.value !== "" || fullnameRegex.test(e.target.value)
              )
            }
          />
        </Grid>
        <Grid item xs={1 / 3}></Grid>
        <Grid item xs={3.5}>
          <TextField
            fullWidth
            id="fullname"
            label="Full Name"
            helperText={errorMessage.fullName}
            value={details.fullName}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "fullName",
                "Full Name",
                e.target.value !== ""
              )
            }
          />
        </Grid>
        <Grid item xs={1 / 3}></Grid>
        <Grid item xs={4.5}>
          <TextField
            fullWidth
            id="email"
            helperText={errorMessage.email}
            label="Email"
            value={details.email}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "email",
                "Email",
                e.target.value !== "" && emailRegex.test(e.target.value)
              )
            }
          />
        </Grid>

        <Grid item xs={1.5 / 3}></Grid>
      </Grid>
      <Grid container marginY={"2%"}>
        <Grid
          item
          xs={3.5}
          sx={{
            ".react-tel-input .form-control": {
              width: "100%",
              height: "60px",
              border: "1px solid black",
            },
            ".react-tel-input .flag-dropdown": {
              border: "1px solid black !important",
            },
          }}
        >
          <PhoneInput
            country={details.phoneCode}
            value={details.phoneNumber}
            onChange={(e, f) => {
              handlingDetails.handleChange(
                e,
                "phoneNumber",
                "Phone Number",
                e !== "",
                f.dialCode,
                "phoneCode"
              );
            }}
          />
        </Grid>
        <Grid item xs={1 / 3}></Grid>
        <Grid item xs={2.5}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Business Unit</InputLabel>
            <Select
              label="Business Unit"
              width="100%"
              value={details.bu ? details.bu : ""}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "bu",
                  "Business Unit",
                  e.target.value !== ""
                )
              }
              input={<OutlinedInput label="Business Unit" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  'input[type="text"], input[type="password"]': {
                    color: "white !important",
                  },
                }}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
              >
                <TextField
                  variant="standard"
                  placeholder="Search..."
                  onChange={(e) => handleSearchChange(e, "bu")}
                  fullWidth
                  onClick={(event) => event.stopPropagation()} // Also here, just to be safe
                />
              </MenuItem>
              {filteredBUOptions.map((data) => (
                <MenuItem key={data.UVVALUE} value={data.UVVALUE}>
                  {data.UVDESC}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1 / 3}></Grid>
        <Grid item xs={2.5}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Department</InputLabel>
            <Select
              label="Department"
              width="100%"
              value={details.dpt ? details.dpt : ""}
              input={<OutlinedInput label="Department" />}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "dpt",
                  "Department",
                  e.target.value !== ""
                )
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  'input[type="text"], input[type="password"]': {
                    color: "white !important",
                  },
                }}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
              >
                <TextField
                  variant="standard"
                  placeholder="Search..."
                  onChange={(e) => handleSearchChange(e, "dept")}
                  fullWidth
                  onClick={(event) => event.stopPropagation()} // Also here, just to be safe
                />
              </MenuItem>
              {filteredDeptOptions.map((data) => (
                <MenuItem key={data.UVVALUE} value={data.UVVALUE}>
                  {data.UVDESC}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1 / 3}></Grid>
        <Grid item xs={2.5}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Position</InputLabel>
            <Select
              label="Position"
              width="100%"
              value={details.post ? details.post : ""}
              input={<OutlinedInput label="Position" />}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "post",
                  "Position",
                  e.target.value !== ""
                )
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  'input[type="text"], input[type="password"]': {
                    color: "white !important",
                  },
                }}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
              >
                <TextField
                  variant="standard"
                  placeholder="Search..."
                  onChange={(e) => handleSearchChange(e, "post")}
                  fullWidth
                  onClick={(event) => event.stopPropagation()} // Also here, just to be safe
                />
              </MenuItem>
              {filteredPostOptions.map((data) => (
                <MenuItem key={data.UVVALUE} value={data.UVVALUE}>
                  {data.UVDESC}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container marginY={"2%"}>
        <Grid item xs={11 / 3}>
          <TextField
            fullWidth
            id="address1"
            label="Address 1"
            helperText={errorMessage.address1}
            value={details.address1}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "address1",
                "Address 1",
                e.target.value !== ""
              )
            }
          />
        </Grid>
        <Grid item xs={1 / 2}></Grid>
        <Grid item xs={11 / 3}>
          <TextField
            fullWidth
            id="address2"
            label="Address 2 (Optional)"
            value={details.address2}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "address2",
                "Address 2",
                e.target.value !== ""
              )
            }
          />
        </Grid>
        <Grid item container xs={11 / 3 + 1 / 2}>
          <Grid item xs={1.5}></Grid>
          <Grid item xs={10.5}>
            <TextField
              fullWidth
              id="idnumber"
              label="ID Number"
              helperText={errorMessage.idNumber}
              value={details.idNumber}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "idNumber",
                  "ID Number",
                  (e.target.value !== "" || idRegex.test(e.target.value)) &&
                    (e.target.value?.length === 14 || e.target.value === "")
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddAdminContent;
