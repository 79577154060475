import { createSlice } from "@reduxjs/toolkit";

const adminsInitialState = {
  pagesData: [],
  updatedPagesData: [],
  editedPagesData: [],
  allEdits: [],
  addedPages: [],
  removedPages: [],
  addedSections: [],
  addedApplications: [],
  businessUnits: [],
  departments: [],
  positions: [],
  usersSecurityList: [],
  usersSecurityListChanges: [],
};

export const adminsSlice = createSlice({
  name: "admins",
  initialState: adminsInitialState,
  reducers: {
    setPagesData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.pagesData = action.payload;
    },
    setUpdatedPagesData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.updatedPagesData = action.payload;
    },
    setEditedPagesData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload?.type === "newEdit") {
        state.editedPagesData.push(action.payload);
      } else if (action.payload?.type === "newSectionEdit") {
        state.editedPagesData[action.payload.index].sections.push(
          action.payload
        );
      } else if (action.payload?.type === "newAppEdit") {
        state.editedPagesData[action.payload.index].sections[
          action.payload.sectionIndex
        ].pages.push(action.payload);
      } else {
        state.editedPagesData = action.payload;
      }
    },
    setAllEdits: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload?.action === "add") {
        state.allEdits.push(action.payload);
      } else if (action.payload?.action === "remove") {
        if (action.payload?.type === "page") {
          state.allEdits = state.allEdits.filter(
            (item) => item.AILINEID !== action.payload.AILINEID
          );
          state.allEdits.push(action.payload);
        } else {
          state.allEdits = state.allEdits.filter(
            (item) => item.BJLINEID !== action.payload.AILINEID
          );
          if (action.payload.old === true) {
            state.allEdits.push(action.payload);
          }
        }
      } else if (action.payload?.action === "update") {
        const old = state.allEdits?.filter(
          (all) => all?.BJLINEID === action.payload.BJLINEID
        );
        state.allEdits.push(action.payload);
      } else {
        if (action.payload.old === true) {
          state.allEdits.push(action.payload);
        } else {
          state.allEdits = action.payload;
        }
      }
    },
    setAddedPages: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.addedPages.push(action.payload);
    },
    setRemovedPages: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.removedPages.push(action.payload);
    },
    setAddedSections: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.addedSections.push(action.payload);
    },
    setAddedApplications: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.addedApplications.push(action.payload);
    },
    setBusinessUnits: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.businessUnits = action.payload;
    },
    setDepartments: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.departments = action.payload;
    },
    setPositions: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.positions = action.payload;
    },
    setUsersSecurityList: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.usersSecurityList = action.payload;
    },
    setUsersSecurityListChanges: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload.type === "newChanges") {
        state.usersSecurityListChanges.push(action.payload);
      } else {
        state.usersSecurityListChanges = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPagesData,
  setUpdatedPagesData,
  setEditedPagesData,
  setAllEdits,
  setAddedPages,
  setRemovedPages,
  setAddedSections,
  setAddedApplications,
  setBusinessUnits,
  setDepartments,
  setPositions,
  setUsersSecurityList,
  setUsersSecurityListChanges,
} = adminsSlice.actions;

export default adminsSlice.reducer;
