import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Table from "../../../../table/Table";

const SaveChangesReview = () => {
  const allEdits = useSelector((state) => state.admins.allEdits);

  let i;
  let j;
  let k;
  let _allEditsData = [];
  let _allEditsSectionsData = [];
  let _allEditsAppsData = [];

  const allEditsColumns = [
    { field: "AILINEID", headerName: "AILINEID", width: 100 },
    { field: "BJPAGEID", headerName: "BJPAGEID", width: 200 },
    { field: "BJSECTIONID", headerName: "BJSECTIONID", width: 200 },
    { field: "action", headerName: "Action", width: 200 },
    { field: "TYPE", headerName: "type", width: 200 },
    { field: "AIDESC", headerName: "AIDESC", width: 200 },
    { field: "AIICON", headerName: "AIICON", width: 200 },
    { field: "AIPLATFORM", headerName: "AIPLATFORM", width: 200 },
    { field: "AISTATUS", headerName: "AISTATUS", width: 200 },
    { field: "AITEXT", headerName: "AITEXT", width: 200 },
    { field: "AIVALUE", headerName: "AIVALUE", width: 200 },
    { field: "BJDESC", headerName: "BJDESC", width: 200 },
    { field: "BJIMAGE", headerName: "BJIMAGE", width: 200 },
    { field: "BJTITLE", headerName: "BJTITLE", width: 200 },
  ];

  for (i = 0; i < allEdits?.length; i++) {
    _allEditsData[i] = {
      id: Math.random(),
      AILINEID: allEdits?.[i]?.AILINEID,
      BJPAGEID: allEdits?.[i]?.BJPAGEID,
      TYPE: allEdits?.[i]?.type,
      action: allEdits?.[i]?.action,
      AIDESC: allEdits?.[i]?.AIDESC,
      AIICON: allEdits?.[i]?.AIICON,
      AIPLATFORM: allEdits?.[i]?.AIPLATFORM,
      AISTATUS: allEdits?.[i]?.AISTATUS,
      AITEXT: allEdits?.[i]?.AITEXT,
      AIVALUE: allEdits?.[i]?.AIVALUE,
      BJDESC: allEdits?.[k]?.BJDESC,
      BJIMAGE: allEdits?.[k]?.BJIMAGE,
      BJSECTIONID: allEdits?.[k]?.BJSECTIONID,
      BJTITLE: allEdits?.[k]?.BJTITLE,
    };
  }

  return (
    <Box>
      <Grid>
        {_allEditsData?.length > 0 && (
          <Grid>
            <Grid>
              <Table columns={allEditsColumns} _newData={_allEditsData} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid></Grid>
    </Box>
  );
};

export default SaveChangesReview;
