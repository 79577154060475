import { Button, Grid } from "@mui/material";
import { HandsontableTableComponent } from "../../../../components/HandsontableTable";
import { useDispatch, useSelector } from "react-redux";
import {
  setMasterInfoData,
  setMasterInfoDataChanges,
} from "../../../../../../../Services/redux/reducers/HRReducer";
import { useState } from "react";
import AlertDialog from "../../../../components/AlertDialog";
import { setNotificationSnackBar } from "../../../../../../../Services/redux/reducers/userMenuReducer";

export const BodyMasterInfo = () => {
  const masterInfoData = useSelector((state) => state.hr.masterInfoData);
  const socketHR = useSelector((state) => state.menu.socketHR);
  const masterInfoDataChanges = useSelector(
    (state) => state.hr.masterInfoDataChanges
  );
  const dispatch = useDispatch();
  const businessUnits = useSelector((state) => state.admins.businessUnits);
  const departments = useSelector((state) => state.admins.departments);
  const positions = useSelector((state) => state.admins.positions);
  const [openSure, setOpenSure] = useState(false);

  const columnHeaders = [
    "ID",
    "ID2",
    "Name",
    "Business Unit ID",
    "Business Unit",
    "Department ID",
    "Department",
    "Position ID",
    "Position",
    "Email",
    "Phone Number",
  ];
  var i;
  var _masterInfoData = [];

  const columns = [
    {
      data: "id",
      editor: false,
    },
    {
      data: "id2",
      editor: "text",
    },
    {
      data: "name",
      editor: "text",
    },
    {
      title: "Business Units ID",
      // set the type of the 'Size' column
      type: "dropdown",
      data: "bfbu",
      source: businessUnits?.map((data) => data?.UVVALUE),
      className: "htCenter",
    },
    {
      data: "bu",
      editor: false,
    },
    {
      title: "Departments ID",
      // set the type of the 'Size' column
      type: "dropdown",
      data: "bfdpt",
      source: departments?.map((data) => data?.UVVALUE),
      className: "htCenter",
    },
    {
      data: "dep",
      editor: false,
    },
    {
      title: "Position ID",
      // set the type of the 'Size' column
      type: "dropdown",
      data: "bfpost",
      source: positions?.map((data) => data?.UVVALUE),
      className: "htCenter",
    },
    {
      data: "pos",
      editor: false,
    },
    {
      data: "email",
      editor: "text",
    },
    {
      data: "pnb",
      editor: "numeric",
    },
  ];

  for (i = 0; i < masterInfoData.length; i++) {
    const state = masterInfoDataChanges?.filter(
      (data) => data?.ABLINEID === masterInfoData?.[i]?.ABLINEID
    )?.[0];
    _masterInfoData[i] = {
      id: masterInfoData?.[i]?.ABLINEID,
      id2:
        state && "ABID2" in state ? state?.ABID2 : masterInfoData?.[i]?.ABID2,
      name:
        state && "ABFULLNAME" in state
          ? state?.ABFULLNAME
          : masterInfoData?.[i]?.ABFULLNAME,
      bfbu: state && "BFBU" in state ? state?.BFBU : masterInfoData?.[i]?.BFBU,
      bu:
        state && "BUDESC" in state
          ? state?.BUDESC
          : masterInfoData?.[i]?.BUDESC,
      bfdpt:
        state && "BFDPT" in state ? state?.BFDPT : masterInfoData?.[i]?.BFDPT,
      dep:
        state && "DPTDESC" in state
          ? state?.DPTDESC
          : masterInfoData?.[i]?.DPTDESC,
      bfpost:
        state && "BFPOST" in state
          ? state?.BFPOST
          : masterInfoData?.[i]?.BFPOST,
      pos:
        state && "POSTDESC" in state
          ? state?.POSTDESC
          : masterInfoData?.[i]?.POSTDESC,
      email:
        state && "ABEMAIL" in state
          ? state?.ABEMAIL
          : masterInfoData?.[i]?.ABEMAIL,
      pnb:
        state && "ABPHONENBR" in state
          ? state?.ABPHONENBR
          : masterInfoData?.[i]?.ABPHONENBR,
    };
  }

  const onGridChange = (e, currentTodos, currentPage, todosPerPage) => {
    const currentIndex =
      (currentPage - 1) * todosPerPage +
      (todosPerPage - (todosPerPage - e?.[0]?.[0]));
    const changedInfo = masterInfoData[currentIndex];
    const changeIndex = masterInfoDataChanges?.findIndex(
      (ob) => ob?.ABLINEID === changedInfo?.ABLINEID
    );
    const headerChanged =
      (e?.[0]?.[1] === "id2" && "ABID2") ||
      (e?.[0]?.[1] === "name" && "ABFULLNAME") ||
      (e?.[0]?.[1] === "bu" && "BUDESC") ||
      (e?.[0]?.[1] === "dep" && "DPTDESC") ||
      (e?.[0]?.[1] === "pos" && "POSTDESC") ||
      (e?.[0]?.[1] === "bfbu" && "BFBU") ||
      (e?.[0]?.[1] === "bfdpt" && "BFDPT") ||
      (e?.[0]?.[1] === "bfpost" && "BFPOST") ||
      (e?.[0]?.[1] === "email" && "ABEMAIL") ||
      (e?.[0]?.[1] === "pnb" && "ABPHONENBR");

    const items =
      (headerChanged === "BFBU" && "BUDESC") ||
      (headerChanged === "BFDPT" && "DPTDESC") ||
      (headerChanged === "BFPOST" && "POSTDESC");

    const fromData =
      (headerChanged === "BFBU" && businessUnits) ||
      (headerChanged === "BFDPT" && departments) ||
      (headerChanged === "BFPOST" && positions);

    if (
      masterInfoDataChanges?.filter(
        (data) => data?.ABLINEID === changedInfo?.ABLINEID
      )?.length > 0
    ) {
      if (items) {
        dispatch(
          setMasterInfoDataChanges([
            ...masterInfoDataChanges?.slice(0, changeIndex), // everything before current post
            {
              ...masterInfoDataChanges[changeIndex],
              [headerChanged]: e?.[0]?.[3],
              [items]: fromData?.filter(
                (data) => data?.UVVALUE === e?.[0]?.[3]
              )?.[0]?.UVDESC,
              type: "oldChanges",
              action: "update",
            },
            ...masterInfoDataChanges?.slice(changeIndex + 1), // everything after current post
          ])
        );
      } else {
        dispatch(
          setMasterInfoDataChanges([
            ...masterInfoDataChanges?.slice(0, changeIndex), // everything before current post
            {
              ...masterInfoDataChanges[changeIndex],
              [headerChanged]: e?.[0]?.[3],
              type: "oldChanges",
              action: "update",
            },
            ...masterInfoDataChanges?.slice(changeIndex + 1), // everything after current post
          ])
        );
      }
    } else {
      dispatch(
        setMasterInfoDataChanges({
          ABLINEID: changedInfo?.ABLINEID,
          ABID2: headerChanged === "ABID2" ? e?.[0]?.[3] : changedInfo?.ABID2,
          ABFULLNAME:
            headerChanged === "ABFULLNAME"
              ? e?.[0]?.[3]
              : changedInfo?.ABFULLNAME,
          BFBU: headerChanged === "BFBU" ? e?.[0]?.[3] : changedInfo?.BFBU,
          BUDESC:
            headerChanged === "BFBU"
              ? businessUnits?.filter(
                  (data) => data?.UVVALUE === e?.[0]?.[3]
                )?.[0]?.UVDESC
              : changedInfo?.BUDESC,
          BFDPT: headerChanged === "BFDPT" ? e?.[0]?.[3] : changedInfo?.BFDPT,
          DPTDESC:
            headerChanged === "BFDPT"
              ? departments?.filter(
                  (data) => data?.UVVALUE === e?.[0]?.[3]
                )?.[0]?.UVDESC
              : changedInfo?.DPTDESC,
          BFPOST:
            headerChanged === "BFPOST" ? e?.[0]?.[3] : changedInfo?.BFPOST,
          POSTDESC:
            headerChanged === "BFPOST"
              ? positions?.filter((data) => data?.UVVALUE === e?.[0]?.[3])?.[0]
                  ?.UVDESC
              : changedInfo?.POSTDESC,
          ABEMAIL:
            headerChanged === "ABEMAIL" ? e?.[0]?.[3] : changedInfo?.ABEMAIL,
          ABPHONENBR:
            headerChanged === "ABPHONENBR"
              ? e?.[0]?.[3]
              : changedInfo?.ABPHONENBR,
          type: "newChanges",
          action: "update",
        })
      );
    }
  };

  const onRemoveRow = (e) => {
    const removedMasterInfo = masterInfoData[e];
    dispatch(
      setMasterInfoData(
        masterInfoData?.filter(
          (item) => item?.ABLINEID !== removedMasterInfo.ABLINEID
        )
      )
    );
    dispatch(
      setMasterInfoDataChanges({
        ABLINEID: removedMasterInfo?.ABLINEID,
        type: "newChanges",
        action: "remove",
      })
    );
  };

  const handleCloseSure = () => {
    setOpenSure(false);
  };

  const onConfirm = () => {
    setOpenSure(false);
    socketHR.emit(
      "dspinternal:web:hr:admin:v1",
      {
        request: "addressBook",
        addressBook: {
          type: "setData",
          setData: masterInfoDataChanges,
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
        dispatch(setMasterInfoDataChanges([]));
      }
    );
  };

  return (
    <Grid sx={{ width: "100%" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: "2%",
          paddingX: "3%",
        }}
      >
        <Button
          onClick={() => setOpenSure(true)}
          disabled={masterInfoDataChanges?.length <= 0}
          sx={{ backgroundColor: "#064987", color: "white" }}
        >
          Confirm Changes
        </Button>
      </Grid>
      <HandsontableTableComponent
        colHeaders={columnHeaders}
        data={_masterInfoData}
        onGridChange={onGridChange}
        onRemoveRow={onRemoveRow}
        colWidths={[100, 100, 200, 100, 150, 100, 150, 100, 150, 200, 150]}
        columns={columns}
        specialKey1={"id"}
        specialKey2={"id"}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        buttonTitle={"Yes"}
        buttonClick={onConfirm}
      />
    </Grid>
  );
};
