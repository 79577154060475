import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

function AlertDialog(props) {
  const width = props?.width;

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ".css-tu3qo6-MuiModal-root-MuiDialog-root, .css-1qxadfk-MuiPaper-root-MuiDialog-paper, .css-sd33hq":
          props?.alertSx
            ? props?.alertSx
            : {
                backgroundColor: "#bfbfbf !important",
                minWidth: "200px !important",
                maxWidth: width ? width : "800px !important",
                width: "100%",
              },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ color: "#064987", fontWeight: "bold", fontSize: "1.2em" }}
        display={"flex"}
        justifyContent={"center"}
      >
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Grid id="alert-dialog-description" sx={props?.sx}>
          {props.content}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={8.2}></Grid>
          <Grid item xs={1.5}>
            {props.buttonTitle != "" && (
              <Button
                autoFocus
                onClick={props.buttonClick}
                sx={{
                  backgroundColor: "#064987!important",
                  color: "white",
                  width: "100%",
                }}
                disabled={props.buttonDisabled}
              >
                {props.buttonTitle}
              </Button>
            )}
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Button
              autoFocus
              sx={{
                backgroundColor: "#7d7d7d",
                color: "white",
                width: "100%",
              }}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={0.3}></Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
