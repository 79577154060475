import React from "react";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepartments,
  setPositions,
} from "../../../../../../Services/redux/reducers/adminsReducer";
import { setHRFilter } from "../../../../../../Services/redux/reducers/HRReducer";

const FilterDPTPOSTReturn = ({ filterInfo, setIsLoading, socket, dptPost }) => {
  const alignmenmt = {
    display: "flex",
    alignItems: "center",
    ".css-sde65v-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "36.7px",
    },
    ".css-11fo197": {
      color: "white",
    },
    ".css-vamk89-MuiFormControl-root": { width: "100%" },
  };

  const dispatch = useDispatch();
  const HRFilter = useSelector((state) => state.hr.HRFilter);

  const handleChange = (e) => {
    dispatch(
      setHRFilter({
        ...HRFilter,
        search: e,
      })
    );
  };

  function getData(x) {
    setIsLoading(true);
    socket.emit(
      "dspinternal:control:data",
      {
        request: "dataAccess",
        dataAccess: {
          type: "udc",
          udc: {
            cat1: "HR",
            cat2: dptPost,
            search: filterInfo?.search,
          },
        },
      },
      (response) => {
        if (dptPost === "DPT") {
          dispatch(setDepartments(response));
        } else if (dptPost === "POST") {
          dispatch(setPositions(response));
        }
        setIsLoading(false);
      }
    );
  }

  return (
    <Grid container sx={alignmenmt}>
      <Grid container sx={{ marginBottom: "1%" }}>
        <Grid item xs={3} sx={alignmenmt}>
          <TextField
            fullWidth
            id="search"
            value={HRFilter.search}
            onChange={(e) => handleChange(e.target.value)}
            label="Search"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter Search Text.."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1} sx={alignmenmt}>
          <Button
            sx={{
              backgroundColor: "#064987",
              width: "100%",
              color: "white",
            }}
            onClick={() => getData(filterInfo)}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid
          item
          xs={3}
          style={{ fontWeight: "bold", fontSize: "1.3rem" }}
          sx={alignmenmt}
        >
          HR / {dptPost === "DPT" ? "Departments" : "Positions"}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterDPTPOSTReturn;
