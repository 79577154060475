import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import { setAllEmployees } from "../../../../../Services/redux/reducers/HRReducer";
import UsersSecurityFilter from "./Filter";
import { UsersSecurityBody } from "./Body";

const UsersSecurityComponent = () => {
  const dispatch = useDispatch();
  // get socket io
  const socketadmin = useSelector((state) => state.menu.socketadmin);
  // check loading when get data
  const [isLoading, setIsLoading] = useState(false);
  const usersSecurityList = useSelector(
    (state) => state.admins.usersSecurityList
  );

  return (
    <Box>
      {socketadmin !== null && (
        <Grid>
          <UsersSecurityFilter setIsLoading={setIsLoading} />
          {!isLoading ? (
            <Grid>
              {usersSecurityList?.length > 0 && <UsersSecurityBody />}
            </Grid>
          ) : (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default UsersSecurityComponent;
