import { Box, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableTextMobileStepper from "../../Carousel";
import { Buffer } from "buffer";
import { setNewRequest } from "../../../../../../Services/redux/reducers/superMyHRReducer";

const PreviewFiles = () => {
  const newRequest = useSelector((state) => state.superMyHR.newRequest);
  const dispatch = useDispatch();

  const images = newRequest?.attachment?.map(
    (att) => `data:${att?.type}/${att?.extension};base64,` + att?.buffer
  );

  const imageNames = newRequest?.attachment?.map((att) => att?.name);

  const onRemoveImage = (activeStep) => {
    if (newRequest && newRequest?.attachment) {
      const removing = newRequest?.attachment?.filter(
        (_, index) => index !== activeStep
      );
      dispatch(
        setNewRequest({
          ...newRequest,
          attachment: removing,
        })
      );
    } else {
      console.log("No attachments to remove");
    }
  };

  return (
    <Box>
      <Grid>
        {newRequest?.attachment?.length > 0 ? (
          <SwipeableTextMobileStepper
            images={images}
            text={imageNames}
            type={"withDelete"}
            onRemoveImage={onRemoveImage}
          />
        ) : (
          <Grid sx={{ color: "black" }}>No media to show!</Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PreviewFiles;
