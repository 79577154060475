import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const AddPage = ({ addPage, handleChange }) => {
  return (
    <Grid container>
      <Grid item xs={2}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <InputLabel>Platform</InputLabel>
          <Select
            label="Platform"
            defaultValue={""}
            width="100%"
            onChange={(e) => handleChange(e.target.value, "platform")}
          >
            <MenuItem key={"web"} value={"web"}>
              Web
            </MenuItem>
            <MenuItem key={"mob"} value={"mob"}>
              Mobile
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2 / 4}></Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          id="icon"
          label="MUI Icon Name"
          value={addPage.icon}
          onChange={(e) => handleChange(e.target.value, "icon")}
        />
      </Grid>
      <Grid item xs={2 / 4}></Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          id="text"
          label="Text"
          value={addPage.text}
          onChange={(e) => handleChange(e.target.value, "text")}
        />
      </Grid>
      <Grid item xs={2 / 4}></Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          id="description"
          label="Description"
          value={addPage.desc}
          onChange={(e) => handleChange(e.target.value, "desc")}
        />
      </Grid>
      <Grid item xs={2 / 4}></Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          id="value"
          label="Value"
          value={addPage.value}
          onChange={(e) => handleChange(e.target.value, "value")}
        />
      </Grid>
    </Grid>
  );
};

export default AddPage;
