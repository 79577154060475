import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CRPOSAdminFilterComponent from "../FilterComponent";
import AlertDialog from "../../components/AlertDialog";
import { setNotificationSnackBar } from "../../../../../Services/redux/reducers/userMenuReducer";

const POSAdminComponent = () => {
  const [openKiosk, setOpenKiosk] = useState(false);
  const [openPrintTerminalInfo, setOpenPrintTerminalInfo] = useState(false);
  const [openPrintReport, setOpenPrintReport] = useState(false);
  const [openForceClosure, setOpenForceClosure] = useState(false);
  const [openPrintSettlement, setOpenPrintSettlement] = useState(false);
  const dispatch = useDispatch();

  const socketmrbc = useSelector((state) => state.menu.socketmrbc);

  const posAdminFilter = useSelector(
    (state) => state.crposadmin.posAdminFilter
  );

  const handleClose = () => {
    setOpenKiosk(false);
    setOpenPrintTerminalInfo(false);
    setOpenPrintReport(false);
    setOpenForceClosure(false);
    setOpenPrintSettlement(false);
  };

  const commonAPI = (type, typeData) => {
    socketmrbc.emit(
      "dspinternal:web:mrbc:admin:v1",
      {
        request: "mrbcPosAdmin",
        mrbcPosAdmin: {
          type: type,
          [type]: typeData,
        },
      },
      (response) => {
        setOpenKiosk(false);
        setOpenPrintTerminalInfo(false);
        setOpenPrintReport(false);
        setOpenForceClosure(false);
        setOpenPrintSettlement(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
      }
    );
    setTimeout(() => {
      if (socketmrbc.connected === false) {
        setOpenKiosk(false);
        setOpenPrintTerminalInfo(false);
        setOpenPrintReport(false);
        setOpenForceClosure(false);
        setOpenPrintSettlement(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: "Error Occured!",
            type: "error",
          })
        );
      }
    }, 3000);
  };

  function sendData(x) {
    if (x === "kioskMode") {
      commonAPI("kioskMode", {
        crid: posAdminFilter?.kioskCrid,
        kioskMode: posAdminFilter?.kioskMode,
      });
    } else if (x === "printTerminalInfo") {
      commonAPI("printTerminalInfo", {
        crid: posAdminFilter?.printTerminalInfoCrid,
      });
    } else if (x === "printReport") {
      commonAPI("printTerminalReport", {
        crid: posAdminFilter?.printReportCrid,
      });
    } else if (x === "forceClosure") {
      commonAPI("forceClosure", {
        crid: posAdminFilter?.forceClosureCrid,
      });
    } else if (x === "printSettlement") {
      commonAPI("printSettlement", {
        crid: posAdminFilter?.printSettlementCrid,
      });
    }
  }

  const onSend = (x) => {
    if (x === "kioskModeControl") {
      setOpenKiosk(true);
    } else if (x === "printTerminalInfo") {
      setOpenPrintTerminalInfo(true);
    } else if (x === "printReport") {
      setOpenPrintReport(true);
    } else if (x === "forceClosure") {
      setOpenForceClosure(true);
    } else if (x === "printSettlement") {
      setOpenPrintSettlement(true);
    }
  };

  const controlsJSON = [
    {
      title: "POS KIOSK mode control",
      content: (
        <Grid>
          <CRPOSAdminFilterComponent
            type={"kioskModeControl"}
            onSend={() => onSend("kioskModeControl")}
          />
          <AlertDialog
            open={openKiosk}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() => sendData("kioskMode")}
          />
        </Grid>
      ),
    },
    {
      title: "Print Terminal Info",
      content: (
        <Grid>
          <CRPOSAdminFilterComponent
            type={"printTerminalInfo"}
            onSend={() => onSend("printTerminalInfo")}
          />
          <AlertDialog
            open={openPrintTerminalInfo}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() => sendData("printTerminalInfo")}
          />
        </Grid>
      ),
    },
    {
      title: "Print Report",
      content: (
        <Grid>
          <CRPOSAdminFilterComponent
            type={"printReport"}
            onSend={() => onSend("printReport")}
          />
          <AlertDialog
            open={openPrintReport}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() => sendData("printReport")}
          />
        </Grid>
      ),
    },
    {
      title: "Print Settlement",
      content: (
        <Grid>
          <CRPOSAdminFilterComponent
            type={"printSettlement"}
            onSend={() => onSend("printSettlement")}
          />
          <AlertDialog
            open={openPrintSettlement}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() => sendData("printSettlement")}
          />
        </Grid>
      ),
    },
    {
      title: "Force Closure",
      content: (
        <Grid>
          <CRPOSAdminFilterComponent
            type={"forceClosure"}
            onSend={() => onSend("forceClosure")}
          />
          <AlertDialog
            open={openForceClosure}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() => sendData("forceClosure")}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Box>
      {controlsJSON.map((data, index) => (
        <Box key={index}>
          <Grid
            sx={{
              backgroundColor: "#064987",
              color: "white",
              height: "30px",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {data.title}
          </Grid>
          {data.content}
        </Box>
      ))}
    </Box>
  );
};

export default POSAdminComponent;
