import { createSlice } from "@reduxjs/toolkit";

const CRPOSAdminSliceInitialState = {
  posAdminFilter: {
    kioskCrid: "",
    kioskMode: false,
    printTerminalInfoCrid: "",
    printReportCrid: "",
    forceClosureCrid: "",
    printSettlementCrid: "",
  },
  crDBAdminFilter: {
    emissionCrid: "",
    emissionMonth: "",
    emissionYear: "",
    emissionType: "",
    dbCrid: "",
    dbType: "",
  },
};

export const CRPOSAdminSlice = createSlice({
  name: "crposadmin",
  initialState: CRPOSAdminSliceInitialState,
  reducers: {
    setPosAdminFilter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.posAdminFilter = action.payload;
    },
    setCRDBAdminFilter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.crDBAdminFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPosAdminFilter, setCRDBAdminFilter } =
  CRPOSAdminSlice.actions;

export default CRPOSAdminSlice.reducer;
