import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MUICheckbox } from "../../../../Styles/overrides";
import {
  setAvisMVFilter,
  setAvisMVList,
} from "../../../../../../../Services/redux/reducers/MVMRBCReducer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { Period } from "../../../../components/Periods";

const MVComponentFilter = ({ setIsLoading, setOpenAdding }) => {
  const socketmrbc = useSelector((state) => state.menu.socketmrbc);
  const divisionData = useSelector((state) => state.menu.divisionData);
  const avisMVFilter = useSelector((state) => state.mvmrbc.avisMVFilter);
  var startYear = 2015;
  var endYear = new Date().getFullYear() + 2;
  var _years = [];

  while (startYear <= endYear) {
    _years.push({ UVVALUE: startYear });
    startYear++;
  }
  const allStatus = [
    { UVDESC: "Open", UVVALUE: "A" },
    { UVDESC: "Close", UVVALUE: "N" },
  ];

  const dispatch = useDispatch();

  const handleSelectCheckChange = (e, item) => {
    const selectAll = e.includes("all");
    const allItemsSelected =
      (item === "divisionsID" &&
        divisionData?.length === avisMVFilter?.divisionsID?.length) ||
      (item === "period" && Period?.length === avisMVFilter?.period?.length) ||
      (item === "year" && _years?.length === avisMVFilter?.year?.length) ||
      (item === "emissionStatus" &&
        allStatus?.length === avisMVFilter?.emissionStatus?.length);

    const data =
      (item === "divisionsID" && divisionData) ||
      (item === "period" && Period) ||
      (item === "year" && _years) ||
      (item === "emissionStatus" && allStatus);

    if (selectAll && !allItemsSelected) {
      dispatch(
        setAvisMVFilter({
          ...avisMVFilter,
          [item]: data?.map((data) => data?.UVVALUE),
        })
      );
    } else if (selectAll && allItemsSelected) {
      dispatch(
        setAvisMVFilter({
          ...avisMVFilter,
          [item]: [],
        })
      );
    } else {
      dispatch(
        setAvisMVFilter({
          ...avisMVFilter,
          [item]: e,
        })
      );
    }
  };

  const sendData = () => {
    setIsLoading(true);
    socketmrbc.emit(
      "dspinternal:web:mrbc:spv:v1",
      {
        request: "mrbcEmission",
        mrbcEmission: {
          type: "mvAvisList",
          mvAvisList: {
            emissionStatus: avisMVFilter?.emissionStatus,
            bu: avisMVFilter?.divisionsID,
            period: avisMVFilter?.period,
            year: avisMVFilter?.year,
            fromDate: avisMVFilter?.fromDate,
            toDate: avisMVFilter?.toDate,
          },
        },
      },
      (response) => {
        dispatch(setAvisMVList(response?.data));
        setIsLoading(false);
      }
    );
  };

  const handleChange = (e, item) => {
    dispatch(
      setAvisMVFilter({
        ...avisMVFilter,
        [item]: e,
      })
    );
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Division</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                avisMVFilter?.divisionsID?.length <= 0
                  ? []
                  : avisMVFilter?.divisionsID
              }
              onChange={(e) =>
                handleSelectCheckChange(e.target.value, "divisionsID")
              }
              input={<OutlinedInput label="Division" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    divisionData?.length === avisMVFilter?.divisionsID?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {divisionData?.map((div) => (
                <MenuItem key={div?.UVVALUE} value={div?.UVVALUE}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      avisMVFilter?.divisionsID?.indexOf(div?.UVVALUE) > -1
                    }
                  />
                  <ListItemText primary={`${div?.UVVALUE} - ${div?.UVDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2 / 7}></Grid>
        <Grid item xs={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Period</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                avisMVFilter?.period?.length <= 0 ? [] : avisMVFilter?.period
              }
              onChange={(e) =>
                handleSelectCheckChange(e.target.value, "period")
              }
              input={<OutlinedInput label="Period" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={12 === avisMVFilter?.period?.length}
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {Period?.map((per) => (
                <MenuItem key={per?.UVVALUE} value={per?.UVVALUE}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={avisMVFilter?.period?.indexOf(per?.UVVALUE) > -1}
                  />
                  <ListItemText primary={`${per?.UVVALUE} - ${per?.UVDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2 / 7}></Grid>
        <Grid item xs={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Year</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={avisMVFilter?.year?.length <= 0 ? [] : avisMVFilter?.year}
              onChange={(e) => handleSelectCheckChange(e.target.value, "year")}
              input={<OutlinedInput label="Year" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={_years?.length === avisMVFilter?.year?.length}
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {_years?.map((y) => (
                <MenuItem key={y?.UVVALUE} value={y?.UVVALUE}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={avisMVFilter?.year?.indexOf(y?.UVVALUE) > -1}
                  />
                  <ListItemText primary={`${y?.UVVALUE}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2 / 7}></Grid>
        <Grid item xs={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                avisMVFilter?.emissionStatus?.length <= 0
                  ? []
                  : avisMVFilter?.emissionStatus
              }
              onChange={(e) =>
                handleSelectCheckChange(e.target.value, "emissionStatus")
              }
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    allStatus?.length === avisMVFilter?.emissionStatus?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {allStatus?.map((stat) => (
                <MenuItem key={stat?.UVVALUE} value={stat?.UVVALUE}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      avisMVFilter?.emissionStatus?.indexOf(stat?.UVVALUE) > -1
                    }
                  />
                  <ListItemText primary={`${stat?.UVDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2 / 7}></Grid>
        <Grid item xs={1.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={dayjs(new Date())}
              slotProps={{
                textField: {
                  size: "small",
                  id: "fromDate",
                  label: "From Date",
                },
              }}
              format="YYYY-MM-DD"
              value={dayjs(avisMVFilter?.fromDate)}
              onChange={(e) =>
                handleChange(moment(e?.$d).format("YYYY-MM-DD"), "fromDate")
              }
              sx={{
                ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2 / 7}></Grid>
        <Grid item xs={1.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={dayjs(new Date())}
              minDate={dayjs(avisMVFilter?.fromDate)}
              slotProps={{
                textField: { size: "small", id: "toDate", label: "To Date" },
              }}
              sx={{
                ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
              format="YYYY-MM-DD"
              value={dayjs(avisMVFilter?.toDate)}
              onChange={(e) =>
                handleChange(moment(e?.$d).format("YYYY-MM-DD"), "toDate")
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2 / 7}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={sendData}
            disabled={
              avisMVFilter?.divisionsID?.length <= 0 ||
              avisMVFilter?.period === "" ||
              avisMVFilter?.year === ""
            }
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={2 / 7}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => setOpenAdding(true)}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MVComponentFilter;
