import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCRDBAdminFilter } from "../../../../../../Services/redux/reducers/CRPOSAdminReducer";

const ClearDBReturn = ({ onSend }) => {
  const dispatch = useDispatch();
  const crDBAdminFilter = useSelector(
    (state) => state.crposadmin.crDBAdminFilter
  );

  const dbType = [
    {
      UVVALUE: "mr",
      UVDESC: "Meter Reading",
    },
    {
      UVVALUE: "bc",
      UVDESC: "Bill Collection",
    },
  ];

  const onChange = (e, item) => {
    dispatch(
      setCRDBAdminFilter({
        ...crDBAdminFilter,
        [item]: e,
      })
    );
  };

  const disabled =
    crDBAdminFilter?.dbCrid === "" || crDBAdminFilter?.dbType === "";

  return (
    <Box>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            id="dbCrid"
            value={crDBAdminFilter?.dbCrid}
            onChange={(e) => onChange(e?.target?.value, "dbCrid")}
            label="CR ID"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter CR ID.."
          />
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1.2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>DB Type</InputLabel>
            <Select
              label="DB Type"
              sx={{ width: "100%" }}
              value={crDBAdminFilter?.dbType}
              onChange={(e) => onChange(e?.target?.value, "dbType")}
            >
              {dbType?.map((dbt) => (
                <MenuItem key={dbt?.UVVALUE} value={dbt?.UVVALUE}>
                  {dbt?.UVDESC}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{ backgroundColor: "#064987", color: "white", width: "100%" }}
            onClick={onSend}
            disabled={disabled}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClearDBReturn;
