import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  setUsersSecurityList,
  setUsersSecurityListChanges,
} from "../../../../../../Services/redux/reducers/adminsReducer";
import AlertDialog from "../../../components/AlertDialog";
import { HandsontableTableComponent } from "../../../components/HandsontableTable";
import { setNotificationSnackBar } from "../../../../../../Services/redux/reducers/userMenuReducer";

export const UsersSecurityBody = () => {
  const socketadmin = useSelector((state) => state.menu.socketadmin);
  const usersSecurityListChanges = useSelector(
    (state) => state.admins.usersSecurityListChanges
  );
  const dispatch = useDispatch();
  const usersSecurityList = useSelector(
    (state) => state.admins.usersSecurityList
  );

  const actions = [
    {
      desc: "allow",
      value: "allow",
    },
    {
      desc: "deny",
      value: "deny",
    },
  ];

  const [openSure, setOpenSure] = useState(false);

  const handleCloseSure = () => {
    setOpenSure(false);
  };

  const columnHeaders = [
    "ID",
    "Address Nb.",
    "User ID",
    "Action",
    "Type",
    "Type Value 1",
    "Type Value 2",
    "Value",
  ];
  var i;
  var _usersSecurityListData = [];

  const columns = [
    {
      data: "id",
      editor: false,
    },
    {
      data: "addressNbr",
      editor: false,
    },
    {
      data: "userID",
      type: "text",
    },
    {
      title: "Actions",
      // set the type of the 'Size' column
      type: "dropdown",
      data: "action",
      source: actions?.map((action) => action?.desc),
      className: "htCenter",
    },
    {
      data: "type",
      editor: false,
    },
    {
      data: "typeVal1",
      editor: "text",
    },
    {
      data: "typeVal2",
      editor: "text",
    },
    {
      data: "value",
      type: "text",
    },
  ];

  for (i = 0; i < usersSecurityList.length; i++) {
    const state = usersSecurityListChanges?.filter(
      (data) => data?.BKLINEID === usersSecurityList?.[i]?.BKLINEID
    )?.[0];

    _usersSecurityListData[i] = {
      id: usersSecurityList?.[i]?.BKLINEID,
      addressNbr: usersSecurityList?.[i]?.ABLINEID,
      userID:
        state && "BKUSERID" in state
          ? state?.BKUSERID
          : usersSecurityList?.[i]?.BKUSERID,
      action:
        state && "BKACTION" in state
          ? state?.BKACTION
          : usersSecurityList?.[i]?.BKACTION,
      type:
        state && "BKTYPE" in state
          ? state?.BKTYPE
          : usersSecurityList?.[i]?.BKTYPE,
      typeVal1:
        state && "BKTYPEVAL1" in state
          ? state?.BKTYPEVAL1
          : usersSecurityList?.[i]?.BKTYPEVAL1,
      typeVal2:
        state && "BKTYPEVAL2" in state
          ? state?.BKTYPEVAL2
          : usersSecurityList?.[i]?.BKTYPEVAL2,
      value:
        state && "BKVALUE" in state
          ? state?.BKVALUE
          : usersSecurityList?.[i]?.BKVALUE,
    };
  }

  const onGridChange = (e, currentTodos, currentPage, todosPerPage) => {
    const currentIndex =
      (currentPage - 1) * todosPerPage +
      (todosPerPage - (todosPerPage - e?.[0]?.[0]));
    const changedUsersSecurityList = usersSecurityList[currentIndex];
    const changeIndex = usersSecurityListChanges?.findIndex(
      (ob) => ob?.BKLINEID === changedUsersSecurityList?.BKLINEID
    );
    const headerChanged =
      (e?.[0]?.[1] === "userID" && "BKUSERID") ||
      (e?.[0]?.[1] === "action" && "BKACTION") ||
      (e?.[0]?.[1] === "type" && "BKTYPE") ||
      (e?.[0]?.[1] === "typeVal1" && "BKTYPEVAL1") ||
      (e?.[0]?.[1] === "typeVal2" && "BKTYPEVAL2") ||
      (e?.[0]?.[1] === "value" && "BKVALUE");
    if (changedUsersSecurityList) {
      if (
        usersSecurityListChanges?.filter(
          (data) => data?.BKLINEID === changedUsersSecurityList?.BKLINEID
        )?.length > 0
      ) {
        dispatch(
          setUsersSecurityListChanges([
            ...usersSecurityListChanges?.slice(0, changeIndex), // everything before current post
            {
              ...usersSecurityListChanges[changeIndex],
              [headerChanged]: e?.[0]?.[3],
              type: "oldChanges",
              action:
                changedUsersSecurityList?.action === "add" ? "add" : "update",
            },
            ...usersSecurityListChanges?.slice(changeIndex + 1), // everything after current post
          ])
        );
      } else {
        dispatch(
          setUsersSecurityListChanges({
            BKLINEID: changedUsersSecurityList?.BKLINEID,
            ABLINEID: changedUsersSecurityList?.ABLINEID,
            BKUSERID:
              headerChanged === "BKUSERID"
                ? e?.[0]?.[3]
                : changedUsersSecurityList?.BKUSERID,
            BKACTION:
              headerChanged === "BKACTION"
                ? e?.[0]?.[3]
                : changedUsersSecurityList?.BKACTION,
            BKTYPE: changedUsersSecurityList?.BKTYPE,
            BKTYPEVAL1:
              headerChanged === "BKTYPEVAL1"
                ? e?.[0]?.[3]
                : changedUsersSecurityList?.BKTYPEVAL1,
            BKTYPEVAL2:
              headerChanged === "BKTYPEVAL2"
                ? e?.[0]?.[3]
                : changedUsersSecurityList?.BKTYPEVAL2,
            BKVALUE:
              headerChanged === "BKVALUE"
                ? e?.[0]?.[3]
                : changedUsersSecurityList?.BKVALUE,
            type: "newChanges",
            action: "update",
          })
        );
      }
    } else {
      dispatch(
        setUsersSecurityList([
          ...usersSecurityList,
          {
            BKLINEID: (usersSecurityList[currentIndex - 1]?.BKLINEID ?? 0) + 1,
            ABLINEID: (usersSecurityList[currentIndex - 1]?.ABLINEID ?? 0) + 1,
            BKUSERID: headerChanged === "BKUSERID" ? e?.[0]?.[3] : "",
            BKACTION: headerChanged === "BKACTION" ? e?.[0]?.[3] : "",
            BKTYPE: "UDC",
            BKTYPEVAL1: headerChanged === "BKTYPEVAL1" ? e?.[0]?.[3] : "",
            BKTYPEVAL2: headerChanged === "BKTYPEVAL2" ? e?.[0]?.[3] : "",
            BKVALUE: headerChanged === "BKVALUE" ? e?.[0]?.[3] : "",
            type: "newChanges",
            action: "add",
          },
        ])
      );
      dispatch(
        setUsersSecurityListChanges({
          BKLINEID: (usersSecurityList[currentIndex - 1]?.BKLINEID ?? 0) + 1,
          ABLINEID: (usersSecurityList[currentIndex - 1]?.ABLINEID ?? 0) + 1,
          BKUSERID: headerChanged === "BKUSERID" ? e?.[0]?.[3] : "",
          BKACTION: headerChanged === "BKACTION" ? e?.[0]?.[3] : "",
          BKTYPE: "UDC",
          BKTYPEVAL1: headerChanged === "BKTYPEVAL1" ? e?.[0]?.[3] : "",
          BKTYPEVAL2: headerChanged === "BKTYPEVAL2" ? e?.[0]?.[3] : "",
          BKVALUE: headerChanged === "BKVALUE" ? e?.[0]?.[3] : "",
          type: "newChanges",
          action: "add",
        })
      );
    }
  };

  const onRemoveRow = (e) => {
    const removedUsersSecurity = usersSecurityList[e];
    dispatch(
      setUsersSecurityList(
        usersSecurityList?.filter(
          (item) => item?.BKLINEID !== removedUsersSecurity.BKLINEID
        )
      )
    );
    dispatch(
      setUsersSecurityListChanges({
        BKLINEID: removedUsersSecurity?.BKLINEID,
        type: "newChanges",
        action: "remove",
      })
    );
  };

  const onConfirm = () => {
    setOpenSure(false);
    socketadmin?.emit(
      "dspinternal:web:admin:usr:v1",
      {
        request: "userSecurity",
        userSecurity: {
          type: "setData",
          setData: usersSecurityListChanges,
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
        dispatch(setUsersSecurityListChanges([]));
      }
    );
  };

  return (
    <Grid sx={{ width: "55%" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: "2%",
          paddingX: "3%",
        }}
      >
        <Button
          onClick={() => setOpenSure(true)}
          disabled={usersSecurityListChanges?.length <= 0}
          sx={{ backgroundColor: "#064987", color: "white" }}
        >
          Confirm Changes
        </Button>
      </Grid>
      <HandsontableTableComponent
        colHeaders={columnHeaders}
        data={_usersSecurityListData}
        onGridChange={onGridChange}
        onRemoveRow={onRemoveRow}
        columns={columns}
        colWidths={[100, 100, 100, 100, 100, 100, 100]}
        specialKey1={"id"}
        specialKey2={"id"}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        buttonTitle={"Yes"}
        buttonClick={onConfirm}
      />
    </Grid>
  );
};
