import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AlertDialog from "../../components/AlertDialog";
import MMSJDEIntegrationFilter from "./FilterComponent";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import {
  setDivisionData,
  setNotificationSnackBar,
} from "../../../../../Services/redux/reducers/userMenuReducer";

const MMSJDEIntegrationComponent = () => {
  const [openInteg, setOpenInteg] = useState(false);
  const socket = useSelector((state) => state.menu.socket);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const integrationFilter = useSelector(
    (state) => state.mrbcEmissions.integrationFilter
  );

  // handle close are you sure pop up
  const handleClose = () => {
    setOpenInteg(false);
  };

  // get all divisions
  function getDivisions() {
    socket?.emit(
      "dspinternal:control:data",
      {
        request: "dataAccess",
        dataAccess: {
          type: "udcAccess",
          udcAccess: {
            cat1: "PBL",
            cat2: "DIV",
          },
        },
      },
      (response) => {
        dispatch(setDivisionData(response));
        cookies.set("_divisionData", response, {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
      }
    );
  }

  function sendData() {
    socket.emit(
      "dspinternal:mrbc",
      {
        request: "mrbcEmission",
        mrbcEmission: {
          type: "emissionInterfaceImport",
          emissionInterfaceImport: {
            emissionType: integrationFilter?.emissionType,
            bu: integrationFilter?.bu,
            period: integrationFilter?.period,
            year: integrationFilter?.year,
          },
        },
      },
      (response) => {
        setOpenInteg(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
      }
    );
    setTimeout(() => {
      if (socket.connected === false) {
        setOpenInteg(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: "Error Occured!",
            type: "error",
          })
        );
      }
    }, 3000);
  }

  // get divisions on open page
  useEffect(() => {
    getDivisions();
  }, []);

  const controlsJSON = [
    {
      title: "MMS-JDE Integration",
      content: (
        <Grid>
          <MMSJDEIntegrationFilter setOpenInteg={setOpenInteg} />
          <AlertDialog
            open={openInteg}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={sendData}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Box>
      {controlsJSON.map((data, index) => (
        <Box key={index}>
          <Grid
            sx={{
              backgroundColor: "#064987",
              color: "white",
              height: "30px",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {data.title}
          </Grid>
          {data.content}
        </Box>
      ))}
    </Box>
  );
};

export default MMSJDEIntegrationComponent;
