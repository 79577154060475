import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Grid } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";

function SwipeableTextMobileStepper({ images, text, type, onRemoveImage }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: type === "withDelete" && "space-between",
          height: 50,
          pl: 2,
          //   bgcolor: "background.default",
        }}
      >
        <Typography>{text[activeStep]}</Typography>
        {type === "withDelete" && (
          <Grid sx={{ marginRight: "2%" }}>
            <Button
              sx={{
                backgroundColor: "#a10303",

                color: "white",
              }}
              onClick={() => onRemoveImage(activeStep)}
            >
              {/* <RemoveIcon sx={{ color: "white" }} /> */} Remove
            </Button>
          </Grid>
        )}
      </Paper>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {images[activeStep] && images?.length > 0 ? (
          <Box
            component="img"
            sx={{
              height: "auto",
              display: "block",
              overflow: "hidden",
              width: "50%",
            }}
            src={images[activeStep]}
            alt={images[activeStep]}
          />
        ) : (
          <Box sx={{ color: "black" }}>No images available!</Box>
        )}
      </div>
      <MobileStepper
        sx={{ color: "white", backgroundColor: "black" }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            sx={{ color: "white" }}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            sx={{ color: "white" }}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;
