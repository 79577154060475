import React, { useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import StandaloneToggleButton from "../../ToggleButton";
import { setNewRequest } from "../../../../../../Services/redux/reducers/superMyHRReducer";
import SearchIcon from "@mui/icons-material/Search";
import { Buffer } from "buffer";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PreviewIcon from "@mui/icons-material/Preview";

const AddNewLeaveRequestForm = ({
  type,
  setOpen,
  onCheckAddressNbr,
  employeeInfo,
  handleRequestedDays,
  setOpenFilesPreview,
}) => {
  const userInfo = useSelector((state) => state.menu.userInfo);
  const dispatch = useDispatch();
  const newRequest = useSelector((state) => state.superMyHR.newRequest);
  const leaveTypes = useSelector((state) => state.superMyHR.leaveTypes);
  const leaveReasons = useSelector((state) => state.superMyHR.leaveReasons);
  const balanceDays = useSelector((state) => state.superMyHR.balanceDays);
  const [fileData, setFileData] = useState([]);
  const balanceExceeded =
    newRequest?.requestedDays > balanceDays?.[0]?.BVVALUE &&
    balanceDays?.[0]?.BVVALUE !== 0;

  const leaveTypesCondition =
    balanceDays?.[0]?.BVVALUE === 0
      ? leaveTypes.filter((type) => type?.UVVALUE !== "AN")
      : leaveTypes;

  const heightForm = {
    '.css-11do72n-MuiGrid-root input[type="text"], .css-11do72n-MuiGrid-root, input[type="password"], .css-11do72n-MuiGrid-root, .css-1dxv3pf-MuiGrid-root, .css-11do72n-MuiGrid-root, .react-tel-input, .css-11do72n-MuiGrid-root, .flag-dropdown, .css-11do72n-MuiGrid-root .css-1dxv3pf-MuiGrid-root, .css-11do72n-MuiGrid-root, .react-tel-input, .css-11do72n-MuiGrid-root, .form-control, .css-11do72n-MuiGrid-root, label[data-shrink=false]+.MuiInputBase-formControl, .css-11do72n-MuiGrid-root, .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
      {
        minHeight: "100%",
      },
    width: "100%",
  };

  const onChangeNewRequest = (e, item) => {
    const addressNbr =
      type === "myHR" ? [userInfo?.ABLINEID] : newRequest?.addressNbr;

    const updateRequest = (key, value) => {
      dispatch(
        setNewRequest({
          ...newRequest,
          [key]: value,
        })
      );
    };

    if (item === "attachment") {
      const files = e?.target?.files;

      if (files && files.length > 0) {
        const updatedFileData = [...fileData];

        Array.from(files).forEach((file, index) => {
          const reader = new FileReader();

          reader.onloadend = function (event) {
            const fileType = file.type.split("/")[0];
            const fileExtension = file.name.split(".").pop();
            const base64Data = event.target.result.split("base64,")[1];
            const bufferData = Buffer.from(base64Data, "base64");
            if (fileExtension === "png" || fileExtension === "jpg") {
              updatedFileData.push({
                name: file.name,
                type: fileType,
                extension: fileExtension,
                buffer: bufferData,
              });

              if (index === files.length - 1) {
                // Check if it's the last file
                setFileData(updatedFileData);
                updateRequest(
                  item,
                  updatedFileData.map((file) => ({
                    name: file.name,
                    type: file.type,
                    extension: file.extension,
                    buffer: file.buffer.toString("base64"),
                  }))
                );
              }
            }
          };

          reader.readAsDataURL(file);
        });
      }
    } else {
      updateRequest(item, e);
    }

    if (item === "fromDate" || item === "toDate" || item === "halfDay") {
      handleRequestedDays(
        addressNbr,
        item === "fromDate" ? e : newRequest?.fromDate,
        item === "toDate" ? e : newRequest?.toDate,
        item === "halfDay" ? e : newRequest?.halfDay
      );
    }
  };

  //Buffer.from(newRequest?.attachment?.[0]?.buffer, "base64")

  return (
    <Grid
      container
      sx={{
        marginTop: "1%",
        'input[type="text"], input[type="password"],.css-1dxv3pf-MuiGrid-root, .react-tel-input, .flag-dropdown,.css-1dxv3pf-MuiGrid-root, .react-tel-input, .form-control, label[data-shrink=false]+.MuiInputBase-formControl, .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
          {
            backgroundColor: "#e5e5e5",
            borderRadius: "8px",
          },
        ".css-112ysrj-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled": {
          color: "#757474",
        },
      }}
    >
      {balanceExceeded && (
        <Grid
          sx={{
            color: "#b10909",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Balance Days Exceeded!!!
        </Grid>
      )}
      <Grid
        container
        sx={{ color: "black", fontWeight: "bold", marginBottom: "3%" }}
      >
        <Grid item xs={9}>
          Employee Info
        </Grid>
        {balanceDays?.length > 0 && (
          <Grid item xs={3} container>
            <Grid item xs={6} sx={{ color: "#064987", fontWeight: "bold" }}>
              Balance:
            </Grid>
            <Grid item xs={6} sx={{ color: "#064987", fontWeight: "bold" }}>
              {balanceDays?.[0]?.BVVALUE}{" "}
              {balanceDays?.[0]?.BVVALUE > 1 ? "Days" : "Day"}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ color: "black" }}>
        {type === "myHR" ? (
          <Grid item xs={1.5}>
            Address NO.
          </Grid>
        ) : (
          <Grid item xs={1.5}></Grid>
        )}
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={2}>
          Name
        </Grid>
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={1.5}>
          Division
        </Grid>
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={3}>
          Department
        </Grid>
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={3}>
          Position
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ color: "#656565", display: "flex", alignItems: "center" }}
      >
        {type === "myHR" ? (
          <Grid item xs={1.5}>
            {userInfo?.ABLINEID}
          </Grid>
        ) : (
          <Grid item xs={1.5}>
            <TextField
              sx={{
                ".css-ntrjkj-MuiInputBase-root-MuiOutlinedInput-root": {
                  padding: "0px",
                },
                ".css-1vhgub1-MuiInputAdornment-root": { marginLeft: "0px" },
                ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                  borderColor:
                    employeeInfo?.isExist === false &&
                    newRequest?.addressNbr !== "" &&
                    "#8e0303 !important",
                },
              }}
              autoComplete="off"
              value={newRequest?.addressNbr ? newRequest?.addressNbr : ""}
              onChange={(e) => onChangeNewRequest(e.target.value, "addressNbr")}
              onBlur={(e) => onCheckAddressNbr(e?.target?.value)}
              fullWidth
              id="search"
              label="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={() =>
                        setOpen({ isOpen: true, type: "addRequest" })
                      }
                      sx={{ width: "auto", minWidth: "0px" }}
                    >
                      <SearchIcon sx={{ color: "#064987" }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={2}>
          {employeeInfo?.data?.ABFULLNAME}
        </Grid>
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={1.5}>
          {employeeInfo?.data?.BUDESC}
        </Grid>
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={3}>
          {employeeInfo?.data?.DPTDESC}
        </Grid>
        <Grid item xs={1 / 4}></Grid>
        <Grid item xs={3}>
          {employeeInfo?.data?.POSTDESC}
        </Grid>
      </Grid>
      {employeeInfo?.isExist === false && newRequest?.addressNbr !== "" && (
        <Grid container>
          <Grid
            item
            xs={1.5}
            sx={{ color: "#8e0303", fontSize: "small", textAlign: "center" }}
          >
            Does Not Exist!
          </Grid>
        </Grid>
      )}
      <Grid container sx={{ backgroundColor: "gray", marginTop: "2%" }}>
        <Divider />
      </Grid>
      {(type === "myHR" || Object.keys(employeeInfo?.data)?.length > 0) && (
        <Grid container>
          <Grid
            container
            sx={{ color: "black", fontWeight: "bold", marginTop: "2%" }}
          >
            Days Requested
          </Grid>
          <Grid container sx={{ marginY: "3%" }}>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  maxDate={dayjs(new Date())}
                  slotProps={{
                    textField: {
                      size: "small",
                      id: "reqDate",
                      label: "Request Date",
                    },
                  }}
                  format="YYYY-MM-DD"
                  value={dayjs(newRequest?.reqDate)}
                  onChange={(e) =>
                    onChangeNewRequest(
                      moment(e?.$d).format("YYYY-MM-DD"),
                      "reqDate"
                    )
                  }
                  sx={{
                    ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                      color: "black",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1 / 4}></Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: {
                      size: "small",
                      id: "fromdate",
                      label: "From Date",
                    },
                  }}
                  maxDate={dayjs(newRequest?.toDate)}
                  format="YYYY-MM-DD"
                  value={dayjs(newRequest?.fromDate)}
                  onChange={(e) =>
                    onChangeNewRequest(
                      moment(e?.$d).format("YYYY-MM-DD"),
                      "fromDate"
                    )
                  }
                  sx={{
                    ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                      color: "black",
                    },
                    width: "100%",
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1 / 4}></Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: {
                      size: "small",
                      id: "toDate",
                      label: "To Date",
                    },
                  }}
                  minDate={dayjs(newRequest?.fromDate)}
                  sx={{
                    ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                      color: "black",
                    },
                    width: "100%",
                  }}
                  format="YYYY-MM-DD"
                  value={dayjs(newRequest?.toDate)}
                  onChange={(e) =>
                    onChangeNewRequest(
                      moment(e?.$d).format("YYYY-MM-DD"),
                      "toDate"
                    )
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1 / 4}></Grid>
            <Grid item xs={2}>
              {(newRequest?.requestedDays === 1 ||
                newRequest?.requestedDays === 0.5) && (
                <FormControl sx={heightForm} variant="outlined" size="small">
                  <InputLabel>Duration</InputLabel>
                  <Select
                    label="Duration"
                    width="100%"
                    value={newRequest?.halfDay}
                    onChange={(e) =>
                      onChangeNewRequest(e.target.value, "halfDay")
                    }
                  >
                    <MenuItem key="HD" value={"HD"}>
                      Half Day
                    </MenuItem>
                    <MenuItem key="FD" value={"FD"}>
                      Full Day
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={1 / 4}></Grid>
            <Grid
              item
              xs={3}
              container
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={6} sx={{ color: "#064987", fontWeight: "bold" }}>
                Total Days:
              </Grid>
              <Grid item xs={6} sx={{ color: "#064987", fontWeight: "bold" }}>
                {newRequest?.requestedDays > 0
                  ? newRequest?.requestedDays
                  : "0"}{" "}
                {newRequest?.requestedDays > 1 ? "Days" : "Day"}
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ color: "black", fontWeight: "bold" }}>
            Leave Details
          </Grid>
          <Grid
            container
            sx={{
              marginY: "3%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormControl
              style={{
                width: "15%",
              }}
              sx={heightForm}
              variant="outlined"
              size="small"
            >
              <InputLabel>Leave Type</InputLabel>
              <Select
                label="Leave Type"
                width="100%"
                value={newRequest?.type}
                onChange={(e) => onChangeNewRequest(e.target.value, "type")}
              >
                {leaveTypesCondition?.map((leaveType) => {
                  return (
                    <MenuItem key={leaveType.UVVALUE} value={leaveType.UVVALUE}>
                      {leaveType.UVDESC}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              style={{
                width: "15%",
              }}
              sx={heightForm}
              variant="outlined"
              size="small"
            >
              <InputLabel>Reason</InputLabel>
              <Select
                label="Reason"
                width="100%"
                value={newRequest?.reqReason}
                onChange={(e) =>
                  onChangeNewRequest(e.target.value, "reqReason")
                }
              >
                {leaveReasons?.map((reason) => (
                  <MenuItem key={reason?.UVVALUE} value={reason?.UVVALUE}>
                    {reason?.UVDESC}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              style={{ width: "15%" }}
              id="comments"
              label="Comments.."
              value={newRequest.reasonComment ? newRequest.reasonComment : ""}
              onChange={(e) =>
                onChangeNewRequest(e.target.value, "reasonComment")
              }
            />
            <Grid
              sx={{
                width: "10%",
                ".Mui-selected": {
                  backgroundColor: "green !important",
                },
                ".css-m36ea6-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover":
                  {
                    backgroundColor: "green !important",
                  },
                display: "flex",
                alignItems: "center",
              }}
            >
              <StandaloneToggleButton
                onChange={onChangeNewRequest}
                newRequest={newRequest}
              />
              <Grid sx={{ marginLeft: "5%", color: "black" }}>Travelling</Grid>
            </Grid>
            <Grid
              width={"30%"}
              container
              sx={{
                ".css-uxhebr-MuiFormControl-root": {
                  height: "44.5px",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "black",
                  textAlign: "center",
                  padding: "3%",
                  borderRadius: "7.5%",
                  fontSize: "small",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="files"
                  style={{
                    color: "white",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    justifyContent: "center",
                  }}
                >
                  <FileUploadIcon sx={{ color: "white", marginRight: "1%" }} />
                  Upload Files
                </label>
              </Grid>
              <Grid item xs={1}>
                <input
                  type="file"
                  onChange={(e) => onChangeNewRequest(e, "attachment")}
                  multiple
                  id="files"
                  style={{ visibility: "hidden", width: "100%" }}
                />
              </Grid>

              {newRequest?.attachment?.length > 0 && (
                <Grid
                  item
                  xs={5}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <label style={{ color: "black" }}>
                    {newRequest?.attachment?.length > 1
                      ? newRequest?.attachment?.length + " files"
                      : newRequest?.attachment?.length + " file"}
                  </label>
                  <Button onClick={() => setOpenFilesPreview(true)}>
                    <PreviewIcon sx={{ color: "black" }} />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ color: "black", fontWeight: "bold" }}>
            Emergency Contact during Absence
          </Grid>
          <Grid
            container
            sx={{
              marginY: "3%",
              display: "flex",
              justifyContent: "space-around",
              maxHeight: "42px",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              id="contactName"
              label="Contact Name"
              value={newRequest.contactName ? newRequest.contactName : ""}
              onChange={(e) =>
                onChangeNewRequest(e.target.value, "contactName")
              }
            />
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              id="contactPhone"
              label="Telephone Number"
              value={newRequest.contactPhone ? newRequest.contactPhone : ""}
              onChange={(e) =>
                onChangeNewRequest(e.target.value, "contactPhone")
              }
            />
            <Grid sx={{ width: "25%" }}></Grid>
            <Grid sx={{ width: "25%" }}></Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AddNewLeaveRequestForm;
