import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { setNewAvisMVData } from "../../../../../../../Services/redux/reducers/MVMRBCReducer";

const ADDNewAVIS = () => {
  const divisionData = useSelector((state) => state.menu.divisionData);
  const newAvisMVData = useSelector((state) => state.mvmrbc.newAvisMVData);
  const dispatch = useDispatch();

  const handleChange = (e, item) => {
    dispatch(
      setNewAvisMVData({
        ...newAvisMVData,
        [item]: e,
      })
    );
  };

  return (
    <Box>
      <Grid container sx={{ marginY: "2%" }}>
        <Grid item xs={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Division</InputLabel>
            <Select
              label="Division"
              sx={{ width: "100%" }}
              value={newAvisMVData?.divisionID ? newAvisMVData?.divisionID : ""}
              onChange={(e) => handleChange(e.target.value, "divisionID")}
            >
              {divisionData?.map((div) => (
                <MenuItem key={div?.UVVALUE} value={div?.UVVALUE}>
                  {div?.UVVALUE} - {div?.UVDESC}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2 / 3}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="period"
            label="Period"
            type="number"
            sx={{
              ".css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                { height: "100%" },
              ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                color: "black",
              },
            }}
            value={newAvisMVData?.period ? newAvisMVData?.period : ""}
            onChange={(e) => {
              if (
                (e.target.value > 0 && e.target.value <= 12) ||
                e.target.value === ""
              )
                handleChange(e.target.value, "period");
            }}
          />
        </Grid>
        <Grid item xs={2 / 3}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            type="number"
            id="year"
            label="Year"
            sx={{
              ".css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                { height: "100%" },
              ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                color: "black",
              },
            }}
            value={newAvisMVData?.year ? newAvisMVData?.year : ""}
            onChange={(e) => handleChange(e.target.value, "year")}
          />
        </Grid>
        <Grid item xs={2 / 3}></Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{
                textField: { size: "small", id: "date", label: "Date" },
              }}
              sx={{
                ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                  color: "black",
                },
                width: "100%",
                height: "100% !important",
                '.css-1kv3oev-MuiFormControl-root-MuiTextField-root, .css-zgexuw-MuiInputBase-root-MuiOutlinedInput-root, input[type="text"], input[type="password"]':
                  {
                    height: "100% !important",
                  },
              }}
              format="YYYY-MM-DD"
              value={
                dayjs(newAvisMVData?.date)
                  ? dayjs(newAvisMVData?.date)
                  : moment(new Date()).format("YYYY-MM-DD")
              }
              onChange={(e) =>
                handleChange(moment(e?.$d).format("YYYY-MM-DD"), "date")
              }
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2.5}>
          <TextField
            fullWidth
            type="number"
            id="avisNb"
            label="AVIS Number"
            sx={{
              ".css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                { height: "100%" },
              ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                color: "black",
              },
            }}
            value={newAvisMVData?.avisNb ? newAvisMVData?.avisNb : ""}
            onChange={(e) => handleChange(e.target.value, "avisNb")}
          />
        </Grid>
        <Grid item xs={2 / 3}></Grid>
        <Grid item xs={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Currency</InputLabel>
            <Select
              label="Currency"
              sx={{ width: "100%" }}
              value={newAvisMVData?.currency ? newAvisMVData?.currency : ""}
              onChange={(e) => handleChange(e.target.value, "currency")}
            >
              <MenuItem key={"LBP"} value={"LBP"}>
                LBP
              </MenuItem>
              <MenuItem key={"USD"} value={"USD"}>
                USD
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2 / 3}></Grid>
        <Grid item xs={2.5}>
          <TextField
            fullWidth
            type="number"
            id="amount"
            label="Amount"
            sx={{
              ".css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                { height: "100%" },
              ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                color: "black",
              },
            }}
            value={newAvisMVData?.amount ? newAvisMVData?.amount : ""}
            onChange={(e) => handleChange(e.target.value, "amount")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ADDNewAVIS;
