import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import Table from "../../../../table/Table";

const BodyMVAvisComponent = () => {
  const avisMVList = useSelector((state) => state.mvmrbc.avisMVList);

  const mvAvisListColumns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "BQBU", headerName: "Business Unit", width: 100 },
    { field: "BQPERIOD", headerName: "Period", width: 150 },
    { field: "BQYEAR", headerName: "Year", width: 150 },
    { field: "BQCURRENCY", headerName: "Currency", width: 150 },
    { field: "BQAMOUNT", headerName: "Amount", width: 150 },
    { field: "BQDATE", headerName: "Date", width: 150 },
    { field: "BQTIME", headerName: "Time", width: 150 },
  ];

  var i;
  var _mvAvisListData = [];

  for (i = 0; i < avisMVList?.length; i++) {
    _mvAvisListData[i] = {
      id: avisMVList?.[i].BQLINEID,
      BQBU: avisMVList?.[i].BQBU,
      BQPERIOD: avisMVList?.[i].BQPERIOD,
      BQYEAR: avisMVList?.[i].BQYEAR,
      BQCURRENCY: avisMVList?.[i].BQCURRENCY,
      BQAMOUNT: avisMVList?.[i].BQAMOUNT,
      BQDATE: moment(avisMVList?.[i].BQDATE)?.format("ddd DD-MM-YYYY"),
      BQTIME: avisMVList?.[i].BQTIME,
    };
  }

  return (
    <Box>
      {avisMVList?.length > 0 && (
        <Grid>
          <Table columns={mvAvisListColumns} _newData={_mvAvisListData} />
        </Grid>
      )}
    </Box>
  );
};

export default BodyMVAvisComponent;
