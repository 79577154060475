import { Box, Grid } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as Muicon from "@mui/icons-material";

const BoxItem = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        backgroundColor: "#a2acb1",
        width: "100%",
        height: "100%",
        color: "#064987",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.image !== "null" && (
            <img alt="" src={props.image} style={{ width: "50%" }} />
          )}
        </Grid>
        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto", paddingLeft: "0px" }}>
              <Typography
                component="div"
                variant="h5"
                sx={{ fontWeight: "bold" }}
              >
                {props.title}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ color: "#064987" }}
              >
                {props.description}
              </Typography>
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BoxItem;
