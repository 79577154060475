import { Box, Grid, Tooltip } from "@mui/material";
import React from "react";
import BigBoxItems from "./BigBoxItems";

const DefaultComponent = ({ JsonData, path }) => {
  return (
    <Box margin={"3%"}>
      {JsonData?.map((data) => (
        <Box marginY={"3%"} key={data?.BJLINEID}>
          <Grid
            sx={{
              backgroundColor: "#064987",
              color: "white",
              height: "30px",
              borderRadius: "7.5px",
              marginBottom: "2%",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {data.BJTITLE}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "left",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {data?.pages?.map((lBox, index) => (
              <Tooltip
                key={lBox?.BJLINEID}
                componentsProps={{
                  tooltip: {
                    sx: {
                      width: "8rem",
                      fontSize: "0.8rem",
                    },
                  },
                }}
                title={
                  <Grid container>
                    <Grid container>
                      <Grid item xs={5}>
                        Page ID:
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={6}>
                        {lBox?.BJLINEID}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={5}>
                        Name:
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={6}>
                        {lBox?.BJROUTE}
                      </Grid>
                    </Grid>
                  </Grid>
                }
                placement="bottom"
              >
                <Box sx={{ width: "20%", marginRight: "5%" }}>
                  <BigBoxItems lBox={lBox} path={path} />
                </Box>
              </Tooltip>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default DefaultComponent;
