import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const ResetContent = ({ resetData, setResetData }) => {
  const onChange = (e, item) => {
    setResetData({
      ...resetData,
      [item]: e,
    });
  };

  return (
    <Box sx={{ marginTop: "2%" }}>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Grid item sx={{ width: "20%" }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Reason</InputLabel>
            <Select
              label="Resason"
              sx={{ width: "100%" }}
              value={resetData?.reason}
              onChange={(e) => onChange(e?.target?.value, "reason")}
            >
              <MenuItem key={"غير ذلك"} value={"غير ذلك"}>
                غير ذلك
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ width: "50%" }}>
          <TextField
            fullWidth
            id="comments"
            value={resetData?.comment}
            onChange={(e) => onChange(e?.target?.value, "comment")}
            label="Comments"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter Your comment.."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetContent;
