import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import { setAllEmployees } from "../../../../../../Services/redux/reducers/HRReducer";
import FilterHRComponent from "../../Filter";
import { BodyDepartments } from "./Body";

const DepartmentsComponent = () => {
  const dispatch = useDispatch();
  // get socket io
  const socket = useSelector((state) => state.menu.socket);
  const socketHR = useSelector((state) => state.menu.socketHR);
  const departments = useSelector((state) => state.admins.departments);
  // check loading when get data
  const [isLoading, setIsLoading] = useState(false);

  // get all divisions/departments/positions/employees to complete the filter
  function getUsersList() {
    socketHR.emit(
      "dspinternal:web:hr:spv:v1",
      {
        request: "attendanceUsersList",
      },
      (response) => {
        dispatch(setAllEmployees(response));
      }
    );
  }

  useEffect(() => {
    if (socketHR !== null) getUsersList();
  }, [socketHR]);

  return (
    <Box>
      {socket !== null && socketHR !== null && (
        <Grid>
          <FilterHRComponent
            socket={socket}
            socketHR={socketHR}
            setIsLoading={setIsLoading}
            app={"deptPost"}
            dptPost={"DPT"}
          />
          {!isLoading ? (
            <Grid>
              {departments?.length > 0 && <BodyDepartments socket={socket} />}
            </Grid>
          ) : (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default DepartmentsComponent;
