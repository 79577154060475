import * as React from "react";
import {
  Box,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Fade,
  Button,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Link, useNavigate } from "react-router-dom";

import Header from "../header";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  setSelectedApp,
  setSelectedPage,
  setUser,
} from "../../../../Services/redux/reducers/userMenuReducer";
import store from "../../../../Services/redux/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Cookies from "universal-cookie";
import RemoveStates from "./RemoveStates";

const Topbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const selectedPage = useSelector((state) => state.menu.selectedPage);
  const userMenu = useSelector((state) => state.menu.userMenu);
  const navigate = useNavigate();

  const selectedMenu = userMenu?.filter((x) => x?.AIDESC === selectedPage);

  const dispatch = useDispatch();
  const cookies = new Cookies();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const Logout = () => {
    dispatch(setUser(false));
    window.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      backgroundColor="#064987"
      alignItems="center"
      padding="0.2%"
    >
      <Grid container>
        {/* <Grid item xs={1} md={0.5} sx={{ height: "100%" }}></Grid>{" "} */}
        <Grid
          item
          xs={3}
          md={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* <img alt="" className={styles.logo} src="/grayLogo.jpg" /> */}
          {selectedMenu?.[0]?.AIDESC !== cookies.get("_selectedApp") && (
            <Link
              to={selectedMenu?.[0]?.AIVALUE}
              state={selectedMenu?.[0]?.sections}
            >
              <Button
                onClick={() => {
                  cookies.set("_selectedPage", selectedMenu?.[0]?.AIDESC, {
                    path: "/",
                    expires: new Date(Date.now() + 2592000),
                  });
                  cookies.set("_selectedApp", selectedMenu?.[0]?.AIDESC, {
                    path: "/",
                    expires: new Date(Date.now() + 2592000),
                  });
                  dispatch(setSelectedPage(selectedMenu?.[0]?.AIDESC));
                  dispatch(setSelectedApp(selectedMenu?.[0]?.AIDESC));
                  RemoveStates?.RemoveReduxStates(dispatch);
                }}
              >
                <ArrowBackIcon sx={{ color: "white" }} />
              </Button>
            </Link>
          )}
        </Grid>
        <Grid
          item
          xs={8}
          md={8.8}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Header />
        </Grid>
        <Grid
          item
          xs={1}
          md={2.2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            margin="0.1%"
            width="100%"
            flexDirection="row"
            display="flex"
            justifyContent="right"
          >
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={Logout}>Logout</MenuItem>
            </Menu>
            <IconButton onClick={handleClick}>
              <PersonOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Topbar;
