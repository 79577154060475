import {
  List,
  useTheme,
  Typography,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { tokens } from "../../../../../theme";
import { MenuItem } from "react-pro-sidebar";
import * as Muicon from "@mui/icons-material";
import Cookies from "universal-cookie";
import {
  setSelectedApp,
  setSelectedPage,
} from "../../../../../Services/redux/reducers/userMenuReducer";
import RemoveStates from "../RemoveStates";

const Item = ({ title, to, icon, state, closeCollapsed }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const cookies = new Cookies();

  return (
    <MenuItem
      active={cookies.get("_selectedPage") === title.split(" /")?.[0]}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        cookies.set("_selectedPage", title.split(" /")?.[0], {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
        cookies.set("_selectedApp", title, {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
        dispatch(setSelectedPage(title));
        dispatch(setSelectedApp(title));
        closeCollapsed();
        RemoveStates?.RemoveReduxStates(dispatch);
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} state={state} />
    </MenuItem>
  );
};

const SidebarList = (props) => {
  const GenerateIcon = (variation, props = {}) => {
    const IconName = Muicon[variation]
      ? Muicon[variation]
      : Muicon["ArrowForwardIos"];
    return <IconName {...props} />;
  };

  return (
    <List sx={{ textAlign: "left" }}>
      {props.userMenu.map((menu) => {
        return (
          <Tooltip
            key={menu.AILINEID}
            componentsProps={{
              tooltip: {
                sx: {
                  width: "100%",
                  fontSize: "0.8rem",
                },
              },
            }}
            title={
              <Grid container>
                <Grid container>
                  <Grid item xs={8}>
                    Page ID:
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    {menu.AILINEID}
                  </Grid>
                </Grid>
              </Grid>
            }
            placement="bottom"
          >
            <Grid>
              <Item
                to={menu.AIVALUE}
                state={menu.sections}
                title={menu.AIDESC}
                icon={GenerateIcon(
                  menu.AIICON ? menu.AIICON : "ArrowForwardIos"
                )}
                closeCollapsed={props?.closeCollapsed}
              />
              <Divider
                sx={{
                  marginX: "6%",
                  borderColor: "rgb(124 124 124 / 67%)",
                }}
              />
            </Grid>
          </Tooltip>
        );
      })}
    </List>
  );
};

export default SidebarList;
