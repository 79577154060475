import { Box } from "@mui/material";
import React from "react";
import "./forgetPassword.css";
// import "./styling/forgetpass.css";
import "./style2.css";
import AuthService from "../../../Services/http/auth.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSnackBarForgetPassword } from "../../../Services/redux/reducers/userMenuReducer";

const ForgetPasswordComponent = () => {
  // foregt password component interface
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();

    var { username } = document.forms[0];

    AuthService.forgetPassword(username.value).then(
      (response) => {
        navigate("/");
        if (response?.data?.status === true) {
          dispatch(
            setSnackBarForgetPassword({
              open: true,
              severity: "success",
              message: response?.data?.reply,
            })
          );
        } else {
          dispatch(
            setSnackBarForgetPassword({
              open: true,
              severity: "error",
              message: response?.data?.reply,
            })
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const forgetForm = (
    <div className="firstForm">
      <form className="myForm">
        <div>
          <img alt="" className="logo001" src="./logmrad.png" />
        </div>
        <div className="formDiv001">
          {/* {httpError.length > 0 && <div className="error">{httpError}</div>} */}
          <div className="input-container">
            <label>Username </label>
            <input type="text" name="username" required />
          </div>
          <div className="button-container">
            <button className="submit-button" onClick={handleSubmit}>
              Get Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <Box widht="100%" height="100vh">
      <div className="forgetForm">{forgetForm}</div>
    </Box>
  );
  // return (
  //   <div className="back">
  //     <Grid sx={{ height: "10%" }}></Grid>
  //     <Grid container sx={{ height: "80%" }}>
  //       <Grid item xs={12} className="imgGrid">
  //         <img alt="" src="/reglogo.png" />
  //       </Grid>
  //       <Grid container item xs={12}>
  //         <Grid item xs={4}></Grid>
  //         <Grid item xs={4} sx={{ width: "100%" }}>
  //           <TextField fullWidth id="email" label="Email" />
  //         </Grid>
  //         <Grid item xs={4}></Grid>
  //       </Grid>
  //       <Grid container item xs={12}>
  //         <Grid item xs={5}></Grid>
  //         <Grid item xs={2} sx={{ width: "100%" }}>
  //           <Button className="send-button">Send Password</Button>
  //         </Grid>
  //         <Grid item xs={5}></Grid>
  //       </Grid>
  //     </Grid>
  //     <Grid sx={{ height: "10%" }}></Grid>
  //   </div>
  // );
};

export default ForgetPasswordComponent;
