import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FilterUsersComponent from "./Filter";
import BodyUsers from "./Body";
import AddUserComponent from "../components/AddUser";
import DetailsPopUp from "./Details";
import allData from "../../../../Services/Data";
import { setUser } from "../../../../Services/redux/reducers/userMenuReducer";

const SubUsersComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const [usersData, setUsersData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [rowDetails, setRowDetails] = useState([]);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [httpDetailsError, setHttpDetailsError] = useState(false);
  const dispatch = useDispatch();

  var i;
  var _usersData = [];

  function getUsersData(x) {
    setIsLoading(true);
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "userdata",
        x,
        "adminendusr/v1/enduser"
      )
      .then(
        (response) => {
          setUsersData(response?.data?.userdata);
          setIsLoading(false);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
        }
      );
  }

  function getUserDetails(x) {
    setIsLoadingDetails(true);
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "userdetails",
        { userid: x },
        "adminendusr/v1/enduser"
      )
      .then(
        (response) => {
          setUserDetails(response);
          setIsLoadingDetails(false);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoadingDetails(false);
          setHttpDetailsError(error);
        }
      );
  }

  const onButtonClick = (e, row) => {
    getUserDetails(row.id);
    setOpenDetails(true);
    setRowDetails(row);
  };

  const onClose = () => {
    setOpenDetails(false);
  };

  const usersDataColumns = [
    // { field: "nothing", headerName: "", width: 5 },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
          }}
          onClick={(e) => onButtonClick(e, params.row)}
        >
          Subscriptions
        </Button>
      ),
      width: 150,
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "title", headerName: "Title", width: 200 },
    { field: "fullname", headerName: "Full Name", width: 200 },
    { field: "username", headerName: "Username", width: 200 },
    { field: "address1", headerName: "Address 1", width: 200 },
    { field: "addrress2", headerName: "Address 2", width: 200 },
    { field: "email", headerName: "Email Address", width: 200 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "phonenumber", headerName: "Phone Number", width: 200 },
    { field: "identity", headerName: "Identity", width: 200 },
  ];

  for (i = 0; i < usersData?.length; i++) {
    _usersData[i] = {
      id: usersData?.[i]?.AAUSERID,
      title: usersData?.[i]?.ABTITLE,
      fullname: usersData?.[i]?.ABFULLNAME,
      username: usersData?.[i]?.AAUSERNAME,
      address1: usersData?.[i]?.ABADDRESS1,
      address2: usersData?.[i]?.ABADDRESS2,
      email: usersData?.[i]?.ABEMAIL,
      status: usersData?.[i]?.ABCAT1,
      phoneCode: usersData?.[i]?.ABPHONECODE,
      phonenumber: usersData?.[i]?.ABPHONENBR,
      identity: usersData?.[i]?.ABIDENTITY,
    };
  }
  const [addDetails, setAddDetails] = useState({
    title: "",
    fullName: "",
    username: "",
    phoneCode: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    email: "",
    idNumber: "",
    idURL: null,
    billURL: null,
    fees: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    title: "",
    fullName: "",
    username: "",
    phoneCode: "",
    phoneNumber: "",
    address1: "",
    email: "",
    idNumber: "",
    idURL: null,
    billURL: null,
    feesURL: null,
  });

  return (
    <Grid>
      <FilterUsersComponent getUsersData={getUsersData} setOpen={setOpen} />
      <BodyUsers
        usersData={usersData}
        isLoading={isLoading}
        httpError={httpError}
        usersDataColumns={usersDataColumns}
        _usersData={_usersData}
      />
      <AddUserComponent
        open={open}
        setOpen={setOpen}
        setDetails={setAddDetails}
        details={addDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        userAll={userAll}
      />
      <DetailsPopUp
        userDetails={userDetails}
        onClose={onClose}
        openDetails={openDetails}
        rowDetails={rowDetails}
        getUserDetails={getUserDetails}
        isLoadingDetails={isLoadingDetails}
        httpDetailsError={httpDetailsError}
      />
    </Grid>
  );
};

export default SubUsersComponent;
