import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SectionsInfo from "../SectionsInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddedApplications,
  setAllEdits,
  setEditedPagesData,
  setRemovedPages,
  setUpdatedPagesData,
} from "../../../../../../../../../Services/redux/reducers/adminsReducer";
import AppsInfo from "../AppInfo";

const SectionsComp = ({ data, index, section, sectionIndex }) => {
  const [sectionExpand, setSectionExpand] = React.useState(false);
  const updatedPagesData = useSelector(
    (state) => state.admins.updatedPagesData
  );
  const editedPagesData = useSelector((state) => state.admins.editedPagesData);
  const allEdits = useSelector((state) => state.admins.allEdits);
  const pagesData = useSelector((state) => state.admins.pagesData);
  const dispatch = useDispatch();

  const toggleAcordion = () => {
    setSectionExpand((prev) => !prev);
  };

  const addApplication = (pageID, id) => {
    const rowsInput = {
      AICAT1: null,
      AICAT2: null,
      AIDESC: data?.AIDESC,
      AIICON: data?.AIICON,
      AILINEID: data?.AILINEID,
      AIPLATFORM: data?.AIPLATFORM,
      AISTATUS: data?.AISTATUS,
      AITEXT: data?.AITEXT,
      AITYPE: data?.AITYPE,
      AIVALUE: data?.AIVALUE,
      BJCOMPONENTTYPE: "app",
      BJDESC: "",
      BJIMAGE: "",
      BJLINEID: Math.random(),
      BJPAGEID: section?.BJPAGEID,
      BJROUTE: "",
      BJSECTIONID: section.BJLINEID,
      BJTITLE: "",
      action: "add",
    };

    const updatePagesData = updatedPagesData.map((page) => {
      // Check if this is the page to update
      if (pageID === page.AILINEID) {
        // Found the matching page, now update its sections
        const updatedSections = page.sections.map((section) => {
          // Check if this is the section to update
          if (id === section.BJLINEID) {
            // Found the matching section, now add the rowsInput to its pages array
            dispatch(setAddedApplications(rowsInput));
            dispatch(setAllEdits({ ...rowsInput, type: "app" }));
            return {
              ...section,
              pages: [...section.pages, rowsInput], // Assuming 'pages' exists within 'section'
            };
          } else {
            // Not the section we're looking to update, return it as is
            return section;
          }
        });

        // Return the updated page with the updated sections
        return {
          ...page,
          sections: updatedSections,
        };
      } else {
        // Not the page we're looking to update, return it as is
        return page;
      }
    });

    // Dispatch the action to update the state with the new pages data
    dispatch(setUpdatedPagesData(updatePagesData));
  };

  const removeSection = (pageIndex, sectionIndex, sectionID) => {
    // Make a deep copy of pagesData to avoid directly mutating state
    const updatePagesData = updatedPagesData.map((page, index) => {
      if (index === pageIndex) {
        return {
          ...page,
          sections: page.sections.filter(
            (_, secIndex) => secIndex !== sectionIndex
          ),
        };
      }
      return page;
    });

    dispatch(setUpdatedPagesData(updatePagesData));
    const isOld =
      pagesData
        ?.map(
          (e) =>
            e?.sections?.filter((s) => s?.BJLINEID === sectionID)?.length > 0
        )
        ?.filter((m) => m === true)?.length > 0;
    if (isOld) {
      dispatch(
        setRemovedPages({
          type: "section",
          action: "remove",
          AILINEID: data?.AILINEID,
          BJLINEID: sectionID,
        })
      );
    }
    dispatch(
      setAllEdits({
        type: "section",
        action: "remove",
        AILINEID: data?.AILINEID,
        BJLINEID: sectionID,
        old: isOld,
      })
    );
  };

  const removeApp = (pageIndex, sectionIndex, appIndex, appID) => {
    // Make a deep copy of pagesData to avoid directly mutating state
    const updatePagesData = updatedPagesData.map((page, index) => {
      if (index === pageIndex) {
        // Update only the sections of the matching page
        const updatedSections = page.sections.map((section, secIndex) => {
          if (sectionIndex === secIndex) {
            // Remove the app from the matching section by filtering out the specified appIndex
            return {
              ...section,
              pages: section.pages.filter(
                (_, applicationIndex) => applicationIndex !== appIndex
              ),
            };
          }
          return section;
        });

        // Return the page with the updated sections
        return {
          ...page,
          sections: updatedSections,
        };
      }
      // For pages that don't match the pageIndex, return them as they are
      return page;
    });

    const isOld =
      pagesData
        ?.map(
          (e) =>
            e?.sections
              ?.map(
                (f) =>
                  f?.pages?.filter((s) => s?.BJLINEID === appID)?.length > 0
              )
              ?.filter((m) => m === true)?.length > 0
        )
        ?.filter((m) => m === true)?.length > 0;

    // Dispatch the updated data to update the state
    dispatch(setUpdatedPagesData(updatePagesData));
    if (isOld) {
      dispatch(
        setRemovedPages({
          type: "app",
          action: "remove",
          AILINEID: data?.AILINEID,
          BJSECTIONID: section?.BJLINEID,
          BJLINEID: appID,
        })
      );
    }
    dispatch(
      setAllEdits({
        type: "app",
        action: "remove",
        AILINEID: data?.AILINEID,
        BJSECTIONID: section?.BJLINEID,
        BJLINEID: appID,
        old: isOld,
      })
    );
  };

  const handleSectionChanges = (e, object, item) => {
    const alreadyEditedPageIndex = editedPagesData.findIndex(
      (ob) => ob?.AILINEID === object?.AILINEID
    );

    const alreadyEditedSectionIndex = editedPagesData?.[
      alreadyEditedPageIndex
    ]?.sections?.findIndex((ob) => ob?.BJLINEID === object?.BJLINEID);

    const editedSectionIndex = data?.sections?.findIndex(
      (ob) => ob?.BJLINEID === object?.BJLINEID
    );

    const allEditsIndex = allEdits?.findIndex(
      (ob) => ob?.BJLINEID === object?.BJLINEID
    );

    const isAdded = allEdits.map((ob) => ob?.action === "add")?.[0];

    if (allEditsIndex !== -1) {
      dispatch(
        setAllEdits([
          ...allEdits?.slice(0, allEditsIndex), // everything before current post
          {
            ...allEdits[allEditsIndex],
            [item]: e,
            action: isAdded ? "add" : "update",
            old: true,
            type: "section",
          },
          ...allEdits?.slice(allEditsIndex + 1), // everything after current post
        ])
      );
    } else {
      dispatch(
        setAllEdits({
          ...object,
          [item]: e,
          action: isAdded ? "add" : "update",
          old: false,
          type: "section",
        })
      );
    }

    if (alreadyEditedPageIndex !== -1) {
      if (alreadyEditedSectionIndex !== -1) {
        const update = [
          ...editedPagesData.slice(0, alreadyEditedPageIndex), // everything before current post
          {
            ...editedPagesData[alreadyEditedPageIndex],
            sections: [
              ...editedPagesData?.[alreadyEditedPageIndex]?.sections?.slice(
                0,
                alreadyEditedSectionIndex
              ), // everything before current post
              {
                ...editedPagesData[alreadyEditedPageIndex]?.sections[
                  alreadyEditedSectionIndex
                ],
                [item]: e,
              },
              ...editedPagesData?.[alreadyEditedPageIndex]?.sections?.slice(
                alreadyEditedSectionIndex + 1
              ), // everything after current post
            ],
          },
          ...editedPagesData.slice(alreadyEditedPageIndex + 1), // everything after current post
        ];
        dispatch(setEditedPagesData(update)); // Assuming dispatch is available
      } else if (alreadyEditedSectionIndex === -1) {
        dispatch(
          setEditedPagesData({
            ...object,
            [item]: e,
            type: "newSectionEdit",
            index: alreadyEditedPageIndex,
          })
        ); // Assuming dispatch is available
      }
    } else {
      // If not already edited, dispatch a new edit action
      const update = {
        ...data,
        sections: [
          ...data?.sections?.slice(0, editedSectionIndex), // everything before current post
          {
            ...data?.sections[editedSectionIndex],
            [item]: e,
          },
          ...data?.sections?.slice(editedSectionIndex + 1), // everything after current post
        ],
        type: "newEdit",
      };
      dispatch(setEditedPagesData(update));
    }
  };

  return (
    <Accordion
      sx={{
        ".css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded,.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root, .css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded:focus,.css-15v22id-MuiAccordionDetails-root,.css-1pjk7et":
          {
            backgroundColor: "#e1e0e0 !important",
          },
        ".css-dp6qik": {
          backgroundColor: "#e1e0e0 !important",
        },
        backgroundColor: "#e1e0e0 !important",
      }}
      expanded={sectionExpand}
    >
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon onClick={toggleAcordion} />}
        aria-controls={`panel${sectionIndex}-content`}
        id={`panel${sectionIndex}-header`}
      >
        <SectionsInfo
          data={section}
          removeSection={() =>
            removeSection(index, sectionIndex, section?.BJLINEID)
          }
          handleSectionChanges={handleSectionChanges}
        />
      </AccordionSummary>
      <AccordionDetails>
        {section?.pages?.map((app, appIndex) => (
          <div key={app?.BJLINEID}>
            <div
              style={{
                fontWeight: "500",
                fontSize: "1rem",
                color: "black",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              Application #{appIndex + 1}
            </div>
            <Accordion
              sx={{
                ".css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded,.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root, .css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded:focus,.css-15v22id-MuiAccordionDetails-root,.css-u3b0c9,.css-1wlgidb":
                  {
                    backgroundColor: "#cfcfcf !important",
                  },
                backgroundColor: "#cfcfcf !important",
              }}
              expanded={false}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <AppsInfo
                  data={app}
                  removeApp={() =>
                    removeApp(index, sectionIndex, appIndex, app?.BJLINEID)
                  }
                  pageData={data}
                  sectionData={section}
                />
              </AccordionSummary>
            </Accordion>
          </div>
        ))}
        {section?.BJLINEID >= 1 && (
          <Button
            sx={{
              color: "white",
              backgroundColor: "#064987",
              marginTop: "1%",
            }}
            onClick={() => addApplication(data?.AILINEID, section?.BJLINEID)}
          >
            Add New Application
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SectionsComp;
