import React, { useEffect, useRef, useState } from "react";
import ChatComponent from "./ChatComponent";
import { useDispatch, useSelector } from "react-redux";
import MQTT from "../../../../../Services/MQTT";
import {
  setSelectedConversation,
  setCurrentUnreadedMessages,
  setConversationHistory,
  setSearchInMsgs,
} from "../../../../../Services/redux/reducers/ticketsReducer";

const ChatTicketsComponent = ({
  client,
  state,
  setIsChatting,
  messageSentPub,
}) => {
  const dispatch = useDispatch();

  const userAll = useSelector((state) => state.menu.userAll);
  // const { state } = useLocation();

  //get unreaded messages / conversation messages from states
  const unreadedMessages = useSelector(
    (state) => state.ticket.unreadedMessages
  );
  const conversationMessages = useSelector(
    (state) => state.ticket.conversationMessages
  );
  const conversationMessagesRef = useRef();
  conversationMessagesRef.current = conversationMessages;

  // On selecting a ticket from the list
  const onConversationItem = (conversation) => {
    dispatch(setSelectedConversation(conversation));
    dispatch(setSearchInMsgs(""));
    // Publish ticket history
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "tickethistory",
          userid: userAll?.userid,
          ticketid: conversation?.AHLINEID,
        }),
      },
      client
    );
    // Change the status of some old unreaded messages in database
    if (
      unreadedMessages?.filter((un) => un.ticketid === conversation?.AHLINEID)
        ?.length > 0
    ) {
      MQTT.mqttPublish(
        {
          topic: "dspenduser/v1/ccagent/tickets",
          qos: 0,
          payload: JSON.stringify({
            request: "msgupdate",
            userid: userAll?.userid,
            ticketid: conversation?.AHLINEID,
            msgupdate: {
              type: "statusupdate",
              messageId: unreadedMessages?.map((x) => {
                if (x.ticketid === conversation?.AHLINEID) {
                  return x?.messages?.messageID;
                }
                return x;
              }),
              status: "seen",
            },
          }),
        },
        client
      );
      dispatch(setCurrentUnreadedMessages([]));
    }

    // Change the status of some old unreaded messages in screen
    if (Object.keys(conversation)?.length > 0) {
      const updateState = () => {
        const newState = conversationMessagesRef.current?.map((obj) => {
          // 👇️ if id equals 2, update country property
          if (obj?.sender?.senderId !== userAll?.userid)
            if (obj?.message?.type !== "chatinfo")
              if (obj.message?.status !== "seen") {
                messageSentPub(
                  conversation?.AHLINEID,
                  [obj?.message?.messageId],
                  "seen"
                );
                return {
                  ...obj,
                  message: {
                    ...obj?.message,
                    status: "seen",
                  },
                };
              }
          return obj;
        });

        dispatch(setConversationHistory(newState));
      };
      updateState();
    }
  };

  useEffect(() => {
    onConversationItem(state);
  }, [client]);

  return (
    <ChatComponent
      onConversationItem={onConversationItem}
      client={client}
      setIsChatting={setIsChatting}
    />
  );
};

export default ChatTicketsComponent;
