import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import { setNotificationSnackBar } from "../../../../../Services/redux/reducers/userMenuReducer";
import LeaveRequestFilter from "../../components/LeaveRequest/Filter";
import AddNewLeaveRequest from "../../components/LeaveRequest/AddNewLeaveRequestPopUp";
import {
  setBalanceDays,
  setNewRequest,
} from "../../../../../Services/redux/reducers/superMyHRReducer";
import UDCRequest from "../../components/LeaveRequest/UDCRequest";
import LeaveRequestBody from "../../components/LeaveRequest/Body";
import moment from "moment";

const SuperHRLeaveRequestComponent = () => {
  const dispatch = useDispatch();
  // get socket io
  const socket = useSelector((state) => state.menu.socket);
  const socketHR = useSelector((state) => state.menu.socketHR);
  // check loading when get data
  const [isLoading, setIsLoading] = useState(false);
  const [openAddLeaveRequest, setOpenAddLeaveRequest] = useState(false);
  const balanceDays = useSelector((state) => state.superMyHR.balanceDays);
  // state to open address nbr search pop up
  const [openAddressNbrSearch, setOpenAddressNbrSearch] = useState({
    isOpen: false,
    type: "",
  });
  const leaveRequestsList = useSelector(
    (state) => state.superMyHR.leaveRequestsList
  );
  // add new leave request api
  const onApplyRequest = (newRequest) => {
    socketHR?.emit(
      "dspinternal:web:hr:spv:v1",
      {
        request: "hrForms",
        hrForms: {
          type: "leaveData",
          leaveData: {
            action: "addData",
            addressNbr: newRequest?.addressNbr,
            type: newRequest?.type,
            halfDay: newRequest?.halfDay,
            reqReason: newRequest?.reqReason,
            reasonComment: newRequest?.reasonComment,
            reqDate: newRequest?.reqDate,
            fromDate: newRequest?.fromDate,
            toDate: newRequest?.toDate,
            contactName: newRequest?.contactName,
            contactPhone: newRequest?.contactPhone,
            flightStatus: newRequest?.flightStatus,
            daysBalance: balanceDays?.[0]?.BVVALUE,
            daysNbr: newRequest?.requestedDays,
            attachment: newRequest?.attachment,
          },
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
        dispatch(
          setNewRequest({
            addressNbr: "",
            type: "",
            halfDay: "FD",
            reqReason: "",
            reasonComment: "",
            reqDate: moment(new Date()).format("YYYY-MM-DD"),
            fromDate: moment(new Date()).format("YYYY-MM-DD"),
            toDate: moment(new Date()).format("YYYY-MM-DD"),
            requestedDays: 1,
            contactName: "",
            contactPhone: "",
            flightStatus: false,
            attachment: [],
          })
        );
      }
    );
  };
  // get leave types ane reasons
  useEffect(() => {
    if (socket !== null) {
      UDCRequest("HR", "LVRQT", "leaveTypes", socket, dispatch);
      UDCRequest("HR", "LVRQR", "leaveReasons", socket, dispatch);
    }
  }, []);
  // get days balance for address nbr
  function getBalance(addressNbr) {
    socketHR.emit(
      "dspinternal:web:hr:spv:v1",
      {
        request: "hrForms",
        hrForms: {
          type: "leaveData",
          leaveData: {
            action: "empBalance",
            addressNbr: addressNbr,
          },
        },
      },
      (response) => {
        dispatch(setBalanceDays(response?.data));
      }
    );
  }

  return (
    <Box>
      {socket !== null && socketHR !== null && (
        <Grid>
          <LeaveRequestFilter
            setOpen={setOpenAddLeaveRequest}
            setIsLoading={setIsLoading}
            openAddressNbrSearch={openAddressNbrSearch}
            setOpenAddressNbrSearch={setOpenAddressNbrSearch}
          />
          {!isLoading ? (
            <Grid>
              {leaveRequestsList?.length > 0 ? (
                <LeaveRequestBody />
              ) : (
                <Grid></Grid>
              )}
            </Grid>
          ) : (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )}
        </Grid>
      )}
      <AddNewLeaveRequest
        open={openAddLeaveRequest}
        setOpen={setOpenAddLeaveRequest}
        type={"superHR"}
        onApplyRequest={onApplyRequest}
        openAddressNbrSearch={openAddressNbrSearch}
        setOpenAddressNbrSearch={setOpenAddressNbrSearch}
        getBalance={getBalance}
      />
    </Box>
  );
};

export default SuperHRLeaveRequestComponent;
