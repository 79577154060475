import React from "react";
import "react-phone-input-2/lib/style.css";
import AddAdminContent from "./AddAdminContent";
import { useState } from "react";
import { Box } from "@mui/material";
import AlertDialog from "../../../components/AlertDialog";
import allData from "../../../../../../Services/Data";
import { useDispatch } from "react-redux";
import {
  setNotificationSnackBar,
  setUser,
} from "../../../../../../Services/redux/reducers/userMenuReducer";

const AddAdminUser = (props) => {
  const open = props.open;
  const userAll = props.userAll;
  const setOpen = props.setOpen;
  const setDetails = props.setDetails;
  const setErrorMessage = props.setErrorMessage;
  const details = props.details;
  const errorMessage = props.errorMessage;

  const [openSure, setOpenSure] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setDetails({
      title: "",
      addressNumber: "",
      bu: "",
      dpt: "",
      post: "",
      fullName: "",
      phoneCode: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      email: "",
      idNumber: "",
    });
    setErrorMessage({
      title: "",
      addressNumber: "",
      bu: "",
      dpt: "",
      post: "",
      fullName: "",
      phoneCode: "",
      phoneNumber: "",
      address1: "",
      email: "",
      idNumber: "",
    });
  };
  const handleCloseSure = () => {
    setOpenSure(false);
  };

  function handleChange(e, key, mess, regex, s, skey) {
    setDetails({
      ...details,
      [key]: e,
      [skey]: s,
    });
    if (regex) {
      setErrorMessage({
        ...errorMessage,
        [key]: "",
        [skey]: "",
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [key]: "Valid " + mess + " is required!",
      });
    }
  }

  const handlingDetails = {
    handleChange,
  };

  const buttonDisabled =
    errorMessage.title !== "" ||
    errorMessage.addressNumber !== "" ||
    errorMessage.bu !== "" ||
    errorMessage.dpt !== "" ||
    errorMessage.post !== "" ||
    errorMessage.fullName !== "" ||
    errorMessage.email !== "" ||
    // errorMessage.phoneCode !== "" ||
    // errorMessage.phoneNumber !== "" ||
    errorMessage.address1 !== "" ||
    // errorMessage.idNumber !== "" ||
    details.title === "" ||
    details.addressNumber === "" ||
    details.bu === "" ||
    details.dpt === "" ||
    details.post === "" ||
    details.fullName === "" ||
    details.email === "" ||
    // details.phoneCode === "" ||
    // details.phoneNumber === "" ||
    details.address1 === "";
  // details.idNumber === "";

  const sureClicked = () => {
    const x = {
      title: details.title,
      bu: details.bu,
      dpt: details.dpt,
      post: details.post,
      phoneCode: details.phoneCode,
      phonePrefix: "70",
      phoneNbr: details.phoneNumber,
      address1: details.address1,
      address2: details.address2,
      email: details.email,
      fullName: details.fullName,
      identity: details.idNumber,
      addressNbr: details.addressNumber,
    };
    allData
      .getSuperData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "addressbook",
        "addBook",
        x,
        "admindsp/v1/adminuser"
      )
      .then(
        (response) => {
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: "User added successfully!",
              type: "success",
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        }
      );

    setOpen(false);
    setOpenSure(false);
    handleClose();
  };

  return (
    <Box>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        title={"Add New User"}
        content={
          <AddAdminContent
            details={details}
            handlingDetails={handlingDetails}
            errorMessage={errorMessage}
          />
        }
        buttonTitle={"ADD"}
        details={details}
        buttonClick={() => setOpenSure(true)}
        buttonDisabled={buttonDisabled}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        buttonTitle={"Yes"}
        buttonClick={sureClicked}
      />
    </Box>
  );
};

export default AddAdminUser;
