import { Box, Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const ClosingEmission = ({ data, setClosingDate, ClosingDate }) => {
  return (
    <Box sx={{ color: "black" }}>
      <Grid sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>Details</Grid>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", fontWeight: "500" }}
      >
        <Grid item xs={2}>
          Business Unit ID
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={2}>
          Business Unit
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={1}>
          Period
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={1}>
          Year
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={2}>
          Recieve Date
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "3%",
          marginTop: "1%",
        }}
      >
        <Grid item xs={2}>
          {data?.BOBU}
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={2}>
          {data?.BU}
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={1}>
          {data?.BOPERIOD}
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={1}>
          {data?.BOYEAR}
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={2}>
          {data?.BOEMISSIONDATE}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{
                textField: { size: "small", id: "date", label: "Closing Date" },
              }}
              sx={{
                ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
              format="YYYY-MM-DD"
              value={dayjs(ClosingDate) ? dayjs(ClosingDate) : new Date()}
              onChange={(e) =>
                setClosingDate(moment(e?.$d).format("YYYY-MM-DD"))
              }
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClosingEmission;
