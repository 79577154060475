import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllEdits,
  setEditedPagesData,
} from "../../../../../../../../Services/redux/reducers/adminsReducer";

const AppsInfo = ({ data, removeApp, pageData, sectionData }) => {
  const dispatch = useDispatch();
  const editedPagesData = useSelector((state) => state.admins.editedPagesData);
  const allEdits = useSelector((state) => state.admins.allEdits);

  const statement = editedPagesData
    ?.filter((ob) => ob?.AILINEID === data?.AILINEID)?.[0]
    ?.sections?.filter((sec) => sec?.BJLINEID === data?.BJSECTIONID)?.[0]
    ?.pages?.filter((app) => app?.BJLINEID === data?.BJLINEID);

  const values = (item) => {
    if (statement?.length > 0) {
      if (statement?.[0][item]) {
        return statement?.[0][item];
      } else {
        return "";
      }
    } else {
      if (data[item]) {
        return data[item];
      } else {
        return "";
      }
    }
  };

  const handleApplicationChanges = (e, object, item) => {
    const alreadyEditedPageIndex = editedPagesData.findIndex(
      (ob) => ob?.AILINEID === object?.AILINEID
    );

    const alreadyEditedSectionIndex = editedPagesData?.[
      alreadyEditedPageIndex
    ]?.sections?.findIndex((ob) => ob?.BJLINEID === object?.BJSECTIONID);

    const alreadyEditedAppIndex = editedPagesData?.[
      alreadyEditedPageIndex
    ]?.sections?.[alreadyEditedSectionIndex]?.pages?.findIndex(
      (ob) => ob?.BJLINEID === object?.BJLINEID
    );

    const editedSectionIndex = pageData?.sections?.findIndex(
      (ob) => ob?.BJLINEID === object?.BJSECTIONID
    );

    const editedAppIndex = pageData?.sections[
      editedSectionIndex
    ]?.pages?.findIndex((ob) => ob?.BJLINEID === object?.BJLINEID);

    const allEditsIndex = allEdits?.findIndex(
      (ob) => ob?.BJLINEID === object?.BJLINEID
    );

    const isAdded = allEdits.map((ob) => ob?.action === "add")?.[0];

    if (allEditsIndex !== -1) {
      dispatch(
        setAllEdits([
          ...allEdits?.slice(0, allEditsIndex), // everything before current post
          {
            ...allEdits[allEditsIndex],
            [item]: e,
            action: isAdded ? "add" : "update",
            old: true,
            type: "app",
          },
          ...allEdits?.slice(allEditsIndex + 1), // everything after current post
        ])
      );
    } else {
      dispatch(
        setAllEdits({
          ...object,
          [item]: e,
          action: isAdded ? "add" : "update",
          old: false,
          type: "app",
        })
      );
    }

    if (alreadyEditedPageIndex !== -1) {
      if (alreadyEditedSectionIndex !== -1) {
        if (alreadyEditedAppIndex !== -1) {
          const update = [
            ...editedPagesData.slice(0, alreadyEditedPageIndex), // everything before current post
            {
              ...editedPagesData[alreadyEditedPageIndex],
              sections: [
                ...editedPagesData?.[alreadyEditedPageIndex]?.sections?.slice(
                  0,
                  alreadyEditedSectionIndex
                ), // everything before current post
                {
                  ...editedPagesData[alreadyEditedPageIndex]?.sections[
                    alreadyEditedSectionIndex
                  ],
                  pages: [
                    ...editedPagesData?.[alreadyEditedPageIndex]?.sections?.[
                      alreadyEditedSectionIndex
                    ]?.pages?.slice(0, alreadyEditedAppIndex), // everything before current post
                    {
                      ...editedPagesData[alreadyEditedPageIndex]?.sections[
                        alreadyEditedSectionIndex
                      ]?.pages[alreadyEditedAppIndex],
                      [item]: e,
                    },
                    ...editedPagesData?.[alreadyEditedPageIndex]?.sections?.[
                      alreadyEditedSectionIndex
                    ]?.pages?.slice(alreadyEditedAppIndex + 1), // everything after current post
                  ],
                },
                ...editedPagesData?.[alreadyEditedPageIndex]?.sections?.slice(
                  alreadyEditedSectionIndex + 1
                ), // everything after current post
              ],
            },
            ...editedPagesData.slice(alreadyEditedPageIndex + 1), // everything after current post
          ];
          dispatch(setEditedPagesData(update)); // Assuming dispatch is available
          // dispatch(
          //   setAllEdits([
          //     ...allEdits?.slice(0, allEditsIndex), // everything before current post
          //     {
          //       ...allEdits[allEditsIndex],
          //       [item]: e,
          //       action: "update",
          //       old: true,
          //     },
          //     ...allEdits?.slice(allEditsIndex + 1), // everything after current post
          //   ])
          // );
        } else if (alreadyEditedAppIndex === -1) {
          dispatch(
            setEditedPagesData({
              ...object,
              [item]: e,
              type: "newAppEdit",
              index: alreadyEditedPageIndex,
              sectionIndex: alreadyEditedSectionIndex,
            })
          ); // Assuming dispatch is available
          // dispatch(
          //   setAllEdits([
          //     ...allEdits?.slice(0, allEditsIndex), // everything before current post
          //     {
          //       ...allEdits[allEditsIndex],
          //       [item]: e,
          //       action: "update",
          //       old: true,
          //     },
          //     ...allEdits?.slice(allEditsIndex + 1), // everything after current post
          //   ])
          // );
        }
      } else {
        if (alreadyEditedAppIndex === -1) {
          // If not already edited, dispatch a new edit action
          const update = {
            ...sectionData,
            pages: [
              ...sectionData?.pages?.slice(0, editedAppIndex), // everything before current post
              {
                ...sectionData?.pages[editedAppIndex],
                [item]: e,
              },
              ...sectionData?.pages?.slice(editedAppIndex + 1), // everything after current post
            ],
            type: "newSectionEdit",
            index: alreadyEditedPageIndex,
          };
          dispatch(setEditedPagesData(update));
        }
      }
    } else {
      const update = {
        ...pageData,
        sections: [
          ...pageData?.sections?.slice(0, editedSectionIndex), // everything before current post
          {
            ...pageData?.sections[editedSectionIndex],
            pages: [
              ...pageData?.sections[editedSectionIndex]?.pages?.slice(
                0,
                editedAppIndex
              ), // everything before current post
              {
                ...pageData?.sections[editedSectionIndex]?.pages[
                  editedAppIndex
                ],
                [item]: e,
              },
              ...pageData?.sections[editedSectionIndex]?.pages?.slice(
                editedAppIndex + 1
              ), // everything after current post
            ],
          },
          ...pageData?.sections?.slice(editedSectionIndex + 1), // everything after current post
        ],
        type: "newEdit",
      };
      dispatch(setEditedPagesData(update));
      // dispatch(
      //   setAllEdits({ ...object, [item]: e, action: "update", old: false })
      // );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        'input[type="text"], input[type="password"]': { height: "10px" },
        ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
          WebkitTextFillColor: "rgb(0 0 0 / 61%) !important",
        },
      }}
    >
      <Grid container>
        <Grid item xs={0.5}>
          <TextField
            fullWidth
            id="id"
            label="ID"
            value={values("BJLINEID")}
            disabled
          />
        </Grid>
        <Grid item xs={1 / 7}></Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            id="type"
            label="Type"
            value={values("BJCOMPONENTTYPE")}
            disabled
          />
        </Grid>
        <Grid item xs={1 / 7}></Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            id="platform"
            label="Platform"
            value={values("AIPLATFORM")}
            disabled
            // onChange={(e) => handleChange(e.target.value, "icon")}
          />
        </Grid>
        <Grid item xs={1 / 7}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="descrption"
            label="Description"
            value={values("BJDESC")}
            onChange={(e) =>
              handleApplicationChanges(e.target.value, data, "BJDESC")
            }
          />
        </Grid>
        <Grid item xs={1 / 7}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="text"
            label="Text"
            value={values("BJTITLE")}
            onChange={(e) =>
              handleApplicationChanges(e.target.value, data, "BJTITLE")
            }
          />
        </Grid>
        <Grid item xs={1 / 7}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="value"
            label="Value"
            value={values("BJROUTE")}
            onChange={(e) =>
              handleApplicationChanges(e.target.value, data, "BJROUTE")
            }
          />
        </Grid>
        <Grid item xs={1 / 7}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="image"
            label="Image"
            value={values("BJIMAGE")}
            onChange={(e) =>
              handleApplicationChanges(e.target.value, data, "BJIMAGE")
            }
          />
        </Grid>
        <Grid item xs={1 / 7}></Grid>
        <Grid
          item
          xs={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button onClick={removeApp}>
            <RemoveCircleIcon sx={{ color: "red" }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppsInfo;
