import { Box } from "@mui/material";
import React from "react";
import BillCollectionFilter from "./billCollection";
import MeterReadingFilter from "./meterReading";

const FilterFields = (props) => {
  const filter = props.filter;
  const type = props.type;
  const error = props.error;
  const handleChange = props.handleChange;
  const divisionData = props.divisionData;
  const handlePeriod = props.handlePeriod;
  const handleYear = props.handleYear;

  return (
    <Box>
      {type === "mr" ? (
        <MeterReadingFilter
          filter={filter}
          error={error}
          handleChange={handleChange}
          divisionData={divisionData}
          handlePeriod={handlePeriod}
          handleYear={handleYear}
        />
      ) : (
        <BillCollectionFilter
          filter={filter}
          error={error}
          handleChange={handleChange}
          divisionData={divisionData}
          handlePeriod={handlePeriod}
          handleYear={handleYear}
        />
      )}
    </Box>
  );
};

export default FilterFields;
