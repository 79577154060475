import { Box } from "@mui/material";
import React from "react";
import KIOSKModeReturn from "./FilterReturn/KioskModeReturn";
import ClearEmissionReturn from "./FilterReturn/ClearEmissionReturn";
import ClearDBReturn from "./FilterReturn/ClearDBReturn";
import PrintTerminalInfoReturn from "./FilterReturn/PrintTerminalInfoReturn";
import PrintReportReturn from "./FilterReturn/PrintReportReturn";
import ForceClosureReturn from "./FilterReturn/ForceClosureReturn";
import PrintSettlementReturn from "./FilterReturn/PrintSettlementReturn";

const CRPOSAdminFilterComponent = ({ type, onSend }) => {
  return (
    <Box
      sx={{
        padding: "1%",
        paddingX: "4%",
        backgroundColor: "#e2e2e2",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      {type === "kioskModeControl" && <KIOSKModeReturn onSend={onSend} />}
      {type === "clearEmission" && <ClearEmissionReturn onSend={onSend} />}
      {type === "clearDB" && <ClearDBReturn onSend={onSend} />}
      {type === "printTerminalInfo" && (
        <PrintTerminalInfoReturn onSend={onSend} />
      )}{" "}
      {type === "printReport" && <PrintReportReturn onSend={onSend} />}
      {type === "forceClosure" && <ForceClosureReturn onSend={onSend} />}
      {type === "printSettlement" && <PrintSettlementReturn onSend={onSend} />}
    </Box>
  );
};

export default CRPOSAdminFilterComponent;
