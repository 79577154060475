import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const emissionsInitialState = {
  emissionsFilter: {
    divisionsID: [],
    status: "",
    type: "",
  },
  emissionsList: [],
  newEmissionData: {
    divisionID: "",
    type: "",
    period: "",
    year: "",
    sayrafa: "",
    amount: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
  },
  integrationFilter: {
    emissionType: "",
    bu: "",
    period: "",
    year: "",
  },
};

export const mrbcEmissionsSlice = createSlice({
  name: "mrbcEmissions",
  initialState: emissionsInitialState,
  reducers: {
    setEmissionsFilter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.emissionsFilter = action.payload;
    },
    setEmissionsList: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.emissionsList = action.payload;
    },
    setNewEmissionData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.newEmissionData = action.payload;
    },
    setIntegrationFilter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.integrationFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEmissionsFilter,
  setEmissionsList,
  setNewEmissionData,
  setIntegrationFilter,
} = mrbcEmissionsSlice.actions;

export default mrbcEmissionsSlice.reducer;
