import axios from "axios";
import { HTTP_URL } from "../GlobalVar/commonVar";
import {
  setDivisionData,
  setSelectedApp,
  setSelectedPage,
  setSocket,
  setUser,
  setUserAll,
  setUserData,
  setUserInfo,
  setUserMenu,
} from "../redux/reducers/userMenuReducer";

async function login(username, password) {
  return await axios.post(
    HTTP_URL + "/session/v1/loginrequest",
    {
      username: username,
      password: password,
      environment: "test",
      application: "dsp-internal-01",
      platform: "web",
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}

async function forgetPassword(username) {
  return await axios.post(
    HTTP_URL + "/session/v1/passreset",
    {
      username: username,
      environment: "test",
      application: "dsp-internal-01",
      platform: "web",
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}

function Logout(client, socket, dispatch, cookies, navigate) {
  localStorage.removeItem("user");
  localStorage.removeItem("_userMenu");
  localStorage.removeItem("_userData");

  dispatch(setUser(false));
  cookies.remove("_user");
  dispatch(setUserMenu([]));
  cookies.remove("_userMenu");
  dispatch(setUserData([]));
  cookies.remove("_userData");
  dispatch(setUserAll([]));
  cookies.remove("_userAll");
  dispatch(setUserInfo([]));
  cookies.remove("_userInfo");
  dispatch(setSelectedPage([]));
  cookies.remove("_selectedPage");
  dispatch(setSelectedApp([]));
  cookies.remove("_selectedApp");
  dispatch(setDivisionData([]));
  cookies.remove("_divisionData");
  cookies.set("_location", "/main", {
    path: "/",
    expires: new Date(Date.now() + 2592000),
  });
  navigate("/");
  if (client) {
    client.end();
  }
  if (socket) {
    socket.disconnect();
  }
  dispatch(setSocket(null));
  cookies.remove("_socket");
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  Logout,
  forgetPassword,
  getCurrentUser,
};

export default AuthService;
