import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import BasicSwitches from "../../../components/BasicSwitch";
import { useDispatch, useSelector } from "react-redux";
import { setPosAdminFilter } from "../../../../../../Services/redux/reducers/CRPOSAdminReducer";

const KIOSKModeReturn = ({ onSend }) => {
  const dispatch = useDispatch();
  const posAdminFilter = useSelector(
    (state) => state.crposadmin.posAdminFilter
  );

  const onChange = (e, item) => {
    dispatch(
      setPosAdminFilter({
        ...posAdminFilter,
        [item]: e,
      })
    );
  };

  return (
    <Box>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            id="kioskCrid"
            value={posAdminFilter?.kioskCrid}
            onChange={(e) => onChange(e?.target?.value, "kioskCrid")}
            label="CR ID"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter CR ID.."
          />
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={0.8}>
          <BasicSwitches
            checked={posAdminFilter?.kioskMode}
            onChange={(e) => onChange(e?.target?.checked, "kioskMode")}
            label={"Mode"}
          />
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{ backgroundColor: "#064987", color: "white", width: "100%" }}
            onClick={onSend}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KIOSKModeReturn;
