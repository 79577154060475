import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import TourIcon from "@mui/icons-material/Tour";
import { Status } from "../../../MeterReading/SelectOptions/Status";

const BillCollectionFilter = ({
  filter,
  handleChange,
  divisionData,
  handlePeriod,
  handleYear,
  error,
}) => {
  return (
    <Box>
      <Box
        sx={{
          "& > :not(style)": { width: "100%" },
          marginBottom: "1%",
        }}
        noValidate
      >
        <Grid container>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="search"
              value={filter.search}
              onChange={(e) => handleChange(e.target.value, "search")}
              label="Search"
              variant="outlined"
              autoComplete="off"
              placeholder="Enter Search Text.."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2 / 6}></Grid>
          <Grid item xs={1.5}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Division ID</InputLabel>
              <Select
                label="Division ID"
                width="100%"
                value={filter.divisionID}
                onChange={(e) => handleChange(e.target.value, "divisionID")}
              >
                {divisionData?.map((data) => (
                  <MenuItem key={data.UVVALUE} value={data.UVVALUE}>
                    {data.UVVALUE} - {data.UVDESC}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2 / 6}></Grid>
          <Grid item xs={1.25}>
            <TextField
              fullWidth
              id="period"
              label="Period"
              variant="outlined"
              autoComplete="off"
              value={filter.period}
              placeholder="Enter Emission Period.."
              onChange={(e) => handlePeriod(e.target.value)}
              helperText={error.period}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2 / 6}></Grid>
          <Grid item xs={1.25}>
            <TextField
              fullWidth
              id="year"
              label="Year"
              variant="outlined"
              value={filter.year}
              helperText={error.year}
              autoComplete="off"
              onChange={(e) => handleYear(e.target.value)}
              placeholder="Enter Emission Year.."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2 / 6}></Grid>
          <Grid item xs={1.25}>
            <TextField
              fullWidth
              id="crid"
              label="CR ID"
              value={filter.crID}
              variant="outlined"
              autoComplete="off"
              placeholder="Enter CR ID.."
              onChange={(e) => handleChange(e.target.value, "crID")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PermContactCalendarIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2 / 6}></Grid>
          <Grid item xs={1.25}>
            <TextField
              fullWidth
              id="round"
              label="Round"
              variant="outlined"
              onChange={(e) => handleChange(e.target.value, "round")}
              value={filter.round}
              autoComplete="off"
              placeholder="Enter Desired Round.."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TourIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2 / 6}></Grid>
          <Grid item xs={1.5}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                width="100%"
                value={filter.status}
                onChange={(e) => handleChange(e.target.value, "status")}
              >
                {Status.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
                <MenuItem key={"UC"} value={"UC"}>
                  Under Correction
                </MenuItem>
                <MenuItem key={"C"} value={"C"}>
                  Corrected
                </MenuItem>
                <MenuItem key={"R"} value={"R"}>
                  Rejected
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BillCollectionFilter;
