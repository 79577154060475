import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCRDBAdminFilter } from "../../../../../../Services/redux/reducers/CRPOSAdminReducer";
import { Period } from "../../../components/Periods";

const ClearEmissionReturn = ({ onSend }) => {
  const dispatch = useDispatch();
  const crDBAdminFilter = useSelector(
    (state) => state.crposadmin.crDBAdminFilter
  );
  var startYear = 2015;
  var endYear = new Date().getFullYear();
  var _years = [];

  while (startYear <= endYear) {
    _years.push({ UVVALUE: startYear });
    startYear++;
  }

  const emissionType = [
    {
      UVVALUE: "mr",
      UVDESC: "Meter Reading",
    },
    {
      UVVALUE: "bc",
      UVDESC: "Bill Collection",
    },
  ];

  const onChange = (e, item) => {
    dispatch(
      setCRDBAdminFilter({
        ...crDBAdminFilter,
        [item]: e,
      })
    );
  };

  const disabled =
    crDBAdminFilter?.emissionCrid === "" ||
    crDBAdminFilter?.emissionMonth === "" ||
    crDBAdminFilter?.emissionYear === "" ||
    crDBAdminFilter?.emissionType === "";

  return (
    <Box>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            id="crid"
            value={crDBAdminFilter?.emissionCrid}
            onChange={(e) => onChange(e?.target?.value, "emissionCrid")}
            label="CR ID"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter CR ID.."
          />
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1.2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Month</InputLabel>
            <Select
              label="Month"
              sx={{ width: "100%" }}
              value={crDBAdminFilter?.emissionMonth}
              onChange={(e) => onChange(e?.target?.value, "emissionMonth")}
            >
              {Period?.map((per) => (
                <MenuItem key={per?.UVVALUE} value={per?.UVVALUE}>
                  {`${per?.UVVALUE} - ${per?.UVDESC}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={0.8}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Year</InputLabel>
            <Select
              label="Year"
              sx={{ width: "100%" }}
              value={crDBAdminFilter?.emissionYear}
              onChange={(e) => onChange(e?.target?.value, "emissionYear")}
            >
              {_years?.map((year) => (
                <MenuItem key={year?.UVVALUE} value={year?.UVVALUE}>
                  {year?.UVVALUE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1.2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Emission Type</InputLabel>
            <Select
              label="Emission Type"
              sx={{ width: "100%" }}
              value={crDBAdminFilter?.emissionType}
              onChange={(e) => onChange(e?.target?.value, "emissionType")}
            >
              {emissionType?.map((et) => (
                <MenuItem key={et?.UVVALUE} value={et?.UVVALUE}>
                  {et?.UVDESC}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{ backgroundColor: "#064987", color: "white", width: "100%" }}
            onClick={onSend}
            disabled={disabled}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClearEmissionReturn;
