import React from "react";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAddNewAddressBookData } from "../../../../../../../Services/redux/reducers/HRReducer";
import AlertDialog from "../../../../components/AlertDialog";
import AddMasterAddressBookForm from "./AddMasterAddressBookForm";
import {
  setNotificationSnackBar,
  setUser,
} from "../../../../../../../Services/redux/reducers/userMenuReducer";
import allData from "../../../../../../../Services/Data";

const AddMasterAddressBook = ({ open, setOpen }) => {
  const addNewAddressBookData = useSelector(
    (state) => state.hr.addNewAddressBookData
  );
  const userAll = useSelector((state) => state.menu.userAll);
  const dispatch = useDispatch();

  const [openSure, setOpenSure] = useState(false);

  const handleClose = () => {
    setOpen(false);
    dispatch(
      setAddNewAddressBookData({
        title: "",
        addressNumber: "",
        bu: "",
        dpt: "",
        post: "",
        fullName: "",
        phoneCode: "",
        phoneNumber: "",
        address1: "",
        address2: "",
        email: "",
        idNumber: "",
      })
    );
  };

  const handleCloseSure = () => {
    setOpenSure(false);
  };

  function handleChange(e, key, mess, regex, s, skey) {
    dispatch(
      setAddNewAddressBookData({
        ...addNewAddressBookData,
        [key]: e,
        [skey]: s,
      })
    );
  }

  const buttonDisabled =
    addNewAddressBookData.title === "" ||
    addNewAddressBookData.addressNumber === "" ||
    addNewAddressBookData.bu === "" ||
    addNewAddressBookData.dpt === "" ||
    addNewAddressBookData.post === "" ||
    addNewAddressBookData.fullName === "" ||
    addNewAddressBookData.email === "" ||
    addNewAddressBookData.address1 === "";

  const sureClicked = () => {
    const x = {
      title: addNewAddressBookData.title,
      bu: addNewAddressBookData.bu,
      dpt: addNewAddressBookData.dpt,
      post: addNewAddressBookData.post,
      phoneCode: addNewAddressBookData.phoneCode,
      phonePrefix: "70",
      phoneNbr: addNewAddressBookData.phoneNumber,
      address1: addNewAddressBookData.address1,
      address2: addNewAddressBookData.address2,
      email: addNewAddressBookData.email,
      fullName: addNewAddressBookData.fullName,
      identity: addNewAddressBookData.idNumber,
      addressNbr: addNewAddressBookData.addressNumber,
    };
    allData
      .getSuperData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "addressbook",
        "addBook",
        x,
        "admindsp/v1/adminuser"
      )
      .then(
        (response) => {
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: "User added successfully!",
              type: "success",
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        }
      );

    setOpen(false);
    setOpenSure(false);
    handleClose();
  };

  return (
    <Box>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        title={"Add New Address Book"}
        content={
          <AddMasterAddressBookForm
            details={addNewAddressBookData}
            handleChange={handleChange}
          />
        }
        buttonTitle={"ADD"}
        details={addNewAddressBookData}
        buttonClick={() => setOpenSure(true)}
        buttonDisabled={buttonDisabled}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        buttonTitle={"Yes"}
        buttonClick={sureClicked}
      />
    </Box>
  );
};

export default AddMasterAddressBook;
