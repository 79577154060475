import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardComponent from "../Dashboard";
import FilterComponent from "../Filter";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import allData from "../../../../../../Services/Data";
import { setUser } from "../../../../../../Services/redux/reducers/userMenuReducer";
import gettingData from "../Services/supervisorsAccess";
import { setDashboardSummary } from "../../../../../../Services/redux/reducers/LVMRBCReducer";
import { getPizzaPieOptions } from "../../../chatTickets/dashboard/ChartsOptions";

const MRDashboard = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const divisionData = useSelector((state) => state.menu.divisionData);
  const dashboardSummary = useSelector(
    (state) => state.lvmrbc.dashboardSummary
  );
  const [searchedData, setSearchedData] = useState([]);
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.menu.socket);

  useEffect(() => {
    gettingData.superAccessData(socket, dispatch);
  }, [socket]);

  function getData(x) {
    setIsLoading(true);
    dispatch(setDashboardSummary({ ...dashboardSummary, loading: true }));
    setHttpError("");
    setStatus(x.status);
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "dashdata01",
        x,
        "mrbc/spv/v1/emission"
      )
      .then(
        (response) => {
          setSearchedData(response?.data?.dashdata01);
          setIsLoading(false);
          const readingsSummaryOptions = getPizzaPieOptions(
            response?.data?.dashdata01?.dash1?.map((resp) => {
              return {
                value: resp?.count,
                name:
                  (resp?.MRACAT1 === null && "Total") ||
                  (resp?.MRACAT1 === "D" && "Done") ||
                  (resp?.MRACAT1 === "N" && "Unvisited") ||
                  (resp?.MRACAT1 === "V1" && "Visited Once") ||
                  (resp?.MRACAT1 === "V2" && "Visited Twice"),
              };
            }),
            ""
          );
          dispatch(
            setDashboardSummary({
              data: readingsSummaryOptions,
              error: false,
              loading: false,
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
          dispatch(
            setDashboardSummary({ ...dashboardSummary, loading: false })
          );
        }
      );
  }

  const chart1 = {
    labels: searchedData?.dash0?.map((data) => data.MRADATE),
    datasets: [
      {
        label:
          (status === "All" && "Total") ||
          (status === "D" && "Done") ||
          (status === "N" && "Unvisited") ||
          (status === "V1" && "Visited Once") ||
          (status === "V2" && "Visited Twice"),
        data: searchedData?.dash0?.map((data) => data.count),
        backgroundColor: "rgba(101, 3, 3, 0.8)",
      },
    ],
  };

  const chart3 = {
    labels: [
      "Meme index",
      "0-100 KW",
      "100-200 KW",
      "200-300 KW",
      "300-400 KW",
      "400-500 KW",
      ">=500 KW",
    ],
    datasets: [
      {
        label: "Consumption",
        data: [
          `${searchedData?.dash2?.[0]?.count0}`,
          `${searchedData?.dash2?.[0]?.count1}`,
          `${searchedData?.dash2?.[0]?.count2}`,
          `${searchedData?.dash2?.[0]?.count3}`,
          `${searchedData?.dash2?.[0]?.count4}`,
          `${searchedData?.dash2?.[0]?.count5}`,
          `${searchedData?.dash2?.[0]?.count6}`,
        ],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  const chart4 = {
    labels:
      searchedData?.dash3?.map((data) => data.MRMONTH) +
      "-" +
      searchedData?.dash3?.map((data) => data.MRYEAR),
    datasets: [
      {
        label: "Consumption per emission",
        data: searchedData?.dash3?.map((data) => data.NEWCONSUMPTION),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
      },
    ],
  };

  return (
    <Box>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          color: "#064987",
          fontSize: "1.5rem",
          marginY: "1%",
        }}
      >
        Meter Reading Dashboard
      </Grid>
      <Grid>
        <FilterComponent
          divisionData={divisionData}
          getData={getData}
          setSearchedData={setSearchedData}
          type={"mr"}
        />
      </Grid>
      {Object.keys(searchedData)?.length > 0 && !isLoading && !httpError && (
        <Grid>
          <DashboardComponent
            type={"mr"}
            chart1={chart1}
            chart3={chart3}
            chart4={chart4}
          />
        </Grid>
      )}
      {isLoading && (
        <Grid sx={{ display: "flex", justifyContent: "center", marginY: "4%" }}>
          <Ring size={40} lineWeight={5} speed={2} color="#064987" />
        </Grid>
      )}
      {!isLoading && httpError && (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            marginY: "4%",
            color: "#064987",
          }}
        >
          {httpError}
        </Grid>
      )}
    </Box>
  );
};

export default MRDashboard;
