import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import FilterEmissions from "./Filter";
import BodyEmissionsComponent from "./Body";
import { useDispatch, useSelector } from "react-redux";
import { setNewEmissionData } from "../../../../../Services/redux/reducers/mrbcEmissionsReducer";
import { Ring } from "@uiball/loaders";
import AlertDialog from "../../components/AlertDialog";
import ImportNewEmission from "./NewEmission";
import gettingData from "../../mrbc/MrbcItems/Services/supervisorsAccess";
import moment from "moment";
import { setNotificationSnackBar } from "../../../../../Services/redux/reducers/userMenuReducer";

const ImportEmissionsComponent = () => {
  const socket = useSelector((state) => state.menu.socket);
  const socketmrbc = useSelector((state) => state.menu.socketmrbc);
  const newEmissionData = useSelector(
    (state) => state.mrbcEmissions.newEmissionData
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openNewOne, setOpenNewOne] = useState(false);

  useEffect(() => {
    gettingData.superAccessData(socket, dispatch);
  }, [socket]);

  const handleClose = () => {
    setOpenNewOne(false);
    dispatch(
      setNewEmissionData({
        divisionID: "",
        type: "",
        period: "",
        year: "",
        sayrafa: "",
        amount: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  };

  const onImportingNewEmission = () => {
    socketmrbc?.emit(
      "dspinternal:web:mrbc:admin:v1",
      {
        request: "mrbcEmission",
        mrbcEmission: {
          type: "emissionImport",
          emissionImport: {
            bu: newEmissionData?.divisionID,
            emissionType: newEmissionData?.type,
            period: newEmissionData?.period,
            year: newEmissionData?.year,
            sayrafaRate: newEmissionData?.sayrafa,
            amount: newEmissionData?.amount,
            emissionDate: newEmissionData?.date,
          },
        },
      },
      (response) => {
        if (response?.replyType === "message") {
          if (response?.message?.type === "success") {
            dispatch(
              setNewEmissionData({
                divisionID: "",
                type: "",
                period: "",
                year: "",
                sayrafa: "",
                amount: "",
                date: moment(new Date()).format("YYYY-MM-DD"),
              })
            );
          }
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: response?.message?.text,
              type: response?.message?.type,
            })
          );
        }
        setOpenNewOne(false);
      }
    );
  };

  const disabled =
    (newEmissionData?.type === "MV" &&
      (newEmissionData?.sayrafa === "" || newEmissionData?.amount === "")) ||
    newEmissionData?.divisionID === "" ||
    newEmissionData?.type === "" ||
    newEmissionData?.period === "" ||
    newEmissionData?.year === "" ||
    newEmissionData?.date === "";

  return (
    <Box>
      <Grid>
        <FilterEmissions
          setIsLoading={setIsLoading}
          setOpenNewOne={setOpenNewOne}
        />
      </Grid>
      {!isLoading ? (
        <Grid sx={{ padding: "3%" }}>
          <BodyEmissionsComponent />
        </Grid>
      ) : (
        <Grid
          sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
        >
          <Ring size={40} lineWeight={5} speed={2} color="#064987" />
        </Grid>
      )}
      {openNewOne && (
        <AlertDialog
          open={openNewOne}
          handleClose={handleClose}
          title={"Import New Emission"}
          content={<ImportNewEmission />}
          buttonTitle={"Import"}
          buttonDisabled={disabled}
          buttonClick={onImportingNewEmission}
        />
      )}
    </Box>
  );
};

export default ImportEmissionsComponent;
