import * as React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";

export default function StandaloneToggleButton({ onChange, newRequest }) {
  return (
    <ToggleButton
      value="check"
      selected={newRequest?.flightStatus}
      onChange={() => {
        onChange(!newRequest?.flightStatus, "flightStatus");
      }}
      sx={{
        backgroundColor: "grey",
        width: "10%",
        height: "10%",
      }}
    >
      <CheckIcon />
    </ToggleButton>
  );
}
