export const Period = [
  { UVVALUE: "1", UVDESC: "January" },
  { UVVALUE: "2", UVDESC: "February" },
  { UVVALUE: "3", UVDESC: "March" },
  { UVVALUE: "4", UVDESC: "April" },
  { UVVALUE: "5", UVDESC: "May" },
  { UVVALUE: "6", UVDESC: "June" },
  { UVVALUE: "7", UVDESC: "July" },
  { UVVALUE: "8", UVDESC: "August" },
  { UVVALUE: "9", UVDESC: "September" },
  { UVVALUE: "10", UVDESC: "October" },
  { UVVALUE: "11", UVDESC: "November" },
  { UVVALUE: "12", UVDESC: "December" },
];
