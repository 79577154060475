import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { MUICheckbox } from "../../../../Styles/overrides";
import { useDispatch, useSelector } from "react-redux";
import { setMasterInfoData } from "../../../../../../../Services/redux/reducers/HRReducer";
import {
  setBusinessUnits,
  setDepartments,
  setPositions,
} from "../../../../../../../Services/redux/reducers/adminsReducer";

const FilterMasterInfoReturn = ({
  filterMasterInfo,
  handleChanges,
  filteringData,
  setIsLoading,
  socket,
  setOpen,
}) => {
  const alignmenmt = {
    display: "flex",
    alignItems: "center",
    ".css-sde65v-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "36.7px",
    },
    ".css-11fo197": {
      color: "white",
    },
    ".css-vamk89-MuiFormControl-root": { width: "100%" },
  };
  const socketHR = useSelector((state) => state.menu.socketHR);

  const disableSearch = filterMasterInfo?.employees?.length <= 0;
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredOptions = filteringData?.employees?.filter(
    (option) =>
      option?.ABFULLNAME?.toLowerCase()?.includes(searchTerm) ||
      option?.ABLINEID?.toString()?.includes(searchTerm)
  );

  function getData(x) {
    setIsLoading(true);
    const filter = {
      empList: x?.employees,
    };
    socketHR.emit(
      "dspinternal:web:hr:admin:v1",
      {
        request: "addressBook",
        addressBook: {
          type: "getData",
          getData: filter,
        },
      },
      (response) => {
        dispatch(setMasterInfoData(response?.data));
        setIsLoading(false);
        getAddOptions("PBL", "BU");
        getAddOptions("HR", "DPT");
        getAddOptions("HR", "POST");
      }
    );
  }

  const getAddOptions = (cat1, cat2) => {
    socket.emit(
      "dspinternal:control:data",
      {
        request: "dataAccess",
        dataAccess: {
          type: "udc",
          udc: {
            cat1: cat1,
            cat2: cat2,
          },
        },
      },
      (response) => {
        if (cat2 === "BU") {
          dispatch(setBusinessUnits(response));
        } else if (cat2 === "DPT") {
          dispatch(setDepartments(response));
        } else if (cat2 === "POST") {
          dispatch(setPositions(response));
        }
      }
    );
  };

  return (
    <Grid container sx={alignmenmt}>
      <Grid container sx={{ marginBottom: "1%" }}>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Business Unit
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterMasterInfo.businessUnit?.length <= 0
                  ? []
                  : filterMasterInfo.businessUnit
              }
              onChange={(e) => handleChanges.handleBUChange(e.target.value)}
              input={<OutlinedInput label="Business Unit" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterMasterInfo.businessUnit?.length ===
                    filteringData?.businessUnit?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteringData.businessUnit?.map((unit) => (
                <MenuItem key={unit?.BFBU} value={unit?.BFBU}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      filterMasterInfo.businessUnit?.indexOf(unit?.BFBU) > -1
                    }
                  />
                  <ListItemText primary={`${unit?.BFBU} - ${unit?.BUDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.6 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Department
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterMasterInfo?.department?.length <= 0
                  ? []
                  : filterMasterInfo?.department
              }
              onChange={(e) =>
                handleChanges.handleDepartmentChange(e.target.value)
              }
              input={<OutlinedInput label="Department" />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterMasterInfo.department?.length ===
                    filteringData?.department?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteringData.department?.map((dep) => (
                <MenuItem key={dep.BFDPT} value={dep.BFDPT}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      filterMasterInfo?.department?.indexOf(dep?.BFDPT) > -1
                    }
                  />
                  <ListItemText primary={`${dep.BFDPT} - ${dep.DPTDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.6 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Position</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterMasterInfo?.position?.length <= 0
                  ? []
                  : filterMasterInfo?.position
              }
              onChange={(e) =>
                handleChanges.handlePositionChange(e.target.value)
              }
              input={<OutlinedInput label="Position" />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterMasterInfo.position?.length ===
                    filteringData?.position?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteringData.position?.map((position) => (
                <MenuItem key={position?.BFPOST} value={position?.BFPOST}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      filterMasterInfo?.position?.indexOf(position?.BFPOST) > -1
                    }
                  />
                  <ListItemText
                    primary={`${position?.BFPOST} - ${position?.POSTDESC}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.6 / 5}></Grid>
        <Grid item xs={3} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="searchable-select-label">Employees</InputLabel>
            <Select
              labelId="searchable-select-label"
              id="searchable-select"
              multiple
              value={
                filterMasterInfo?.employees?.length <= 0
                  ? []
                  : filterMasterInfo?.employees
              }
              onChange={(e) =>
                handleChanges.handleEmployeesChange(e.target.value)
              }
              input={<OutlinedInput label="Employees" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  'input[type="text"], input[type="password"]': {
                    color: "white !important",
                  },
                }}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(event) => event.stopPropagation()}
              >
                <TextField
                  variant="standard"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  onClick={(event) => event.stopPropagation()}
                  fullWidth
                />
              </MenuItem>
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterMasterInfo.employees?.length ===
                    filteringData?.employees?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteredOptions.map((employee) => (
                <MenuItem key={employee.ABLINEID} value={employee.ABLINEID}>
                  <Checkbox
                    checked={
                      filterMasterInfo?.employees.indexOf(employee.ABLINEID) >
                      -1
                    }
                    sx={MUICheckbox}
                  />
                  <ListItemText
                    primary={`${employee.ABLINEID} - ${employee.ABFULLNAME}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.6 / 5}></Grid>
        <Grid item xs={0.5} sx={alignmenmt}>
          <Button
            sx={{
              backgroundColor: !disableSearch ? "#064987" : "#afabab",
              width: "100%",
            }}
            disabled={disableSearch}
            onClick={() => getData(filterMasterInfo)}
          >
            <SearchIcon sx={{ color: "white" }} />
          </Button>
        </Grid>
        <Grid item xs={1.6 / 5}></Grid>
        <Grid item xs={1.5} sx={alignmenmt}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              width: "100%",
            }}
            onClick={() => {
              setOpen(true);
              getAddOptions("PBL", "BU");
              getAddOptions("HR", "DPT");
              getAddOptions("HR", "POST");
            }}
          >
            Address Book
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterMasterInfoReturn;
