import * as React from "react";
import { Box, Grid } from "@mui/material";

import { Navigate, Route, Routes } from "react-router-dom";
import Topbar from "./components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/Sidebar";
import MRBCDefault from "./mrbc/mrbc";
import MeterReadingComponent from "./mrbc/MrbcItems/MeterReading";
import BillCollectionComponent from "./mrbc/MrbcItems/BillCollection";
import BCDashboard from "./mrbc/MrbcItems/BCDashboard";
import MRDashboard from "./mrbc/MrbcItems/MRDashboard";
import ControlsComponent from "./mrbc/MrbcItems/Controls";
import SubUsersComponent from "./subUsers";
import SuperAdminsComponent from "./superAdmin";
import SuperUsersComponent from "./superAdmin/Users";
import SuperPagesComponent from "./superAdmin/Pages";
import TicketsComponent from "./chatTickets";
import TicketListComponent from "./chatTickets/ticketList";
import DashboardListComponent from "./chatTickets/dashboard";
import { SnackbarProvider } from "notistack";
import SimpleSnackbar from "./components/NotificationMessages";
import HumanResoursesComponent from "./humanResource";
import AttendanceComponent from "./humanResource/Attendance";
import MRBCEmissionsComponent from "./mrbcEmissions";
import ImportEmissionsComponent from "./mrbcEmissions/ImportEmissions";
import Cookies from "universal-cookie";
import MRBCMVComponent from "./mrbcMV";
import MVAVISComponent from "./mrbcMV/DataEntry/AVIS";
import CustomizedSnackbars from "./components/NotificationAlert";
import { setNotificationSnackBar } from "../../../Services/redux/reducers/userMenuReducer";
import MasterInfoComponent from "./humanResource/AddressBook/MasterInfo";
import DepartmentsComponent from "./humanResource/AddressBook/Departments";
import PositionsComponent from "./humanResource/AddressBook/Positions";
import UsersSecurityComponent from "./superAdmin/UsersSecurity";
import LeaveRequestComponent from "./humanResource/LeaveRequest";
import MyHRComponent from "./myHR";
import MyHRLeaveRequestComponent from "./myHR/LeaveRequest";
import SuperHRComponent from "./superHR";
import SuperHRLeaveRequestComponent from "./superHR/LeaveRequest";
import HolidaysComponent from "./humanResource/Holidays";
import CRPOSAdminComponent from "./CRPOSAdmin";
import POSAdminComponent from "./CRPOSAdmin/POSAdmin";
import CRDBAdminComponent from "./CRPOSAdmin/CRDBAdmin";
import MMSJDEIntegrationComponent from "./mrbcEmissions/MMSJDEIntegration";

export default function MainLayout(props) {
  const userMenu = useSelector((state) => state.menu.userMenu);
  const userAll = useSelector((state) => state.menu.userAll);
  const client = useSelector((state) => state.menu.client);
  const notificationSnackBar = useSelector(
    (state) => state.menu.notificationSnackBar
  );
  const dispatch = useDispatch();
  // const [client, setClient] = React.useState(null);
  // get the new msg to display as notification
  const notificationmessages = useSelector(
    (state) => state.ticket.notificationmessages
  );
  // define props
  const state = props?.state;
  const path = props?.location;

  // cookies
  const cookies = new Cookies();
  const selectedPage = cookies.get("_selectedPage");

  const selectedPageValue = userMenu?.filter(
    (menu) => menu?.AIDESC === selectedPage
  )?.[0]?.AIVALUE;

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setNotificationSnackBar({
        open: false,
        message: "",
        type: "",
      })
    );
  };

  return (
    <div className="mainDiv">
      <Grid container height={"100%"}>
        {/* display the side bar */}
        <Grid
          // item
          // lg={0.2}
          // md={0.8}
          // xs={1}
          sx={{ height: "auto", width: "4%", minHeight: "100vh" }}
        >
          <Sidebar />
        </Grid>
        <Grid
          // item lg={11.8} md={11.2} xs={11}
          sx={{ height: "auto", width: "96%", minHeight: "100vh" }}
        >
          <Box width="5px" className="content" />
          <main className="content">
            {/* display the top bar */}
            <Box className="topbar">
              <Topbar client={client} />
            </Box>
            {/* display all the contents as route with each link */}
            <Box sx={{ overflow: "hidden" }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate to={state ? selectedPageValue : "/main"} />
                  }
                />
                {/* LV MRBC */}
                <Route
                  exact
                  path="spvlvmrbc01"
                  element={<MRBCDefault state={state} path={path} />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01a"
                  element={<MeterReadingComponent />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01b"
                  element={<BillCollectionComponent />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01c"
                  element={<BCDashboard />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01d"
                  element={<MRDashboard />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01e"
                  element={<ControlsComponent />}
                />
                {/* Sub Users */}
                <Route
                  exact
                  path="admindspsub"
                  element={<SubUsersComponent />}
                />
                {/* super admin */}
                <Route
                  exact
                  path="superadmin01"
                  element={<SuperAdminsComponent state={state} path={path} />}
                />
                <Route
                  exact
                  path="superadmin01/superadmin01a"
                  element={<SuperPagesComponent />}
                />
                <Route
                  exact
                  path="superadmin01/superadmin01b"
                  element={<SuperUsersComponent />}
                />
                <Route
                  exact
                  path="superadmin01/superadmin01c"
                  element={<UsersSecurityComponent />}
                />
                {/* chat ticket */}
                <Route
                  exact
                  path="chatickets01"
                  element={<TicketsComponent state={state} path={path} />}
                />
                <Route
                  exact
                  path="chatickets01/chatickets01a"
                  element={<TicketListComponent client={client} />}
                />
                <Route
                  exact
                  path="chatickets01/chatickets01b"
                  element={<DashboardListComponent client={client} />}
                />
                {/* human resources */}
                <Route
                  exact
                  path="humanresources01"
                  element={
                    <HumanResoursesComponent state={state} path={path} />
                  }
                />
                <Route
                  exact
                  path="humanresources01/humanresources01a"
                  element={<AttendanceComponent />}
                />
                <Route
                  exact
                  path="humanresources01/humanresources01b"
                  element={<MasterInfoComponent />}
                />
                <Route
                  exact
                  path="humanresources01/humanresources01c"
                  element={<DepartmentsComponent />}
                />
                <Route
                  exact
                  path="humanresources01/humanresources01d"
                  element={<PositionsComponent />}
                />
                <Route
                  exact
                  path="humanresources01/humanresources01e"
                  element={<LeaveRequestComponent />}
                />
                <Route
                  exact
                  path="humanresources01/humanresources01f"
                  element={<HolidaysComponent />}
                />
                {/* mrbc admin */}
                <Route
                  exact
                  path="mrbcadmin01"
                  element={<MRBCEmissionsComponent state={state} path={path} />}
                />
                <Route
                  exact
                  path="mrbcadmin01/mrbcadmin01a"
                  element={<ImportEmissionsComponent />}
                />
                <Route
                  exact
                  path="mrbcadmin01/mrbcadmin01b"
                  element={<MMSJDEIntegrationComponent />}
                />
                {/* mv mrbc */}
                <Route
                  exact
                  path="mvmrbc01"
                  element={<MRBCMVComponent state={state} path={path} />}
                />
                <Route
                  exact
                  path="mvmrbc01/mvmrbc01a"
                  element={<MVAVISComponent />}
                />
                {/* my hr */}
                <Route
                  exact
                  path="myHR01"
                  element={<MyHRComponent state={state} path={path} />}
                />
                <Route
                  exact
                  path="myHR01/myHR01a"
                  element={<MyHRLeaveRequestComponent />}
                />
                {/* supervisor hr */}
                <Route
                  exact
                  path="supervisorHR01"
                  element={<SuperHRComponent state={state} path={path} />}
                />
                <Route
                  exact
                  path="supervisorHR01/supervisorHR01a"
                  element={<SuperHRLeaveRequestComponent />}
                />
                {/* mrbc pos admin */}
                <Route
                  exact
                  path="mrbcposadmin01"
                  element={<CRPOSAdminComponent state={state} path={path} />}
                />
                <Route
                  exact
                  path="mrbcposadmin01/mrbcposadmin01a"
                  element={<POSAdminComponent />}
                />
                <Route
                  exact
                  path="mrbcposadmin01/mrbcposadmin01b"
                  element={<CRDBAdminComponent />}
                />
              </Routes>
            </Box>
          </main>
        </Grid>
        {/* display the notification for the new messages */}
        {Object.keys(notificationmessages)?.length > 0 && (
          <Grid
            item
            xs={12}
            sx={{
              display: "grid",
              gridTemplateColumns: "45%",
              height: "100%",
              backgroundColor: "red",
              ".go3844575157": {
                backgroundColor: "#8e8b8b",
              },
              ".go946087465": {
                width: "85%",
              },
            }}
          >
            <SnackbarProvider
              maxSnack={5}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <SimpleSnackbar
                msg={notificationmessages}
                userAll={userAll}
                client={client}
              />
            </SnackbarProvider>
          </Grid>
        )}
        {notificationSnackBar?.open && (
          <CustomizedSnackbars
            open={notificationSnackBar?.open}
            handleClose={handleCloseSnackBar}
            severity={notificationSnackBar?.type}
            message={notificationSnackBar?.message}
          />
        )}
      </Grid>
    </div>
  );
}
