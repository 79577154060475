import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import TourIcon from "@mui/icons-material/Tour";
import React from "react";

const ControlsOpenRound = (props) => {
  const filter = props.filter;
  const divisionData = props.divisionData;
  const handleChange = props.handleChange;
  const handlePeriod = props.handlePeriod;
  const handleYear = props.handleYear;
  const error = props.error;
  const setTypeControl = props.setTypeControl;
  const typeControl = props.typeControl;

  return (
    <Box marginBottom={"1%"}>
      <Grid container>
        <Grid item xs={1.75}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              width="100%"
              value={typeControl}
              onChange={(e) => setTypeControl(e.target.value)}
            >
              <MenuItem key={"mr"} value={"mr"}>
                {"Meter Reading"}
              </MenuItem>
              <MenuItem key={"bc"} value={"bc"}>
                {"Bill Collection"}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.5 / 5}></Grid>
        <Grid item xs={1.75}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Division ID</InputLabel>
            <Select
              label="Division ID"
              width="100%"
              value={filter.divisionID}
              onChange={(e) => handleChange(e.target.value, "divisionID")}
            >
              {divisionData?.map((data) => (
                <MenuItem key={data.UVVALUE} value={data.UVVALUE}>
                  {data.UVVALUE} - {data.UVDESC}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.5 / 5}></Grid>
        <Grid item xs={1.75}>
          <TextField
            fullWidth
            id="period"
            label="Period"
            variant="outlined"
            autoComplete="off"
            value={filter.period}
            placeholder="Enter Emission Period.."
            onChange={(e) => handlePeriod(e.target.value)}
            helperText={error.period}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={1.5 / 5}></Grid>
        <Grid item xs={1.75}>
          <TextField
            fullWidth
            id="year"
            label="Year"
            variant="outlined"
            value={filter.year}
            helperText={error.year}
            autoComplete="off"
            onChange={(e) => handleYear(e.target.value)}
            placeholder="Enter Emission Year.."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={1.5 / 5}></Grid>
        <Grid item xs={1.75}>
          <TextField
            fullWidth
            id="crid"
            label="CR ID"
            value={filter.crID}
            variant="outlined"
            autoComplete="off"
            placeholder="Enter CR ID.."
            onChange={(e) => handleChange(e.target.value, "crID")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PermContactCalendarIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={1.5 / 5}></Grid>
        <Grid item xs={1.75}>
          <TextField
            fullWidth
            id="round"
            label="Round"
            variant="outlined"
            onChange={(e) => handleChange(e.target.value, "round")}
            value={filter.round}
            autoComplete="off"
            placeholder="Enter Desired Round.."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TourIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ControlsOpenRound;
