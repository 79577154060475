import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSelector } from "react-redux";

const PagesInfo = ({ data, removePage, handlePageChanges }) => {
  const editedPagesData = useSelector((state) => state.admins.editedPagesData);

  const statement = editedPagesData?.filter(
    (ob) => ob?.AILINEID === data?.AILINEID
  );

  const values = (item) => {
    if (statement?.length > 0) {
      if (statement?.[0][item]) {
        return statement?.[0][item];
      } else {
        return "";
      }
    } else {
      if (data[item]) {
        return data[item];
      } else {
        return "";
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        'input[type="text"], input[type="password"]': { height: "10px" },
        ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
          WebkitTextFillColor: "rgb(0 0 0 / 61%) !important",
        },
      }}
    >
      <Grid container>
        <Grid item xs={0.5}>
          <TextField
            fullWidth
            id="id"
            label="ID"
            value={values("AILINEID")}
            disabled
            // onChange={(e) => handleChange(e.target.value, "icon")}
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            id="type"
            label="Type"
            value={values("AITYPE")}
            disabled
            // onChange={(e) => handleChange(e.target.value, "icon")}
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            id="platform"
            label="Platform"
            value={values("AIPLATFORM")}
            disabled
            // onChange={(e) => handleChange(e.target.value, "icon")}
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="descrption"
            label="Description"
            value={values("AIDESC")}
            onChange={(e) => handlePageChanges(e.target.value, data, "AIDESC")}
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="text"
            label="Text"
            value={values("AITEXT")}
            onChange={(e) => handlePageChanges(e.target.value, data, "AITEXT")}
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="value"
            label="Value"
            value={values("AIVALUE")}
            onChange={(e) => handlePageChanges(e.target.value, data, "AIVALUE")}
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            id="status"
            label="Status"
            value={values("AISTATUS")}
            onChange={(e) =>
              handlePageChanges(e.target.value, data, "AISTATUS")
            }
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            id="icon"
            label="MUI Icon Name"
            value={values("AIICON")}
            onChange={(e) => handlePageChanges(e.target.value, data, "AIICON")}
          />
        </Grid>
        <Grid item xs={1 / 8}></Grid>
        <Grid
          item
          xs={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button onClick={removePage}>
            <RemoveCircleIcon sx={{ color: "red" }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PagesInfo;
