import { Box } from "@mui/material";
import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setAttendanceBreakData,
  setAttendanceData,
  setAttendanceDaysData,
  setAttendanceDetails,
  setHRFilter,
} from "../../../../../Services/redux/reducers/HRReducer";
import {
  getBarColumnsOptions,
  getPizzaPieOptions,
} from "../../chatTickets/dashboard/ChartsOptions";
import FilterAttendanceReturn from "../Attendance/FilterReturn";
import FilterMasterInfoReturn from "../AddressBook/MasterInfo/FilterReturn";
import FilterDPTPOSTReturn from "../AddressBook/FilterDPTPOSTReturn";
import FilterHolidaysReturn from "../Holidays/FilterReturn";

const FilterHRComponent = ({
  socket,
  socketHR,
  setIsLoading,
  app,
  setOpen,
  dptPost,
  setSearched,
}) => {
  const allEmployees = useSelector((state) => state.hr.allEmployees);
  const HRFilter = useSelector((state) => state.hr.HRFilter);
  const attendanceData = useSelector((state) => state.hr.attendanceData);
  const attendanceBreakData = useSelector(
    (state) => state.hr.attendanceBreakData
  );
  const attendanceDaysData = useSelector(
    (state) => state.hr.attendanceDaysData
  );
  const dispatch = useDispatch();
  //convert data to real date
  function convertData(response, type) {
    const convertedData = response?.map((item) => {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        // Check if the property value is a string
        if (type === "shift" ? key === "SHIFTHOURS" : key === "BREAKHOURS") {
          newItem[key] = [item[key]];
        } else {
          newItem[key] = item[key];
        }
      });
      return newItem;
    });
    const date = response?.map((resp) =>
      moment(resp?.BGDATE)?.format("YYYY-MM-DD")
    );
    const arrayHashmap = convertedData?.reduce((obj, item) => {
      if (obj[item.ABLINEID]) {
        if (
          date?.includes(moment(item.BGDATE)?.format("YYYY-MM-DD")) &&
          Math.sign(type === "shift" ? item.SHIFTHOURS : item.BREAKHOURS) > 0
        ) {
          type === "shift"
            ? obj[item.ABLINEID].SHIFTHOURS.push(...item.SHIFTHOURS)
            : obj[item.ABLINEID].BREAKHOURS.push(...item.BREAKHOURS);
        } else {
          type === "shift"
            ? obj[item.ABLINEID].SHIFTHOURS.push("0")
            : obj[item.ABLINEID].BREAKHOURS.push("0");
        }
      } else {
        obj[item.ABLINEID] = { ...item };
      }
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    return mergedArray;
  }

  function getData(x) {
    setIsLoading(true);
    dispatch(setAttendanceData({ ...attendanceData, loading: true }));
    dispatch(setAttendanceBreakData({ ...attendanceBreakData, loading: true }));
    dispatch(setAttendanceDaysData({ ...attendanceDaysData, loading: true }));
    const filter = {
      checkType: x?.check,
      breakType: x?.break,
      users: x?.employees,
      fromDate: x?.fromdate,
      toDate: x?.todate,
    };
    socketHR.emit(
      "dspinternal:web:hr:spv:v1",
      {
        request: "attendanceUsersLogs",
        attendanceUsersLogs: filter,
      },
      (response) => {
        setIsLoading(false);
        dispatch(setAttendanceDetails(response));
        const shiftArray = convertData(response?.[1], "shift");
        const breakArray = convertData(response?.[3], "break");
        const avgShift =
          response?.[1].reduce((accumulator, object) => {
            const condition =
              Math.sign(object.SHIFTHOURS) > 0 &&
              Number(object.SHIFTHOURS) < 24;
            return accumulator + (condition ? Number(object.SHIFTHOURS) : 0);
          }, 0) / response?.[1].length;
        const avgBreak =
          response?.[3].reduce((accumulator, object) => {
            return (
              accumulator +
              (Math.sign(object.BREAKHOURS) > 0 &&
              Math.sign(object.BREAKHOURS) < 24
                ? Number(object.BREAKHOURS)
                : 0)
            );
          }, 0) / response?.[3].length;

        const dateRemoveDupl = response?.[1]?.filter(
          (ele, ind) =>
            ind ===
            response?.[1].findIndex(
              (resp) =>
                moment(resp?.BGDATE)?.format("YYYY-MM-DD") ===
                moment(ele?.BGDATE)?.format("YYYY-MM-DD")
            )
        );

        const breakDateRemoveDupl = response?.[3]?.filter(
          (ele, ind) =>
            ind ===
            response?.[1].findIndex(
              (resp) =>
                moment(resp?.BGDATE)?.format("YYYY-MM-DD") ===
                moment(ele?.BGDATE)?.format("YYYY-MM-DD")
            )
        );

        const namesRemoveDupl = response?.[2]?.filter(
          (ele, ind) =>
            ind ===
            response?.[2].findIndex(
              (resp) => resp?.ABFULLNAME === ele?.ABFULLNAME
            )
        );

        const attendanceOptions = getBarColumnsOptions(
          shiftArray?.map((resp) => {
            return {
              name: `${resp?.ABFULLNAME}`,
              type: "bar",
              data: resp?.SHIFTHOURS?.map((data) =>
                data >= 0 && data < 24 ? data : 0
              ).reverse(),
              barMaxWidth: "10%",
            };
          }),
          dateRemoveDupl?.map((resp) =>
            moment(resp?.BGDATE)?.format("YYYY-MM-DD")
          ),
          "Attendance Hours Per Shift",
          `Average Hours = ${avgShift.toFixed(3)}`
        );
        const attendanceBreakOptions = getBarColumnsOptions(
          breakArray?.map((resp) => {
            return {
              name: `${resp?.ABFULLNAME}`,
              type: "bar",
              data: resp?.BREAKHOURS?.map((data) =>
                data >= 0 && data < 24 ? data : 0
              ).reverse(),
              barMaxWidth: "10%",
            };
          }),
          breakDateRemoveDupl?.map((resp) =>
            moment(resp?.BGDATE)?.format("YYYY-MM-DD")
          ),
          "Attendance Hours Per Break",
          `Average Hours = ${avgBreak.toFixed(3)}`
        );

        const attendanceDaysPizzaPieOptions = getPizzaPieOptions(
          namesRemoveDupl?.map((resp) => {
            return { value: resp?.DAYSCOUNT, name: `${resp?.ABFULLNAME}` };
          }),
          "Attendance Days"
        );

        dispatch(
          setAttendanceData({
            data: attendanceOptions,
            error: false,
            loading: false,
          })
        );
        dispatch(
          setAttendanceBreakData({
            data: attendanceBreakOptions,
            error: false,
            loading: false,
          })
        );
        dispatch(
          setAttendanceDaysData({
            data: attendanceDaysPizzaPieOptions,
            error: false,
            loading: false,
          })
        );
      }
    );
  }

  // remove duplicates from all business units
  const businessUnit = allEmployees
    ?.filter(
      (ele, ind) =>
        ind ===
        allEmployees.findIndex(
          (elem) => elem.BFBU === ele.BFBU && elem.BUDESC === ele.BUDESC
        )
    )
    ?.sort((a, b) => parseFloat(a.BFBU) - parseFloat(b.BFBU));

  // remove duplicates from all departments
  const departmentFilter = allEmployees?.filter(
    (ele, ind) =>
      ind === allEmployees.findIndex((elem) => elem.BFDPT === ele.BFDPT)
  );

  const empDeptFiltered = allEmployees?.filter((dep) =>
    HRFilter?.businessUnit?.includes(dep?.BFBU)
  );
  // get departments without duplicates with same business unit chosed
  const department = HRFilter?.businessUnit?.length
    ? allEmployees
        ?.filter((dep) => HRFilter?.businessUnit?.includes(dep?.BFBU))
        ?.filter(
          (ele, ind) =>
            ind ===
            empDeptFiltered.findIndex((elem) => elem.BFDPT === ele.BFDPT)
        )
    : departmentFilter;

  // remove duplicates from all positions
  const positionFilter = allEmployees?.filter(
    (ele, ind) =>
      ind === allEmployees.findIndex((elem) => elem.BFPOST === ele.BFPOST)
  );

  const empPostFiltered = allEmployees
    ?.filter((dep) => HRFilter?.businessUnit?.includes(dep?.BFBU))
    ?.filter((pos) => HRFilter?.department?.includes(pos?.BFDPT));

  // get positions without duplicates with same departments chosed
  const position =
    HRFilter?.businessUnit?.length && HRFilter?.department?.length
      ? allEmployees
          ?.filter((dep) => HRFilter?.businessUnit?.includes(dep?.BFBU))
          ?.filter((pos) => HRFilter?.department?.includes(pos?.BFDPT))
          ?.filter(
            (ele, ind) =>
              ind ===
              empPostFiltered.findIndex((elem) => elem.BFPOST === ele.BFPOST)
          )
      : positionFilter;

  // remove duplicates from all employees
  const employeesFilter = allEmployees?.filter(
    (ele, ind) =>
      ind === allEmployees.findIndex((elem) => elem.ABLINEID === ele.ABLINEID)
  );
  // get employees without duplicates with same positions chosed
  const employees = HRFilter?.position
    ? employeesFilter?.filter((dep) =>
        HRFilter?.position?.includes(dep?.BFPOST)
      )
    : employeesFilter;
  // handle change values for check, and the dates
  const handleChange = (e, item) => {
    dispatch(
      setHRFilter({
        ...HRFilter,
        [item]: e,
      })
    );
  };
  // handle change for the value of business unit
  const handleBUChange = (e) => {
    const length = e?.length - 1 === filteringData?.businessUnit?.length;
    const empIncluded = allEmployees?.filter((dep) => e?.includes(dep?.BFBU));
    const department = !e?.includes("all")
      ? allEmployees
          ?.filter((dep) => e?.includes(dep?.BFBU))
          ?.filter(
            (ele, ind) =>
              ind === empIncluded.findIndex((elem) => elem.BFDPT === ele.BFDPT)
          )
          ?.map((f) => f?.BFDPT)
      : allEmployees
          ?.filter((dep) => e?.includes(dep?.BFBU))
          ?.filter(
            (ele, ind) =>
              ind === empIncluded.findIndex((elem) => elem.BFDPT === ele.BFDPT)
          )
          ?.map((f) => f?.BFDPT);

    const position = allEmployees
      ?.filter((dep) => e?.includes(dep?.BFBU))
      ?.filter(
        (ele, ind) =>
          ind === empIncluded.findIndex((elem) => elem.BFPOST === ele.BFPOST)
      )
      ?.map((f) => f?.BFPOST);

    if (e?.includes("all") && !length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          businessUnit: businessUnit?.map((bu) => bu?.BFBU),
          department: department,
          position: departmentFilter
            ?.filter((pos) => department?.includes(pos?.BFDPT))
            ?.map((f) => f?.BFPOST),
          employees: [],
        })
      );
    } else if (length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          businessUnit: [],
          department: [],
          position: [],
          employees: [],
        })
      );
    } else {
      dispatch(
        setHRFilter({
          ...HRFilter,
          businessUnit: e,
          department: department,
          position: position,
          employees: [],
        })
      );
    }
  };
  // handle change for the value of department
  const handleDepartmentChange = (e) => {
    const length = e?.length - 1 === filteringData?.department?.length;
    const empIncluded = allEmployees
      ?.filter((dep) => HRFilter?.businessUnit?.includes(dep?.BFBU))
      ?.filter((dep) => e?.includes(dep?.BFDPT));

    const positions = !e?.includes("all")
      ? allEmployees
          ?.filter((dep) => HRFilter?.businessUnit?.includes(dep?.BFBU))
          ?.filter((dep) => e?.includes(dep?.BFDPT))
          ?.filter(
            (ele, ind) =>
              ind ===
              empIncluded.findIndex((elem) => elem.BFPOST === ele.BFPOST)
          )
          ?.map((f) => f?.BFPOST)
      : allEmployees
          ?.filter((dep) => HRFilter?.businessUnit?.includes(dep?.BFBU))
          ?.filter((dep) => e?.includes(dep?.BFDPT))
          ?.filter(
            (ele, ind) =>
              ind ===
              empIncluded.findIndex((elem) => elem.BFPOST === ele.BFPOST)
          )
          ?.map((f) => f?.BFPOST);

    if (e?.includes("all") && !length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          department: department?.map((dep) => dep?.BFDPT),
          position: positions,
          employees: [],
        })
      );
    } else if (length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          department: [],
          position: [],
          employees: [],
        })
      );
    } else {
      dispatch(
        setHRFilter({
          ...HRFilter,
          department: e,
          position: positions,
          employees: [],
        })
      );
    }
  };
  // handle change for the value of position
  const handlePositionChange = (e) => {
    const length = e?.length - 1 === filteringData?.position?.length;
    if (e?.includes("all") && !length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          position: position?.map((pos) => pos?.BFPOST),
          employees: [],
        })
      );
    } else if (length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          position: [],
          employees: [],
        })
      );
    } else {
      dispatch(
        setHRFilter({
          ...HRFilter,
          position: e,
          employees: [],
        })
      );
    }
  };
  // handle change for the value of employees
  const handleEmployeesChange = (e) => {
    const length = e?.length - 1 === filteringData?.employees?.length;
    if (e?.includes("all") && !length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          employees: employees?.map((pos) => pos?.ABLINEID),
        })
      );
    } else if (length) {
      dispatch(
        setHRFilter({
          ...HRFilter,
          employees: [],
        })
      );
    } else {
      dispatch(
        setHRFilter({
          ...HRFilter,
          employees: e,
        })
      );
    }
  };

  const handleChanges = {
    handleChange,
    handleBUChange,
    handleDepartmentChange,
    handlePositionChange,
    handleEmployeesChange,
  };

  const filteringData = {
    businessUnit,
    department,
    position,
    employees,
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      {app === "attendance" && (
        <FilterAttendanceReturn
          filterAttendance={HRFilter}
          handleChanges={handleChanges}
          filteringData={filteringData}
          getData={getData}
        />
      )}
      {app === "masterInfo" && (
        <FilterMasterInfoReturn
          filterMasterInfo={HRFilter}
          handleChanges={handleChanges}
          filteringData={filteringData}
          setIsLoading={setIsLoading}
          socket={socket}
          setOpen={setOpen}
        />
      )}
      {app === "deptPost" && (
        <FilterDPTPOSTReturn
          filterInfo={HRFilter}
          setIsLoading={setIsLoading}
          socket={socket}
          dptPost={dptPost}
        />
      )}
      {app === "holidays" && (
        <FilterHolidaysReturn
          filterInfo={HRFilter}
          setIsLoading={setIsLoading}
          setSearched={setSearched}
        />
      )}
    </Box>
  );
};

export default FilterHRComponent;
