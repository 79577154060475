import { Box, Button, Grid } from "@mui/material";
import React from "react";
import FilterFields from "./Fields/filterFields";
import ControlsOpenRound from "./Fields/controls/controlOpenRound";
import ControlsGetLocation from "./Fields/controls/controlGetLocation";
import ControlsLockReading from "./Fields/controls/controlLockReading";

const FilterReturn = (props) => {
  const filter = props.filter;
  const setFilter = props.setFilter;

  const notControls = filter.divisionID === "";

  const controls =
    filter.divisionID === "" ||
    filter.year === "" ||
    filter.crID === "" ||
    props.typeControl === "" ||
    filter.round === "";

  const lockPerEmission =
    filter.divisionID === "" ||
    filter.year === "" ||
    filter.period === "" ||
    filter.nbOfLock === "" ||
    props.typeControl === "";

  const disabled = props.type !== "controls" ? notControls : controls;

  return (
    <Box
      sx={{
        padding: "1%",
        paddingX: "4%",
        backgroundColor: "#e2e2e2",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      {props.type !== "controls" ? (
        <FilterFields
          type={props.type}
          error={props.error}
          handleChange={props.handleChange}
          divisionData={props.divisionData}
          handlePeriod={props.handlePeriod}
          handleYear={props.handleYear}
          pathType={props.pathType}
          filter={props.filter}
          setFilter={props.setFilter}
        />
      ) : (
        <Grid>
          {props.wichControl === "openRound" && (
            <ControlsOpenRound
              divisionData={props.divisionData}
              handleChange={props.handleChange}
              handlePeriod={props.handlePeriod}
              handleYear={props.handleYear}
              filter={props.filter}
              error={props.error}
              setTypeControl={props.setTypeControl}
              typeControl={props.typeControl}
            />
          )}
          {props.wichControl === "getLocation" && (
            <Grid>
              <ControlsGetLocation
                filter={props.filter}
                handleChange={props.handleChange}
                getData={props.getData}
              />
            </Grid>
          )}
          {props.wichControl === "lockReading" && (
            <ControlsLockReading
              divisionData={props.divisionData}
              handleChange={props.handleChange}
              handlePeriod={props.handlePeriod}
              handleYear={props.handleYear}
              filter={props.filter}
              setFilter={props.setFilter}
              error={props.error}
              setTypeControl={props.setTypeControl}
              typeControl={props.typeControl}
            />
          )}
        </Grid>
      )}
      {props.wichControl !== "getLocation" && (
        <Box>
          <Grid container>
            {props.pathType === "gPath" && (
              <Grid item xs={1}>
                <Button
                  sx={{
                    backgroundColor: "#064987",
                    color: "white",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  disabled={filter.divisionID === ""}
                  onClick={() => {
                    props.getPathData({
                      type: props.type,
                      month: filter.period,
                      year: filter.year,
                      round: filter.round,
                      crid: filter.crID,
                      search: filter.search,
                      obs: filter.obs,
                      status: filter.status,
                      editix: filter.editix,
                      divid: filter.divisionID,
                    });
                  }}
                >
                  Get Path
                </Button>
              </Grid>
            )}
            <Grid item xs={props.pathType === "gPath" ? 9 : 10}></Grid>
            <Grid item xs={1}>
              <Button
                sx={{
                  borderRadius: "12px",
                  width: "100%",
                }}
                onClick={() =>
                  setFilter({
                    search: "",
                    divisionID: "",
                    period: "",
                    year: "",
                    crID: "",
                    round: "",
                    status: "All",
                    obs: "All",
                    editix: "All",
                    nbOfLock: null,
                  })
                }
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={1}>
              {props.type !== "controls" ? (
                <Button
                  sx={{
                    backgroundColor: "#064987",
                    color: "white",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  disabled={
                    filter.divisionID === "" ||
                    filter.period === "" ||
                    filter.year === ""
                  }
                  onClick={() => {
                    props.getData({
                      type: props.type,
                      month: filter.period,
                      year: filter.year,
                      round: filter.round,
                      crid: filter.crID,
                      search: filter.search,
                      obs: filter.obs,
                      status: filter.status,
                      editix: filter.editix,
                      divid: filter.divisionID,
                    });
                  }}
                >
                  Search
                </Button>
              ) : (
                <Button
                  sx={{
                    backgroundColor: "#064987",
                    color: "white",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  disabled={
                    props.typeControl === "emission"
                      ? lockPerEmission
                      : disabled
                  }
                  onClick={() => {
                    if (props.wichControl === "openRound") {
                      props.getData({
                        type: props.typeControl,
                        month: filter.period,
                        year: filter.year,
                        round: filter.round,
                        crid: filter.crID,
                        divid: filter.divisionID,
                      });
                    } else if (props.wichControl === "lockReading") {
                      props.getData({
                        lockType: props.typeControl,
                        month: filter.period,
                        year: filter.year,
                        round: filter.round,
                        crid: filter.crID,
                        bu: filter.divisionID,
                        lockNb: filter.nbOfLock,
                      });
                    }
                  }}
                >
                  {"Apply"}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FilterReturn;
