import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterAdminsPages from "./Filter";
import allData from "../../../../../Services/Data";
import BodyAdmins from "../Common/Body";
import AlertDialog from "../../components/AlertDialog";
import AddPageContent from "./AddPage";
import {
  setNotificationSnackBar,
  setUser,
} from "../../../../../Services/redux/reducers/userMenuReducer";
import {
  setAllEdits,
  setPagesData,
  setUpdatedPagesData,
} from "../../../../../Services/redux/reducers/adminsReducer";

const SuperPagesComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [beSure, setBeSure] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const dispatch = useDispatch();
  const [addPage, setAddPage] = useState({
    type: "",
    icon: "",
    platfrom: "",
    text: "",
    desc: "",
    value: "",
  });
  const [editDetails, setEditDetails] = useState({
    lineid: rowData.id,
    type: "",
    icon: "",
    platfrom: "",
    text: rowData.text,
    status: rowData.status === "Active" ? "A" : "N",
    desc: rowData.description,
    value: rowData.value,
  });
  const [filter, setFilter] = useState({
    search: "",
    platform: [],
  });

  function getData(x, req, type) {
    dispatch(setAllEdits([]));
    if (type === "pagesdetails") {
      setIsLoading(true);
    }
    allData
      .getSuperData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        req,
        type,
        x,
        "admindsp/v1/adminuser"
      )
      .then(
        (response) => {
          if (type === "pagesdetails") {
            dispatch(setPagesData(response?.data?.pagesdata));
            dispatch(setUpdatedPagesData(response?.data?.pagesdata));
            setIsLoading(false);
          } else if (type === "pageadd") {
            dispatch(
              setNotificationSnackBar({
                open: true,
                message: "Page Added Successfully!",
                type: "success",
              })
            );
            setBeSure(false);
            getData(filter, "pagesdata", "pagesdetails");
            setOpenAdd(false);
            setAddPage({
              type: "",
              icon: "",
              platfrom: "",
              text: "",
              desc: "",
              value: "",
            });
          } else if (type === "pageupdate") {
            dispatch(
              setNotificationSnackBar({
                open: true,
                message: "Page Updated Successfully!",
                type: "success",
              })
            );
            setBeSure(false);
            getData(filter, "pagesdata", "pagesdetails");
            setOpenDetails(false);
          } else if (type === "pagedelete") {
            dispatch(
              setNotificationSnackBar({
                open: true,
                message: "Page Deleted Successfully!",
                type: "success",
              })
            );
            setBeSure(false);
            getData(filter, "pagesdata", "pagesdetails");
            setIsDelete("");
            setOpenDetails(false);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: "An Error Occured!",
              type: "error",
            })
          );
        }
      );
  }

  const onButtonClick = (e, row) => {
    setOpenDetails(true);
    setRowData(row);
    setEditDetails({
      lineid: row.id,
      icon: row.icon,
      platfrom: row.platfrom,
      text: row.text,
      status: row.status === "Active" ? "A" : "N",
      desc: row.description,
      value: row.value,
    });
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
    setBeSure(false);
  };

  const handleCloseBeSure = () => {
    setBeSure(false);
  };

  const handleGetData = () => {
    dispatch(setAllEdits([]));
    getData(
      { search: filter.search, platform: filter?.platform },
      "pagesdata",
      "pagesdetails"
    );
  };

  return (
    <Box>
      <FilterAdminsPages
        getData={(x) => {
          setDetailsData([]);
          setOpenDetails(false);
          getData(x, "pagesdata", "pagesdetails");
        }}
        setOpenAdd={setOpenAdd}
        filter={filter}
        setFilter={setFilter}
      />
      <BodyAdmins
        // data={pagesData}
        isLoading={isLoading}
        httpError={httpError}
        // adminsDataColumns={pagesDataColumns}
        // _adminsData={_pagesData}
        open={openDetails}
        setOpen={setOpenDetails}
        rowData={rowData}
        detailsData={detailsData}
        getData={getData}
        getDataForCancel={handleGetData}
        admins={"pages"}
        editDetails={editDetails}
        setEditDetails={setEditDetails}
        setBeSure={setBeSure}
        isDelete={setIsDelete}
      />
      {openAdd && (
        <AlertDialog
          open={openAdd}
          handleClose={handleCloseAdd}
          title={"ADD NEW PAGE"}
          content={
            <AddPageContent
              addPage={addPage}
              setAddPage={setAddPage}
              setBeSure={setBeSure}
            />
          }
          buttonTitle={""}
        />
      )}
      {beSure && (
        <AlertDialog
          open={beSure}
          handleClose={handleCloseBeSure}
          title={"Are You Sure?"}
          content={""}
          buttonTitle={"Yes"}
          buttonClick={() =>
            getData(
              (openAdd && addPage) ||
                (openDetails && isDelete === "" && editDetails) ||
                (isDelete !== "" && { lineid: isDelete }),
              "pagesdata",
              (openAdd && "pageadd") ||
                (openDetails && isDelete === "" && "pageupdate") ||
                (isDelete !== "" && "pagedelete")
            )
          }
        />
      )}
    </Box>
  );
};

export default SuperPagesComponent;
