import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSelector } from "react-redux";

const SectionsInfo = ({ data, removeSection, handleSectionChanges }) => {
  const editedPagesData = useSelector((state) => state.admins.editedPagesData);
  const statement = editedPagesData
    ?.filter((ob) => ob?.AILINEID === data?.AILINEID)?.[0]
    ?.sections?.filter((sec) => sec?.BJLINEID === data?.BJLINEID);

  const values = (item) => {
    if (statement?.length > 0) {
      if (statement?.[0][item]) {
        return statement?.[0][item];
      } else {
        return "";
      }
    } else {
      if (data[item]) {
        return data[item];
      } else {
        return "";
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        'input[type="text"], input[type="password"]': { height: "10px" },
        ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
          WebkitTextFillColor: "rgb(0 0 0 / 61%) !important",
        },
      }}
    >
      <Grid container>
        <Grid item xs={0.5}>
          <TextField
            fullWidth
            id="id"
            label="ID"
            value={values("BJLINEID")}
            disabled
            // onChange={(e) => handleChange(e.target.value, "icon")}
          />
        </Grid>
        <Grid item xs={1 / 5}></Grid>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            id="type"
            label="Type"
            value={values("BJCOMPONENTTYPE")}
            disabled
            // onChange={(e) => handleChange(e.target.value, "icon")}
          />
        </Grid>
        <Grid item xs={1 / 5}></Grid>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            id="platform"
            label="Platform"
            value={values("AIPLATFORM")}
            disabled
          />
        </Grid>
        <Grid item xs={1 / 5}></Grid>
        <Grid item xs={3.5}>
          <TextField
            fullWidth
            id="descrption"
            label="Description"
            value={values("BJDESC")}
            onChange={(e) =>
              handleSectionChanges(e.target.value, data, "BJDESC")
            }
          />
        </Grid>
        <Grid item xs={1 / 5}></Grid>
        <Grid item xs={3.5}>
          <TextField
            fullWidth
            id="title"
            label="Title"
            value={values("BJTITLE")}
            onChange={(e) =>
              handleSectionChanges(e.target.value, data, "BJTITLE")
            }
          />
        </Grid>
        <Grid item xs={1 / 5}></Grid>
        <Grid
          item
          xs={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button onClick={removeSection}>
            <RemoveCircleIcon sx={{ color: "red" }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionsInfo;
