import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const SuperMyHRInitialState = {
  searchRequests: {
    select: false,
    addressNbr: [],
    leaveType: [],
    leaveReason: [],
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  },
  newRequest: {
    addressNbr: "",
    type: "",
    halfDay: "FD",
    reqReason: "",
    reasonComment: "",
    reqDate: moment(new Date()).format("YYYY-MM-DD"),
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    requestedDays: 1,
    contactName: "",
    contactPhone: "",
    flightStatus: false,
    attachment: [],
  },
  leaveTypes: cookies.get("_leaveTypes") ? cookies.get("_leaveTypes") : [],
  leaveReasons: cookies.get("_leaveReasons")
    ? cookies.get("_leaveReasons")
    : [],
  selectedAddressNbSearch: [],
  leaveRequestsList: [],
  balanceDays: [],
};

export const superMyHRSlice = createSlice({
  name: "superMyHR",
  initialState: SuperMyHRInitialState,
  reducers: {
    setSearchRequests: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.searchRequests = action.payload;
    },
    setNewRequest: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.newRequest = action.payload;
    },
    setLeaveTypes: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.leaveTypes = action.payload;
    },
    setLeaveReasons: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.leaveReasons = action.payload;
    },
    setSelectedAddressNbSearch: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action?.payload?.type === "new") {
        state.selectedAddressNbSearch.push(action.payload);
      } else {
        state.selectedAddressNbSearch = action.payload;
      }
    },
    setLeaveRequestsList: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.leaveRequestsList = action.payload;
    },
    setBalanceDays: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.balanceDays = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearchRequests,
  setNewRequest,
  setLeaveTypes,
  setLeaveReasons,
  setSelectedAddressNbSearch,
  setLeaveRequestsList,
  setBalanceDays,
} = superMyHRSlice.actions;

export default superMyHRSlice.reducer;
