import * as React from "react";
import { FormControlLabel, Switch } from "@mui/material";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function BasicSwitches({ checked, onChange, label }) {
  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            sx={{
              ".css-jsexje-MuiSwitch-thumb": {
                color: "black",
              },
              ".css-dnkzfa-MuiSwitch-track": {
                backgroundColor: "gray",
              },
            }}
            checked={checked}
            onChange={(e) => onChange(e)}
          />
        }
        label={label}
      />
    </div>
  );
}
