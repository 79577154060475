import React, { useState } from "react";
import { Box } from "@mui/material";
import SubscriptionShow from "./SubscriptionShow";
import SubscriptionAdd from "./SubscriptionAdd";

const SubsciptionsContent = (props) => {
  const userDetails = props.userDetails?.data?.userdetails;
  const [isAdding, setIsAdding] = useState(false);

  return (
    <Box>
      {!isAdding ? (
        <SubscriptionShow
          userDetails={userDetails}
          setIsAdding={setIsAdding}
          rowDetails={props.rowDetails}
          getdetails={props.getUserDetails}
          isLoadingDetails={props.isLoadingDetails}
          httpDetailsError={props.httpDetailsError}
        />
      ) : (
        <SubscriptionAdd
          setIsAdding={setIsAdding}
          rowDetails={props.rowDetails}
          getdetails={props.getUserDetails}
        />
      )}
    </Box>
  );
};

export default SubsciptionsContent;
