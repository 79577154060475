export const MUICheckbox = {
  "&.Mui-checked": {
    color: "white !important",
  },
};

export const MUIDialog = {
  ".css-1qxadfk-MuiPaper-root-MuiDialog-paper, .css-sd33hq": {
    backgroundColor: "#bfbfbf !important",
    minWidth: "200px !important",
    maxWidth: "675px !important",
    width: "100% !important",
  },
};

export const MUITextField = {
  ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "black",
  },
  ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: "gray",
  },
};

export const MUIGrid = {
  ".css-1s4mvio-MuiButtonBase-root-MuiButton-root, .css-1vxb0sp": {
    color: "white !important",
  },
  height: "auto",
  width: "100%",
  ".css-1rt05gm-MuiDataGrid-root, .MuiDataGrid-columnHeaderTitleContainer, .css-1rt05gm-MuiDataGrid-root, .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
    {
      display: "flex",
      justifyContent: "center",
    },
};

export const flexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const selectForm = {
  '.css-11do72n-MuiGrid-root input[type="text"], .css-11do72n-MuiGrid-root input[type="password"], .css-11do72n-MuiGrid-root .css-1dxv3pf-MuiGrid-root, .css-11do72n-MuiGrid-root .react-tel-input, .css-11do72n-MuiGrid-root .flag-dropdown, .css-11do72n-MuiGrid-root .css-1dxv3pf-MuiGrid-root, .css-11do72n-MuiGrid-root .react-tel-input, .css-11do72n-MuiGrid-root .form-control, .css-11do72n-MuiGrid-root label[data-shrink=false]+.MuiInputBase-formControl, .css-11do72n-MuiGrid-root .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
    { minHeight: "100%" },
};
