import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import moment from "moment";
import { MUICheckbox } from "../../../Styles/overrides";

const FilterAttendanceReturn = ({
  filterAttendance,
  handleChanges,
  filteringData,
  getData,
}) => {
  const alignmenmt = {
    display: "flex",
    alignItems: "center",
    ".css-sde65v-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "36.7px",
    },
    ".css-11fo197": {
      color: "white",
    },
    ".css-vamk89-MuiFormControl-root": { width: "100%" },
  };

  const disableSearch = filterAttendance?.employees?.length <= 0;
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredOptions = filteringData?.employees?.filter(
    (option) =>
      option?.ABFULLNAME?.toLowerCase()?.includes(searchTerm) ||
      option?.ABLINEID?.toString()?.includes(searchTerm)
  );

  return (
    <Grid container sx={alignmenmt}>
      <Grid container sx={{ marginBottom: "1%" }}>
        <Grid item xs={1.8} sx={alignmenmt} container>
          <Grid item xs={5.5}>
            <FormControl
              sx={{
                width: "100%",
              }}
              size="small"
            >
              <InputLabel>Check</InputLabel>
              <Select
                label="Check"
                width="100%"
                value={filterAttendance?.check}
                onChange={(e) =>
                  handleChanges.handleChange(e.target.value, "check")
                }
              >
                <MenuItem key={"all"} value={"all"}>
                  All
                </MenuItem>
                <MenuItem key={"checkin"} value={"checkin"}>
                  Check In
                </MenuItem>
                <MenuItem key={"checkout"} value={"checkout"}>
                  Check Out
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5.5}>
            <FormControl
              sx={{
                width: "100%",
              }}
              size="small"
            >
              <InputLabel>Break</InputLabel>
              <Select
                label="Check"
                width="100%"
                value={filterAttendance?.break}
                onChange={(e) =>
                  handleChanges.handleChange(e.target.value, "break")
                }
              >
                <MenuItem key={"all"} value={"all"}>
                  All
                </MenuItem>
                <MenuItem key={"breakin"} value={"breakin"}>
                  Break In
                </MenuItem>
                <MenuItem key={"breakout"} value={"breakout"}>
                  Break Out
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Business Unit
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterAttendance.businessUnit?.length <= 0
                  ? []
                  : filterAttendance.businessUnit
              }
              onChange={(e) => handleChanges.handleBUChange(e.target.value)}
              input={<OutlinedInput label="Business Unit" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterAttendance.businessUnit?.length ===
                    filteringData?.businessUnit?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteringData.businessUnit?.map((unit) => (
                <MenuItem key={unit?.BFBU} value={unit?.BFBU}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      filterAttendance.businessUnit?.indexOf(unit?.BFBU) > -1
                    }
                  />
                  <ListItemText primary={`${unit?.BFBU} - ${unit?.BUDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Department
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterAttendance?.department?.length <= 0
                  ? []
                  : filterAttendance?.department
              }
              onChange={(e) =>
                handleChanges.handleDepartmentChange(e.target.value)
              }
              input={<OutlinedInput label="Department" />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterAttendance.department?.length ===
                    filteringData?.department?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteringData.department?.map((dep) => (
                <MenuItem key={dep.BFDPT} value={dep.BFDPT}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      filterAttendance?.department?.indexOf(dep?.BFDPT) > -1
                    }
                  />
                  <ListItemText primary={`${dep.BFDPT} - ${dep.DPTDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Position</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterAttendance?.position?.length <= 0
                  ? []
                  : filterAttendance?.position
              }
              onChange={(e) =>
                handleChanges.handlePositionChange(e.target.value)
              }
              input={<OutlinedInput label="Position" />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterAttendance.position?.length ===
                    filteringData?.position?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteringData.position?.map((position) => (
                <MenuItem key={position?.BFPOST} value={position?.BFPOST}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      filterAttendance?.position?.indexOf(position?.BFPOST) > -1
                    }
                  />
                  <ListItemText
                    primary={`${position?.BFPOST} - ${position?.POSTDESC}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={dayjs(new Date())}
              slotProps={{
                textField: {
                  size: "small",
                  id: "fromdate",
                  label: "From Date",
                },
              }}
              format="YYYY-MM-DD"
              value={dayjs(filterAttendance?.fromdate)}
              onChange={(e) =>
                handleChanges.handleChange(
                  moment(e?.$d).format("YYYY-MM-DD"),
                  "fromdate"
                )
              }
              sx={{
                ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={dayjs(new Date())}
              minDate={dayjs(filterAttendance?.fromdate)}
              slotProps={{
                textField: { size: "small", id: "todate", label: "To Date" },
              }}
              sx={{
                ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
              format="YYYY-MM-DD"
              value={dayjs(filterAttendance?.todate)}
              onChange={(e) =>
                handleChanges.handleChange(
                  moment(e?.$d).format("YYYY-MM-DD"),
                  "todate"
                )
              }
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10.5} sx={alignmenmt}>
          <FormControl sx={{ width: "100%", mt: 3 }}>
            <InputLabel id="searchable-select-label">Employees</InputLabel>
            <Select
              labelId="searchable-select-label"
              id="searchable-select"
              multiple
              value={
                filterAttendance?.employees?.length <= 0
                  ? []
                  : filterAttendance?.employees
              }
              onChange={(e) =>
                handleChanges.handleEmployeesChange(e.target.value)
              }
              onClick={() => setSearchTerm("")}
              input={<OutlinedInput label="Employees" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  'input[type="text"], input[type="password"]': {
                    color: "white !important",
                  },
                }}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(event) => event.stopPropagation()}
              >
                <TextField
                  variant="standard"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  onClick={(event) => event.stopPropagation()}
                  fullWidth
                />
              </MenuItem>
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    filterAttendance.employees?.length ===
                    filteringData?.employees?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {filteredOptions.map((employee) => (
                <MenuItem key={employee.ABLINEID} value={employee.ABLINEID}>
                  <Checkbox
                    checked={
                      filterAttendance?.employees.indexOf(employee.ABLINEID) >
                      -1
                    }
                    sx={MUICheckbox}
                  />
                  <ListItemText
                    primary={`${employee.ABLINEID} - ${employee.ABFULLNAME}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1} sx={alignmenmt}>
          <Button
            sx={{
              backgroundColor: !disableSearch ? "#064987" : "#afabab",
              width: "100%",
            }}
            disabled={disableSearch}
            onClick={() => getData(filterAttendance)}
          >
            <SearchIcon sx={{ color: "white" }} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterAttendanceReturn;
