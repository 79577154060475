import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIntegrationFilter } from "../../../../../../Services/redux/reducers/mrbcEmissionsReducer";
import { Period } from "../../../components/Periods";

const MMSJDEIntegrationFilter = ({ setOpenInteg }) => {
  const divisionData = useSelector((state) => state.menu.divisionData);
  const integrationFilter = useSelector(
    (state) => state.mrbcEmissions.integrationFilter
  );
  const dispatch = useDispatch();

  var startYear = 2015;
  var endYear = new Date().getFullYear();
  var _years = [];

  while (startYear <= endYear) {
    _years.push({ UVVALUE: startYear });
    startYear++;
  }

  const handleChange = (e, item) => {
    dispatch(
      setIntegrationFilter({
        ...integrationFilter,
        [item]: e,
      })
    );
  };

  const disabled =
    integrationFilter?.bu === "" ||
    integrationFilter?.period === "" ||
    integrationFilter?.year === "";

  return (
    <Box
      sx={{
        padding: "1%",
        paddingX: "4%",
        backgroundColor: "#e2e2e2",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              width="100%"
              value={integrationFilter?.emissionType}
              onChange={(e) => handleChange(e.target.value, "emissionType")}
            >
              <MenuItem key={"mr"} value={"mr"}>
                {"Meter Reading"}
              </MenuItem>
              <MenuItem key={"bc"} value={"bc"}>
                {"Bill Collection"}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Divisions</InputLabel>
            <Select
              label="Divisions"
              width="100%"
              value={integrationFilter?.bu}
              onChange={(e) => handleChange(e.target.value, "bu")}
            >
              {divisionData.map((bu) => (
                <MenuItem key={bu?.UVVALUE} value={bu?.UVVALUE}>
                  {`${bu?.UVVALUE}`} - {`${bu?.UVDESC}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1.2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Month</InputLabel>
            <Select
              label="Month"
              sx={{ width: "100%" }}
              value={integrationFilter?.period}
              onChange={(e) => handleChange(e?.target?.value, "period")}
            >
              {Period?.map((per) => (
                <MenuItem key={per?.UVVALUE} value={per?.UVVALUE}>
                  {`${per?.UVVALUE} - ${per?.UVDESC}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={0.8}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Year</InputLabel>
            <Select
              label="Year"
              sx={{ width: "100%" }}
              value={integrationFilter?.year}
              onChange={(e) => handleChange(e?.target?.value, "year")}
            >
              {_years?.map((year) => (
                <MenuItem key={year?.UVVALUE} value={year?.UVVALUE}>
                  {year?.UVVALUE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{ backgroundColor: "#064987", color: "white", width: "100%" }}
            onClick={() => setOpenInteg(true)}
            disabled={disabled}
          >
            Integrate
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MMSJDEIntegrationFilter;
