import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./userdetails.module.scss";
import allData from "../../../../../Services/Data";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../../components/AlertDialog";
import {
  setNotificationSnackBar,
  setUser,
} from "../../../../../Services/redux/reducers/userMenuReducer";

const UserDetails = (props) => {
  const userAll = useSelector((state) => state.menu.userAll);
  const setOpen = props.setOpen;
  const rowData = props.rowData;
  const detailsData = props.detailsData;
  const [accessPages, setAccessPages] = useState([]);
  const [userID, setUserID] = useState("");
  const [platform, setPlatform] = useState("web");
  const [addUsername, setAddUsername] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const dispatch = useDispatch();

  const userIndex = detailsData?.usernames?.findIndex(
    (option) => option.userid === userID
  );

  const handleClose = () => {
    setIsAdd(false);
    setIsDelete(false);
    setIsUpdate(false);
  };

  const onAddingUser = () => {
    usingApi({ username: addUsername, id: rowData.id }, "useradd");
    handleClose();
  };

  const onDeletingUser = () => {
    usingApi(
      {
        username: detailsData?.usernames?.[userIndex]?.username,
        adminuserid: detailsData?.usernames?.[userIndex]?.userid,
      },
      "userdelete"
    );
    // setUserID("");
    handleClose();
  };

  function usingApi(x, type) {
    allData
      .getSuperData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "userdata",
        type,
        x,
        "admindsp/v1/adminuser"
      )
      .then(
        (response) => {
          if (response?.data?.request === "userexists") {
            dispatch(
              setNotificationSnackBar({
                open: true,
                message: "User already exists, Please Try Again!",
                type: "error",
              })
            );
          } else {
            dispatch(
              setNotificationSnackBar({
                open: true,
                message: "Operation Done Successfully!",
                type: "success",
              })
            );
            setAddUsername("");
          }
          props.getData({ id: rowData.id }, "userdata", "userdetails");
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: "Error Occured, Please Try Again!",
              type: "error",
            })
          );
        }
      );
  }

  var i;
  var _added = [];
  var _removed = [];

  const handleChange = (value) => {
    const filtered = detailsData?.allpages?.filter(
      (option) =>
        value
          ?.map((f) => f?.split(" ")?.[0])
          ?.filter((s) => Number(s) === option?.AILINEID)?.length > 0 &&
        option?.AIPLATFORM === platform
    );

    const addedPages = filtered?.filter(
      (option) =>
        detailsData?.usernames?.[userIndex]?.pages
          ?.map((op) => op?.AAPAGEID)
          .includes(option?.AILINEID) === false
    );
    const removedPages = detailsData?.usernames?.[userIndex]?.pages.filter(
      (option) =>
        filtered?.map((op) => op?.AILINEID).includes(option?.AAPAGEID) ===
          false && option?.AIPLATFORM === platform
    );
    for (i = 0; i < addedPages?.length; i++) {
      _added[i] = {
        pageid: addedPages?.[i]?.AILINEID,
        userid: detailsData?.usernames?.[userIndex]?.userid,
        action: "add",
      };
    }

    for (i = 0; i < removedPages?.length; i++) {
      _removed[i] = {
        pageid: removedPages?.[i]?.AAPAGEID,
        userid: detailsData?.usernames?.[userIndex]?.userid,
        action: "remove",
      };
    }
    Array.prototype.push.apply(_added, _removed);
    setAccessPages(_added);
  };

  const onUpdate = () => {
    usingApi(accessPages, "pagesupdate");
    handleClose();
  };

  return (
    <Box sx={{ backgroundColor: "#c3c3c3", height: "100vh", padding: "3%" }}>
      <Grid container>
        <Grid item xs={11}></Grid>
        <Grid item xs={1}>
          <Button onClick={() => setOpen(false)}>
            <CancelIcon sx={{ color: "red" }} />
          </Button>
        </Grid>
      </Grid>
      <Grid
        sx={{
          marginY: "10%",
          ".css-75gcxd-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled": {
            color: "black !important",
          },
          ".css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled, .MuiOutlinedInput-notchedOutline,.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
            {
              borderColor: "black !important",
              WebkitTextFillColor: "#064987 !important",
              fontWeight: "bold",
            },
        }}
      >
        <Grid container>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="id"
              label="ID"
              value={rowData.id ? rowData.id : ""}
              disabled
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="fullname"
              label="Full Name"
              value={rowData.fullname ? rowData.fullname : ""}
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ marginTop: "5%", display: "flex", alignItems: "center" }}
        >
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="Add Username"
              label="Add Username"
              value={addUsername ? addUsername : ""}
              onChange={(e) => setAddUsername(e.target.value)}
              // disabled
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              sx={{ backgroundColor: "#064987" }}
              onClick={() => setIsAdd(true)}
              disabled={addUsername === ""}
            >
              <AddIcon
                sx={{
                  color:
                    addUsername === "" ? "rgba(255, 255, 255, 0.3)" : "white",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ marginY: "5%" }} className={styles.access}>
          Access
        </Grid>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={3.5}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Platform</InputLabel>
              <Select
                label="Platform"
                value={platform}
                width="100%"
                onChange={(e) => {
                  setUserID("");
                  setPlatform(e.target.value);
                }}
              >
                <MenuItem key={"web"} value={"web"}>
                  Web
                </MenuItem>
                <MenuItem key={"mob"} value={"mob"}>
                  Mobile
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1 / 2}></Grid>
          <Grid item xs={5.5}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Usernames</InputLabel>
              <Select
                label="Usernames"
                value={userID}
                width="100%"
                onChange={(e) => {
                  setUserID("");
                  setTimeout(() => {
                    setUserID(e.target.value);
                  }, 500);
                }}
              >
                {detailsData?.usernames?.map((data) => (
                  <MenuItem key={data.userid} value={data.userid}>
                    {data.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1 / 2}></Grid>
          <Grid item xs={2}>
            <Button
              sx={{ backgroundColor: "#064987" }}
              onClick={() => setIsDelete(true)}
              disabled={userID === ""}
            >
              <DeleteIcon
                sx={{
                  color: userID === "" ? "rgba(255, 255, 255, 0.3)" : "white",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginY: "5%",
            ".css-1q6gkgc-MuiInputBase-root-MuiFilledInput-root:before, .css-1glbuew-MuiInputBase-root-MuiFilledInput-root:before":
              {
                borderBottomColor: "black",
              },
            ".css-6od3lo-MuiChip-label, .css-196n7va-MuiSvgIcon-root,.css-e2o3q6-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,.css-e2o3q6-MuiButtonBase-root-MuiChip-root, .MuiChip-deleteIcon ":
              {
                color: "black",
              },
            ".css-1970doy-MuiStack-root": {
              width: "100%",
            },
          }}
        >
          {userIndex >= 0 && (
            <Grid>
              <Stack spacing={3} sx={{ width: 500, marginBottom: "5%" }}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={detailsData?.allpages
                    ?.filter((plat) => plat?.AIPLATFORM === platform)
                    ?.map(
                      (option) =>
                        `${option?.AILINEID} - ${option?.AIDESC} - ${option?.AIPLATFORM}`
                    )}
                  onChange={(event, value) => handleChange(value)}
                  defaultValue={
                    detailsData?.usernames?.[userIndex]?.pages
                      ? detailsData?.usernames?.[userIndex]?.pages
                          ?.filter((plat) => plat?.AIPLATFORM === platform)
                          ?.map(
                            (option) =>
                              `${option?.AILINEID} - ${option?.AIDESC} - ${option?.AIPLATFORM}`
                          )
                      : ""
                  }
                  freeSolo
                  disableClearable
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Pages"
                      placeholder="Get Access To"
                    />
                  )}
                />
                <Grid>
                  <Button
                    sx={{
                      backgroundColor: "#064987!important",
                      color: "white",
                      width: "100%",
                    }}
                    onClick={() => setIsUpdate(true)}
                    disabled={accessPages?.length === 0}
                  >
                    Save
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
      {(isAdd || isDelete || isUpdate) && (
        <AlertDialog
          open={isAdd || isDelete || isUpdate}
          handleClose={handleClose}
          title={"Are You Sure?"}
          buttonTitle={"Yes"}
          buttonClick={
            (isAdd && onAddingUser) ||
            (isDelete && onDeletingUser) ||
            (isUpdate && onUpdate)
          }
        />
      )}
    </Box>
  );
};

export default UserDetails;
