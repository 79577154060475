import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import DashboardComponent from "../Dashboard";
import FilterComponent from "../Filter";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import allData from "../../../../../../Services/Data";
import { setUser } from "../../../../../../Services/redux/reducers/userMenuReducer";
import gettingData from "../Services/supervisorsAccess";
import { setDashboardSummary } from "../../../../../../Services/redux/reducers/LVMRBCReducer";
import { getPizzaPieOptions } from "../../../chatTickets/dashboard/ChartsOptions";

const BCDashboard = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const divisionData = useSelector((state) => state.menu.divisionData);
  const dashboardSummary = useSelector(
    (state) => state.lvmrbc.dashboardSummary
  );
  const [searchedData, setSearchedData] = useState([]);
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.menu.socket);

  useEffect(() => {
    gettingData.superAccessData(socket, dispatch);
  }, [socket]);

  function getData(x) {
    setStatus(x.status);
    dispatch(setDashboardSummary({ ...dashboardSummary, loading: true }));
    setIsLoading(true);
    setHttpError("");
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "dashdata01",
        x,
        "mrbc/spv/v1/emission"
      )
      .then(
        (response) => {
          setSearchedData(response?.data?.dashdata01);
          setIsLoading(false);
          const readingsSummaryOptions = getPizzaPieOptions(
            response?.data?.dashdata01?.dash1?.map((resp) => {
              return {
                value: resp?.count,
                name:
                  (resp?.BCACAT1 === null && "Total") ||
                  (resp?.BCACAT1 === "D" && "Done") ||
                  (resp?.BCACAT1 === "N" && "Unvisited") ||
                  (resp?.BCACAT1 === "V1" && "Visited Once") ||
                  (resp?.BCACAT1 === "R" && "Rejected") ||
                  (resp?.BCACAT1 === "V2" && "Visited Twice"),
              };
            }),
            ""
          );
          dispatch(
            setDashboardSummary({
              data: readingsSummaryOptions,
              error: false,
              loading: false,
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
          dispatch(
            setDashboardSummary({ ...dashboardSummary, loading: false })
          );
        }
      );
  }

  const chart1 = {
    labels: searchedData?.dash0?.map((data) => data.BCADATE),
    datasets: [
      {
        label:
          (status === "All" && "Total") ||
          (status === "D" && "Done") ||
          (status === "N" && "Unvisited") ||
          (status === "V1" && "Visited Once") ||
          (status === "V2" && "Visited Twice"),
        data: searchedData?.dash0?.map((data) => data.count),
        backgroundColor: "rgba(101, 3, 3, 0.8)",
      },
    ],
  };

  const chart2 = {
    labels: searchedData?.dash1?.map(
      (data) =>
        (data.BCACAT1 === null && "Total") ||
        (data.BCACAT1 === "D" && "Done") ||
        (data.BCACAT1 === "N" && "Unvisited") ||
        (data.BCACAT1 === "V1" && "Visited Once") ||
        (data.BCACAT1 === "V2" && "Visited Twice")
    ),
    datasets: [
      {
        label: "%",
        data: searchedData?.dash1?.map((data) => data.count),
        backgroundColor: [
          "rgba(129, 135, 7, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(129, 135, 7, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chart3 = {
    labels: searchedData?.dash2?.map((data) => data.BCCRID),
    datasets: [
      {
        label: "Collected",
        data: searchedData?.dash2?.map((data) => data.BCCOLLECTED),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Delivered",
        data: searchedData?.dash2?.map((data) => data.DELIVERED),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Remaining",
        data: searchedData?.dash2?.map((data) => data.REMAINING),
        backgroundColor: "black",
      },
    ],
  };

  const chart4 = {
    labels: searchedData?.dash3?.map(
      (data) => data.BCMONTH + "-" + data.BCYEAR
    ),
    datasets: [
      {
        label: "Amount",
        data: searchedData?.dash3?.map((data) => data.BCAMOUNT),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Corrected",
        data: searchedData?.dash3?.map((data) => data.BCCORRECTED),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Collected",
        data: searchedData?.dash3?.map((data) => data.BCCOLLECTED),
        backgroundColor: "black",
      },
    ],
  };

  return (
    <Box>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          color: "#064987",
          fontSize: "1.5rem",
          marginY: "1%",
        }}
      >
        Bill Collection Dashboard
      </Grid>
      <Grid>
        <FilterComponent
          divisionData={divisionData}
          getData={getData}
          // setSearchedData={setSearchedData}
          type={"bc"}
        />
      </Grid>
      {Object.keys(searchedData)?.length > 0 && !isLoading && !httpError && (
        <Grid>
          <DashboardComponent
            type={"bc"}
            searchedData={searchedData}
            chart1={chart1}
            chart2={chart2}
            chart3={chart3}
            chart4={chart4}
          />
        </Grid>
      )}
      {isLoading && (
        <Grid sx={{ display: "flex", justifyContent: "center", marginY: "4%" }}>
          <Ring size={40} lineWeight={5} speed={2} color="#064987" />
        </Grid>
      )}
      {!isLoading && httpError && (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            marginY: "4%",
            color: "#064987",
          }}
        >
          {httpError}
        </Grid>
      )}
    </Box>
  );
};

export default BCDashboard;
