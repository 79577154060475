import {
  setLeaveReasons,
  setLeaveTypes,
} from "../../../../../../Services/redux/reducers/superMyHRReducer";
import Cookies from "universal-cookie";

const UDCRequest = (cat1, cat2, type, socket, dispatch) => {
  const cookies = new Cookies();
  socket.emit(
    "dspinternal:control:data",
    {
      request: "dataAccess",
      dataAccess: {
        type: "udc",
        udc: {
          cat1: cat1,
          cat2: cat2,
        },
      },
    },
    (response) => {
      if (type === "leaveTypes") {
        dispatch(setLeaveTypes(response));
        cookies.set("_leaveTypes", response, {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
      } else {
        dispatch(setLeaveReasons(response));
        cookies.set("_leaveReasons", response, {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
      }
    }
  );
};

export default UDCRequest;
