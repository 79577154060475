import "core-js/stable";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { Box } from "@mui/material";
import { Provider } from "react-redux";
import store from "./Services/redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Box sx={{ height: "100%" }}>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
    {/* </React.StrictMode> */}
  </Box>
);

reportWebVitals();
