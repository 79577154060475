import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CRPOSAdminFilterComponent from "../FilterComponent";
import AlertDialog from "../../components/AlertDialog";
import { setNotificationSnackBar } from "../../../../../Services/redux/reducers/userMenuReducer";

const CRDBAdminComponent = () => {
  const [openEmission, setOpenEmission] = useState(false);
  const [openDB, setOpenDB] = useState(false);
  const dispatch = useDispatch();

  const crDBAdminFilter = useSelector(
    (state) => state.crposadmin.crDBAdminFilter
  );

  const socketmrbc = useSelector((state) => state.menu.socketmrbc);

  const handleClose = () => {
    setOpenEmission(false);
    setOpenDB(false);
  };

  const commonAPI = (type, typeData) => {
    socketmrbc.emit(
      "dspinternal:web:mrbc:admin:v1",
      {
        request: "mrbcPosAdmin",
        mrbcPosAdmin: {
          type: type,
          [type]: typeData,
        },
      },
      (response) => {
        setOpenEmission(false);
        setOpenDB(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
      }
    );
    if (socketmrbc.connected === false) {
      setTimeout(() => {
        setOpenEmission(false);
        setOpenDB(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: "Error Occured!",
            type: "error",
          })
        );
      }, 3000);
    }
  };

  function sendData(x) {
    if (x === "clearEmission") {
      commonAPI("emissionClear", {
        crid: crDBAdminFilter?.emissionCrid,
        month: parseInt(crDBAdminFilter?.emissionMonth, 10),
        year: crDBAdminFilter?.emissionYear,
        emissionType: crDBAdminFilter?.emissionType,
      });
    } else if (x === "clearDB") {
      commonAPI("DBClear", {
        crid: crDBAdminFilter?.dbCrid,
        dbType: crDBAdminFilter?.dbType,
      });
    }
  }

  const onSend = (x) => {
    if (x === "clearEmission") {
      setOpenEmission(true);
    } else if (x === "clearDB") {
      setOpenDB(true);
    }
  };

  const controlsJSON = [
    {
      title: "Clear Emission",
      content: (
        <Grid>
          <CRPOSAdminFilterComponent
            type={"clearEmission"}
            onSend={() => onSend("clearEmission")}
          />
          <AlertDialog
            open={openEmission}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() => sendData("clearEmission")}
          />
        </Grid>
      ),
    },
    {
      title: "Clear DB",
      content: (
        <Grid>
          <CRPOSAdminFilterComponent
            type={"clearDB"}
            onSend={() => onSend("clearDB")}
          />
          <AlertDialog
            open={openDB}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() => sendData("clearDB")}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Box>
      {controlsJSON.map((data, index) => (
        <Box key={index}>
          <Grid
            sx={{
              backgroundColor: "#064987",
              color: "white",
              height: "30px",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {data.title}
          </Grid>
          {data.content}
        </Box>
      ))}
    </Box>
  );
};

export default CRDBAdminComponent;
