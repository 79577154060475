import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  setHolidaysList,
  setHolidaysListChanges,
} from "../../../../../../Services/redux/reducers/HRReducer";
import { HandsontableTableComponent } from "../../../components/HandsontableTable";
import AlertDialog from "../../../components/AlertDialog";
import moment from "moment";
import { setNotificationSnackBar } from "../../../../../../Services/redux/reducers/userMenuReducer";

export const HolidaysBody = () => {
  const holidaysListChanges = useSelector(
    (state) => state.hr.holidaysListChanges
  );
  const dispatch = useDispatch();
  const holidaysList = useSelector((state) => state.hr.holidaysList);
  const socketHR = useSelector((state) => state.menu.socketHR);

  const [openSure, setOpenSure] = useState(false);

  const handleCloseSure = () => {
    setOpenSure(false);
  };

  const columnHeaders = ["ID", "Date", "Description"];
  var i;
  var _holidaysListData = [];

  const columns = [
    {
      data: "id",
      editor: false,
    },
    {
      data: "date",
      type: "date",
      dateFormat: "YYYY-MM-DD",
      correctFormat: true,
      datePickerConfig: {
        // First day of the week (0: Sunday, 1: Monday, etc)
        firstDay: 0,
        showWeekNumber: true,
        disableDayFn(date) {
          // Disable Sunday and Saturday
          return date.getDay() === 0 || date.getDay() === 6;
        },
      },
    },
    {
      data: "description",
      editor: "text",
    },
  ];

  for (i = 0; i < holidaysList.length; i++) {
    const state = holidaysListChanges?.filter(
      (data) => data?.BNLINEID === holidaysList?.[i]?.BNLINEID
    )?.[0];
    _holidaysListData[i] = {
      id: holidaysList?.[i]?.BNLINEID,
      date:
        state && "BNDATE" in state
          ? moment(state?.BNDATE).format("YYYY-MM-DD")
          : moment(holidaysList?.[i]?.BNDATE).format("YYYY-MM-DD"),
      description:
        state && "BNDESC" in state ? state?.BNDESC : holidaysList?.[i]?.BNDESC,
    };
  }

  const onGridChange = (e, currentTodos, currentPage, todosPerPage) => {
    const currentIndex =
      (currentPage - 1) * todosPerPage +
      (todosPerPage - (todosPerPage - e?.[0]?.[0]));
    const changedHolidays = holidaysList[currentIndex];
    const changeIndex = holidaysListChanges?.findIndex(
      (ob) => ob?.BNLINEID === changedHolidays?.BNLINEID
    );
    const headerChanged =
      (e?.[0]?.[1] === "date" && "BNDATE") ||
      (e?.[0]?.[1] === "description" && "BNDESC");
    if (changedHolidays) {
      if (
        holidaysListChanges?.filter(
          (data) => data?.BNLINEID === changedHolidays?.BNLINEID
        )?.length > 0
      ) {
        dispatch(
          setHolidaysListChanges([
            ...holidaysListChanges?.slice(0, changeIndex), // everything before current post
            {
              ...holidaysListChanges[changeIndex],
              [headerChanged]:
                headerChanged === "BNDATE"
                  ? moment(e?.[0]?.[3]).format("YYYY-MM-DD")
                  : e?.[0]?.[3],
              type: "oldChanges",
              actionType:
                changedHolidays?.actionType === "add" ? "add" : "update",
            },
            ...holidaysListChanges?.slice(changeIndex + 1), // everything after current post
          ])
        );
      } else {
        dispatch(
          setHolidaysListChanges({
            BNLINEID: changedHolidays?.BNLINEID,
            BNDATE:
              headerChanged === "BNDATE"
                ? moment(e?.[0]?.[3]).format("YYYY-MM-DD")
                : moment(changedHolidays?.BNDATE).format("YYYY-MM-DD"),
            BNDESC:
              headerChanged === "BNDESC"
                ? e?.[0]?.[3]
                : changedHolidays?.BNDESC,
            type: "newChanges",
            actionType: "update",
          })
        );
      }
    } else {
      dispatch(
        setHolidaysList([
          ...holidaysList,
          {
            BNLINEID: (holidaysList[currentIndex - 1]?.BNLINEID ?? 0) + 1,
            BNDATE:
              headerChanged === "BNDATE"
                ? e?.[0]?.[3]
                : changedHolidays?.BNDATE,
            BNDESC:
              headerChanged === "BNDESC"
                ? e?.[0]?.[3]
                : changedHolidays?.BNDESC,
            type: "newChanges",
            actionType: "add",
          },
        ])
      );
      dispatch(
        setHolidaysListChanges({
          BNLINEID: (holidaysList[currentIndex - 1]?.BNLINEID ?? 0) + 1,
          BNDATE:
            headerChanged === "BNDATE" ? e?.[0]?.[3] : changedHolidays?.BNDATE,
          BNDESC:
            headerChanged === "BNDESC" ? e?.[0]?.[3] : changedHolidays?.BNDESC,
          type: "newChanges",
          actionType: "add",
        })
      );
    }
  };

  const onRemoveRow = (e) => {
    const removedHoliday = holidaysList[e];
    dispatch(
      setHolidaysList(
        holidaysList?.filter(
          (item) => item?.BNLINEID !== removedHoliday.BNLINEID
        )
      )
    );
    dispatch(
      setHolidaysListChanges({
        BNLINEID: removedHoliday?.BNLINEID,
        type: "newChanges",
        actionType: "remove",
      })
    );
  };

  const onConfirm = () => {
    setOpenSure(false);
    socketHR?.emit(
      "dspinternal:web:hr:admin:v1",
      {
        request: "hrData",
        hrData: {
          type: "holidaysData",
          holidaysData: {
            action: "setData",
            setData: holidaysListChanges,
          },
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
        dispatch(setHolidaysListChanges([]));
      }
    );
  };

  return (
    <Grid sx={{ width: "50%" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: "2%",
          paddingX: "3%",
        }}
      >
        <Button
          onClick={() => setOpenSure(true)}
          disabled={holidaysListChanges?.length <= 0}
          sx={{ backgroundColor: "#064987", color: "white" }}
        >
          Confirm Changes
        </Button>
      </Grid>
      <HandsontableTableComponent
        colHeaders={columnHeaders}
        data={_holidaysListData}
        onGridChange={onGridChange}
        onRemoveRow={onRemoveRow}
        colWidths={[150, 200, 250]}
        columns={columns}
        specialKey1={"id"}
        specialKey2={"id"}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        buttonTitle={"Yes"}
        buttonClick={onConfirm}
      />
    </Grid>
  );
};
