import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosAdminFilter } from "../../../../../../Services/redux/reducers/CRPOSAdminReducer";

const PrintSettlementReturn = ({ onSend }) => {
  const dispatch = useDispatch();
  const posAdminFilter = useSelector(
    (state) => state.crposadmin.posAdminFilter
  );

  const onChange = (e, item) => {
    dispatch(
      setPosAdminFilter({
        ...posAdminFilter,
        [item]: e,
      })
    );
  };

  const disabled = posAdminFilter?.printSettlementCrid === "";

  return (
    <Box>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={1.5}>
          <TextField
            fullWidth
            id="printSettlementCrid"
            value={posAdminFilter?.printSettlementCrid}
            onChange={(e) => onChange(e?.target?.value, "printSettlementCrid")}
            label="CR ID"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter CR ID.."
          />
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{ backgroundColor: "#064987", color: "white", width: "100%" }}
            onClick={onSend}
            disabled={disabled}
          >
            Print
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrintSettlementReturn;
