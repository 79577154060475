export const BarToolbox = {
  feature: {
    saveAsImage: {
      name: "Chart",
      show: true,
      title: "Save as Image",
      // name: 'Results Over Time', // need to set dynamic
      type: "jpeg",
      backgroundColor: "#FFFFFF",
      pixelRatio: 2,
    },
    dataZoom: {
      show: true,
      title: {
        zoom: "Zoom",
        back: "Restore Zoom",
      },
    },
    dataView: {
      show: true,
      title: "View Data",
      readOnly: true,
      lang: ["View Data", "Close", "Refresh"],
    },
    magicType: {
      show: true,
      title: {
        line: "Line Chart",
        bar: "Bar Chart",
        tiled: "Tiled Chart",
      },
      type: ["line", "bar", "tiled"],
    },
    restore: { show: true, title: "Restore" },
  },
};

export const PieToolbox = {
  feature: {
    saveAsImage: {
      name: "Chart",
      show: true,
      title: "Save as Image",
      // name: 'Share of Topics', // need to set dynamic
      type: "jpeg",
      backgroundColor: "#FFFFFF",
      pixelRatio: 2,
    },
    dataView: {
      show: true,
      readOnly: true,
      title: "View Data",
      lang: ["View Data", "Close", "Refresh"],
    },
    restore: { show: true, title: "Restore" },
  },
};

export function getBarOptions(data, labels) {
  return {
    tooltip: {
      show: true,
    },
    toolbox: BarToolbox,
    xAxis: {
      type: "category",
      data: labels,
    },
    yAxis: {
      type: "value",
    },
    series: data,
    legend: {
      y: "bottom",
      show: true,
    },
  };
}

export function getPieOptions(data) {
  return {
    tooltip: {
      show: true,
    },
    // toolbox: PieToolbox,
    series: {
      type: "pie",
      data: data,
      label: {
        position: "outer",
        alignTo: "none",
        bleedMargin: 5,
      },
      top: "10%",
      bottom: "10%",
    },
    legend: {
      top: "bottom",
      show: true,
    },
  };
}

export function getDoughnutOptions(data, title) {
  return {
    title: { text: title },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "8%",
      left: "center",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };
}

export function getPizzaPieOptions(data, title) {
  return {
    title: {
      text: title,
    },
    tooltip: {},
    legend: {
      // right: "right",
      // bottom: 0,
      x: 0,
      y: "bottom",
      type: "scroll",
    },
    series: [
      {
        name: "",
        type: "pie",
        selectedMode: "single",
        selectedOffset: 30,
        clockwise: true,
        label: {
          fontSize: 18,
          color: "#235894",
        },
        labelLine: {
          lineStyle: {
            color: "#235894",
          },
        },
        data: data,
        itemStyle: {
          opacity: 0.7,
          // color: {
          //   image: piePatternImg,
          //   repeat: "repeat",
          // },
          borderWidth: 3,
          borderColor: "#235894",
        },
        // right: "20%",
        bottom: "10%",
      },
    ],
  };
}

export const WordCloudOptions = {
  type: "wordCloud",
  shape: "circle",
  sizeRange: [12, 35],
  rotationRange: [0, 0],
  width: "100%",
  height: "100%",
  top: "10%",
  bottom: "10%",
  drawOutOfBound: false,
  gridSize: 8,
  textStyle: {
    normal: {
      fontFamily: "sans-serif",
      fontWeight: "bold",
      // Color can be a callback function or a color string
      color: function () {
        // Random color
        return (
          "rgb(" +
          [
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160),
          ].join(",") +
          ")"
        );
      },
    },
    emphasis: {
      shadowBlur: 1,
      shadowColor: "#333",
    },
  },
};

export function getBarColumnsOptions(data, xAxisData, title, subtext) {
  return {
    title: {
      text: title,
      subtext: subtext ? subtext : "",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: { orient: "horizontal", x: 0, y: "bottom", type: "scroll" },
    grid: {
      // left: "20%",
      // right: "4%",
      // bottom: "3%",
      containLabel: true,
      // top: "10%",
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    series: data ? data : [],
  };
}

export function getFunnelChartOptions(data, legend) {
  return {
    title: {
      text: "Closure time needed for last 10 tickets",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c}min",
    },
    legend: {
      data: data
        ?.map((data) => `${data?.name}`)
        ?.sort((a, b) => parseFloat(b) - parseFloat(a)),
      orient: "vertical",
      x: "right",
      y: "center",
    },
    series: [
      {
        name: "Funnel",
        type: "funnel",
        left: "10%",
        top: 60,
        bottom: 60,
        width: "80%",
        min: 0,
        max: 100,
        minSize: "0%",
        maxSize: "100%",
        sort: "ascending",
        gap: 2,
        label: {
          show: false,
          position: "inside",
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: "solid",
          },
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1,
        },
        emphasis: {
          label: {
            fontSize: 20,
          },
        },
        data: data,
      },
    ],
  };
}
