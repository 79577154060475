import { Box, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { HandsontableTableComponent } from "../../HandsontableTable";
import moment from "moment";

const LeaveRequestBody = () => {
  const leaveRequestsList = useSelector(
    (state) => state.superMyHR.leaveRequestsList
  );

  var i;
  var _leaveRequestsListData = [];
  // Column Headers
  const columnHeaders = [
    "No.",
    "Name",
    "Address",
    "Phone Nbr",
    "Business Unit",
    "Department",
    "Position",
    "Reason",
    "Type",
    "Request Date",
    "From",
    "To",
    "Balance",
    "Duration",
    "Requested Days",
  ];
  // All columns
  const columns = [
    {
      data: "ABLINEID",
      editor: false,
    },
    {
      data: "ABFULLNAME",
      editor: false,
    },
    {
      data: "ABADDRESS1",
      editor: false,
    },
    {
      data: "ABPHONENBR",
      editor: false,
    },
    {
      data: "BUDESC",
      editor: false,
    },
    {
      data: "DPTDESC",
      editor: false,
    },
    {
      data: "POSTDESC",
      editor: false,
    },
    {
      data: "REASONDESC",
      editor: false,
    },
    {
      data: "TYPEDESC",
      editor: false,
    },
    {
      data: "BUDATE1",
      editor: false,
    },
    {
      data: "BUDATE2",
      editor: false,
    },
    {
      data: "BUDATE3",
      editor: false,
    },
    {
      data: "BUCAT5",
      editor: false,
    },
    {
      data: "BUCAT6",
      editor: false,
    },
    {
      data: "BUCAT7",
      editor: false,
    },
  ];
  // to set the data of the grid
  for (i = 0; i < leaveRequestsList?.length; i++) {
    _leaveRequestsListData[i] = {
      ABLINEID: leaveRequestsList?.[i]?.ABLINEID,
      ABFULLNAME: leaveRequestsList?.[i]?.ABFULLNAME,
      ABADDRESS1: leaveRequestsList?.[i]?.ABADDRESS1,
      ABPHONENBR: leaveRequestsList?.[i]?.ABPHONENBR,
      BUDESC: leaveRequestsList?.[i]?.BUDESC,
      DPTDESC: leaveRequestsList?.[i]?.DPTDESC,
      POSTDESC: leaveRequestsList?.[i]?.POSTDESC,
      REASONDESC: leaveRequestsList?.[i]?.REASONDESC,
      TYPEDESC: leaveRequestsList?.[i]?.TYPEDESC,
      BUDATE1: moment(leaveRequestsList?.[i]?.BUDATE1)?.format("DD-MM-YYYY"),
      BUDATE2: moment(leaveRequestsList?.[i]?.BUDATE2)?.format("DD-MM-YYYY"),
      BUDATE3: moment(leaveRequestsList?.[i]?.BUDATE3)?.format("DD-MM-YYYY"),
      BUCAT5: leaveRequestsList?.[i]?.BUCAT5,
      BUCAT6: leaveRequestsList?.[i]?.BUCAT6 === "HD" ? "Half Day" : "Full Day",
      BUCAT7: leaveRequestsList?.[i]?.BUCAT7,
    };
  }
  return (
    <Box sx={{ padding: "5%" }}>
      <Grid sx={{ width: "100%" }}>
        <HandsontableTableComponent
          colHeaders={columnHeaders}
          //   onGridChange={onGridChange}
          data={_leaveRequestsListData}
          columns={columns}
          colWidths={[
            60, 200, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 100, 150,
            100,
          ]}
          //   onClick={function (r, c) {
          //     var data = this.getDataAtRow(r);
          //     setSearchedAddressNbr(data);
          //   }}
          type={"readOnly"}
          specialKey1={"ABLINEID"}
          specialKey2={"ABLINEID"}
        />
      </Grid>
    </Box>
  );
};

export default LeaveRequestBody;
