import { Box } from "@mui/material";
import React from "react";
import AlertDialog from "../../components/AlertDialog";
import SubsciptionsContent from "./Items/Subscription";

const DetailsPopUp = (props) => {
  const openDetails = props.openDetails;
  const onClose = props.onClose;

  const content = (
    // <BasicTabs
    //   // tab1={"User"}
    //   tab2={"Subscriptions"}
    //   // item1={<UserContent rowDetails={props.rowDetails} />}
    //   item2={
    <SubsciptionsContent
      userDetails={props.userDetails}
      rowDetails={props.rowDetails}
      getUserDetails={props.getUserDetails}
      isLoadingDetails={props.isLoadingDetails}
      httpDetailsError={props.httpDetailsError}
    />
    //   }
    // />
  );

  return (
    <Box>
      <AlertDialog
        open={openDetails}
        handleClose={onClose}
        title={"Subscriptions"}
        content={content}
        buttonTitle={""}
      />
    </Box>
  );
};

export default DetailsPopUp;
