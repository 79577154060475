import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmissionsFilter,
  setEmissionsList,
} from "../../../../../../Services/redux/reducers/mrbcEmissionsReducer";
import { MUICheckbox } from "../../../Styles/overrides";

const FilterEmissions = ({ setIsLoading, setOpenNewOne }) => {
  const divisionData = useSelector((state) => state.menu.divisionData);
  const emissionsFilter = useSelector(
    (state) => state.mrbcEmissions.emissionsFilter
  );
  const socketmrbc = useSelector((state) => state.menu.socketmrbc);

  const allStatus = [
    { text: "Open", value: "A" },
    { text: "Close", value: "N" },
  ];

  const allTypes = [
    { text: "Low Voltage (LV)", value: "LV" },
    { text: "Medium Voltage (MV)", value: "MV" },
  ];

  const dispatch = useDispatch();

  const handleDivisionsChange = (e, item) => {
    const selectAll = e.includes("all");
    const allItemsSelected =
      divisionData?.length === emissionsFilter?.divisionsID?.length;

    if (selectAll && !allItemsSelected) {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: divisionData?.map((data) => data?.UVDESC),
        })
      );
    } else if (selectAll && allItemsSelected) {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: [],
        })
      );
    } else {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: e,
        })
      );
    }
  };

  const handleStatusChange = (e, item) => {
    const selectAll = e.includes("all");
    const allItemsSelected =
      allStatus?.length === emissionsFilter?.status?.length;

    if (selectAll && !allItemsSelected) {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: allStatus?.map((data) => data?.text),
        })
      );
    } else if (selectAll && allItemsSelected) {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: [],
        })
      );
    } else {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: e,
        })
      );
    }
  };

  const handleTypesChange = (e, item) => {
    const selectAll = e.includes("all");
    const allItemsSelected = allTypes?.length === emissionsFilter?.type?.length;

    if (selectAll && !allItemsSelected) {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: allTypes?.map((data) => data?.text),
        })
      );
    } else if (selectAll && allItemsSelected) {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: [],
        })
      );
    } else {
      dispatch(
        setEmissionsFilter({
          ...emissionsFilter,
          [item]: e,
        })
      );
    }
  };

  const sendData = () => {
    setIsLoading(true);
    const divisionsIDs = divisionData
      ?.filter((data) => emissionsFilter?.divisionsID?.includes(data?.UVDESC))
      ?.map((v) => v?.UVVALUE);
    const statusValues = allStatus
      ?.filter((data) => emissionsFilter?.status?.includes(data?.text))
      ?.map((v) => v?.value);
    const typesValues = allTypes
      ?.filter((data) => emissionsFilter?.type?.includes(data?.text))
      ?.map((v) => v?.value);
    socketmrbc.emit(
      "dspinternal:web:mrbc:admin:v1",
      {
        request: "mrbcEmission",
        mrbcEmission: {
          type: "emissionList",
          emissionList: {
            bu: divisionsIDs,
            status: statusValues,
            emissionType: typesValues,
          },
        },
      },
      (response) => {
        dispatch(setEmissionsList(response));
        setIsLoading(false);
      }
    );
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={1.5}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Type</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                emissionsFilter?.type?.length <= 0 ? [] : emissionsFilter?.type
              }
              onChange={(e) => handleTypesChange(e.target.value, "type")}
              input={<OutlinedInput label="Type" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={allTypes?.length === emissionsFilter?.type?.length}
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {allTypes?.map((type) => (
                <MenuItem key={type?.text} value={type?.text}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={emissionsFilter?.type?.indexOf(type?.text) > -1}
                  />
                  <ListItemText primary={`${type?.text}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Division</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                emissionsFilter?.divisionsID?.length <= 0
                  ? []
                  : emissionsFilter?.divisionsID
              }
              onChange={(e) =>
                handleDivisionsChange(e.target.value, "divisionsID")
              }
              input={<OutlinedInput label="Division" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    divisionData?.length ===
                    emissionsFilter?.divisionsID?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {divisionData?.map((div) => (
                <MenuItem key={div?.UVDESC} value={div?.UVDESC}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      emissionsFilter?.divisionsID?.indexOf(div?.UVDESC) > -1
                    }
                  />
                  <ListItemText primary={`${div?.UVVALUE} - ${div?.UVDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1.5}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                emissionsFilter?.status?.length <= 0
                  ? []
                  : emissionsFilter?.status
              }
              onChange={(e) => handleStatusChange(e.target.value, "status")}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={
                    allStatus?.length === emissionsFilter?.status?.length
                  }
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {allStatus?.map((status) => (
                <MenuItem key={status?.text} value={status?.text}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={
                      emissionsFilter?.status?.indexOf(status?.text) > -1
                    }
                  />
                  <ListItemText primary={status?.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={sendData}
            disabled={
              emissionsFilter?.divisionsID?.length <= 0 ||
              emissionsFilter?.status?.length <= 0 ||
              emissionsFilter?.type?.length <= 0
            }
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => setOpenNewOne(true)}
          >
            Import New Emission
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterEmissions;
