import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useRef, useState } from "react";
import Handsontable from "handsontable";

// register Handsontable's modules
registerAllModules();

export const HandsontableTableComponent = ({
  colHeaders,
  data,
  onGridChange,
  columns,
  onRemoveRow,
  onClick,
  type,
  colWidths,
  specialKey1,
  specialKey2,
}) => {
  const hotRef = useRef(null); // Create a ref
  const hotRef2 = useRef(null); // Create a ref

  const [currentPage, setCurrentPage] = useState(1);
  const [todosPerPage, setTodosPerPage] = useState(10);
  const pageNumbers = [];

  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = data.slice(indexOfFirstTodo, indexOfLastTodo);
  // choosing a page from the select
  function handleClick(event) {
    setCurrentPage(Number(event));
  }
  // go to the previous page
  function goPrevious() {
    setCurrentPage(currentPage - 1);
  }
  // go to the next page
  function goNext() {
    setCurrentPage(currentPage + 1);
  }

  for (let i = 1; i <= Math.ceil(data.length / todosPerPage); i++) {
    pageNumbers.push(i);
  }
  // handling export for grid
  const handleExport = () => {
    const exportPlugin = hotRef2.current.hotInstance.getPlugin("exportFile"); // Access the plugin when hot is initialized
    exportPlugin.downloadFile("csv", {
      bom: false,
      columnDelimiter: ",",
      columnHeaders: false,
      exportHiddenColumns: true,
      exportHiddenRows: true,
      fileExtension: "csv",
      filename: "Handsontable-CSV-file_[YYYY]-[MM]-[DD]",
      mimeType: "text/csv",
      rowDelimiter: "\r\n",
      rowHeaders: true,
    });
  };

  const templateValues = ["", "", ""];

  function isEmptyRow(instance, row) {
    const rowData = instance.countRows();

    for (let i = 0, ilen = rowData.length; i < ilen; i++) {
      if (rowData[i] !== null) {
        return false;
      }
    }

    return true;
  }

  function defaultValueRenderer(instance, td, row, col) {
    const args = arguments;

    if (args[5] === null && isEmptyRow(instance, row)) {
      args[5] = templateValues[col];
      td.style.color = "#999";
    } else {
      td.style.color = "";
    }

    Handsontable.renderers.TextRenderer.apply(this, args);
  }

  return (
    <Grid
      sx={{
        width: "100%",
        padding: type === "readOnly" ? "0" : "3%",
        textAlign: "center",
      }}
    >
      <Grid sx={{ textAlign: "right", marginBottom: "2%" }}>
        <Button
          sx={{ backgroundColor: "#064987", color: "white" }}
          onClick={handleExport}
        >
          Export
        </Button>
      </Grid>
      <HotTable
        ref={hotRef} // Attach the ref to HotTable
        id="hot"
        data={currentTodos}
        filters={true}
        dropdownMenu={["filter_by_value", "filter_action_bar"]}
        colWidths={colWidths}
        rowHeaderWidth={50}
        rowHeaders={true}
        rowHeights={45}
        minRows={currentTodos?.length < 10 ? currentTodos?.length : 10}
        colHeaders={colHeaders}
        columns={columns}
        beforeChange={(e) =>
          onGridChange(e, currentTodos, currentPage, todosPerPage)
        }
        afterRemoveRow={(e) => onRemoveRow(e)}
        contextMenu={["remove_row", "copy"]}
        height={"auto"}
        width={"100%"}
        autoWrapRow={true}
        autoWrapCol={true}
        licenseKey="non-commercial-and-evaluation"
        // cells={(row, col, prop) => {
        //   const cellProperties = {};

        //   cellProperties.renderer = defaultValueRenderer;

        //   return cellProperties;
        // }}
        minSpareRows={
          type === "readOnly" ||
          (currentTodos?.length === todosPerPage &&
            currentTodos[currentTodos.length - 1]?.[specialKey1] !==
              data[data.length - 1]?.[specialKey2])
            ? "0"
            : "1"
        }
        afterSelection={onClick}
        selectionMode={type === "readOnly" && "single"}
        dragToScroll={true}
        manualRowResize={true}
      />
      <Grid sx={{ display: "none" }}>
        <HotTable
          ref={hotRef2} // Attach the ref to HotTable
          id="hot2"
          data={data}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2%",
        }}
      >
        {currentTodos?.[0]?.[specialKey1] !== data?.[0]?.[specialKey2] && (
          <Button onClick={goPrevious}>Prev</Button>
        )}
        <FormControl
          sx={{
            width: "9%",
          }}
          size="small"
        >
          <InputLabel>No.</InputLabel>
          <Select
            label="Page No."
            width="100%"
            value={currentPage}
            onChange={(e) => handleClick(e.target.value)}
          >
            {pageNumbers.map((number) => (
              <MenuItem key={number} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {currentTodos[currentTodos.length - 1]?.[specialKey1] !==
          data[data.length - 1]?.[specialKey2] && (
          <Button onClick={goNext}>Next</Button>
        )}
      </Grid>
    </Grid>
  );
};
