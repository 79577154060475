import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import FilterHRComponent from "../Filter";
import { HolidaysBody } from "./Body";

const HolidaysComponent = () => {
  // get socket io
  const socketHR = useSelector((state) => state.menu.socketHR);
  // check loading when get data
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  return (
    <Box>
      {socketHR !== null && (
        <Grid>
          <FilterHRComponent
            setIsLoading={setIsLoading}
            app={"holidays"}
            setSearched={setSearched}
          />
          {!isLoading ? (
            <Grid>{searched && <HolidaysBody />}</Grid>
          ) : (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default HolidaysComponent;
