import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PagesInfo from "./Informations/PagesInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddedSections,
  setAllEdits,
  setEditedPagesData,
  setRemovedPages,
  setUpdatedPagesData,
} from "../../../../../../../Services/redux/reducers/adminsReducer";
import SectionsComp from "./Informations/Sections";

const AccordionPages = ({ data, index }) => {
  const dispatch = useDispatch();
  const updatedPagesData = useSelector(
    (state) => state.admins.updatedPagesData
  );
  const editedPagesData = useSelector((state) => state.admins.editedPagesData);
  const allEdits = useSelector((state) => state.admins.allEdits);
  const [pageExpand, setPageExpand] = React.useState(false);

  const handlePageChanges = (e, object, item) => {
    const alreadyEditedIndex = editedPagesData.findIndex(
      (ob) => ob?.AILINEID === object?.AILINEID
    );

    const allEditsIndex = allEdits.findIndex(
      (ob) => ob?.type === "page" && ob?.AILINEID === object?.AILINEID
    );

    const isAdded = allEdits.map((ob) => ob?.action === "add")?.[0];

    if (allEditsIndex !== -1) {
      dispatch(
        setAllEdits([
          ...allEdits?.slice(0, allEditsIndex), // everything before current post
          {
            ...allEdits[allEditsIndex],
            [item]: e,
            action: isAdded ? "add" : "update",
            old: true,
            type: "page",
          },
          ...allEdits?.slice(allEditsIndex + 1), // everything after current post
        ])
      );
    } else {
      dispatch(
        setAllEdits({
          ...object,
          [item]: e,
          action: isAdded ? "add" : "update",
          old: false,
          type: "page",
        })
      );
    }

    if (alreadyEditedIndex !== -1) {
      const update = [
        ...editedPagesData.slice(0, alreadyEditedIndex), // everything before current post
        {
          ...editedPagesData[alreadyEditedIndex],
          [item]: e,
          action: "update",
          old: true,
          type: "page",
        },
        ...editedPagesData.slice(alreadyEditedIndex + 1), // everything after current post
      ];
      dispatch(setEditedPagesData(update)); // Assuming dispatch is available
    } else {
      // If not already edited, dispatch a new edit action
      dispatch(setEditedPagesData({ ...object, [item]: e, type: "newEdit" }));
    }
  };

  const toggleAcordion = () => {
    setPageExpand((prev) => !prev);
  };
  const addSection = (data) => {
    const rowsInput = {
      AICAT1: null,
      AICAT2: null,
      AIDESC: data?.AIDESC,
      AIICON: data?.AIICON,
      AILINEID: data?.AILINEID,
      AIPLATFORM: data?.AIPLATFORM,
      AISTATUS: data?.AISTATUS,
      AITEXT: data?.AITEXT,
      AITYPE: data?.AITYPE,
      AIVALUE: data?.AIVALUE,
      BJCOMPONENTTYPE: "section",
      BJDESC: "",
      BJIMAGE: null,
      BJLINEID: Math.random(),
      BJPAGEID: data?.AILINEID,
      BJROUTE: null,
      BJSECTIONID: null,
      BJTITLE: "",
      type: "newSectionEdit",
      index: index,
      pages: [],
      action: "add",
    };
    // Assuming pagesData is accessible and is an array of page objects each having a 'sections' array
    const updatePagesData = updatedPagesData.map((page) => {
      if (data?.AILINEID === page?.AILINEID) {
        dispatch(setAddedSections(rowsInput));
        dispatch(setAllEdits({ ...rowsInput, type: "section" }));
        return {
          ...page, // Spread the existing page properties
          sections: [...page.sections, rowsInput], // Append new section to the existing sections array
        };
      } else {
        return { ...page };
      }
    });
    dispatch(setUpdatedPagesData(updatePagesData));
    // If you need to update the state with this new array
  };

  const removePage = (index, pageID) => {
    const rows = [...updatedPagesData];
    rows.splice(index, 1);
    dispatch(setUpdatedPagesData(rows));
    dispatch(
      setRemovedPages({ type: "page", action: "remove", AILINEID: pageID })
    );
    dispatch(setAllEdits({ type: "page", action: "remove", AILINEID: pageID }));
  };

  return (
    <Accordion
      sx={{
        ".css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded,.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root, .css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded:focus,.css-15v22id-MuiAccordionDetails-root":
          {
            backgroundColor: "white !important",
          },
        ".css-havevq-MuiSvgIcon-root, .css-h4os0j.Mui-disabled,.css-1n3veo1": {
          color: "black",
        },
        ".css-izq3bt.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: "black",
        },
        ".css-x63n5h:last-of-type": {
          backgroundColor: "white !important",
        },
        backgroundColor: "white !important",
        ".css-h4os0j.Mui-disabled": {
          WebkitTextFillColor: "gray",
        },
      }}
      expanded={pageExpand}
    >
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon onClick={toggleAcordion} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <PagesInfo
          data={data}
          removePage={() => removePage(index, data.AILINEID)}
          handlePageChanges={handlePageChanges}
        />
      </AccordionSummary>
      <AccordionDetails>
        {data?.sections?.map((section, sectionIndex) => (
          <div key={section?.BJLINEID}>
            <div
              style={{
                fontWeight: "600",
                fontSize: "1.1rem",
                color: "black",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              Section #{sectionIndex + 1}
            </div>
            <SectionsComp
              data={data}
              section={section}
              sectionIndex={sectionIndex}
              index={index}
            />

            <Divider sx={{ backgroundColor: "white" }} />
          </div>
        ))}
        <Button
          sx={{ color: "white", backgroundColor: "#064987", marginTop: "1%" }}
          onClick={() => addSection(data)}
        >
          Add New Section
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionPages;
