import { configureStore } from "@reduxjs/toolkit";
import menuReducer from "./reducers/userMenuReducer";
import ticketsReducer from "./reducers/ticketsReducer";
import HRReducer from "./reducers/HRReducer";
import adminsReducer from "./reducers/adminsReducer";
import mrbcEmissionsReducer from "./reducers/mrbcEmissionsReducer";
import lvMrbcReducer from "./reducers/LVMRBCReducer";
import MVMRBCReducer from "./reducers/MVMRBCReducer";
import superMyHRReducer from "./reducers/superMyHRReducer";
import CRPOSAdminReducer from "./reducers/CRPOSAdminReducer";

const store = configureStore({
  reducer: {
    menu: menuReducer,
    ticket: ticketsReducer,
    hr: HRReducer,
    admins: adminsReducer,
    mrbcEmissions: mrbcEmissionsReducer,
    lvmrbc: lvMrbcReducer,
    mvmrbc: MVMRBCReducer,
    superMyHR: superMyHRReducer,
    crposadmin: CRPOSAdminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "menu/setSocket",
          "menu/setSocketLabels",
          "menu/setClient",
          "menu/setSocketMRBC",
          "menu/setSocketHR",
          "menu/setSocketAdmin",
        ],
        ignoredPaths: [
          "menu.socket",
          "menu.socketLabels",
          "menu.client",
          "menu.socketmrbc",
          "menu.socketHR",
          "menu.socketadmin",
        ],
      },
    }),
});

export default store;
