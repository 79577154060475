import { setDivisionData } from "../../../../../../Services/redux/reducers/userMenuReducer";
import Cookies from "universal-cookie";

function superAccessData(socket, dispatch) {
  const cookies = new Cookies();
  socket?.emit(
    "dspinternal:control:data",
    {
      request: "dataAccess",
      dataAccess: {
        type: "udcAccess",
        udcAccess: {
          cat1: "PBL",
          cat2: "DIV",
        },
      },
    },
    (response) => {
      dispatch(setDivisionData(response));
      cookies.set("_divisionData", response, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
    }
  );
}

const gettingData = {
  superAccessData,
};

export default gettingData;
