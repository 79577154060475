import React, { useState } from "react";
import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { setUsersSecurityList } from "../../../../../../Services/redux/reducers/adminsReducer";

const UsersSecurityFilter = ({ setIsLoading }) => {
  const socketadmin = useSelector((state) => state.menu.socketadmin);

  const alignmenmt = {
    display: "flex",
    alignItems: "center",
    ".css-sde65v-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "36.7px",
    },
    ".css-11fo197": {
      color: "white",
    },
    ".css-vamk89-MuiFormControl-root": { width: "100%" },
  };

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setSearch(e);
  };

  function getData() {
    setIsLoading(true);
    socketadmin.emit(
      "dspinternal:web:admin:usr:v1",
      {
        request: "userSecurity",
        userSecurity: {
          type: "getData",
          getData: {
            search: search,
          },
        },
      },
      (response) => {
        setIsLoading(false);
        dispatch(setUsersSecurityList(response?.data));
      }
    );
  }

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={alignmenmt}>
        <Grid container sx={{ marginBottom: "1%" }}>
          <Grid item xs={3} sx={alignmenmt}>
            <TextField
              fullWidth
              id="search"
              value={search}
              onChange={(e) => handleChange(e.target.value)}
              label="Search"
              variant="outlined"
              autoComplete="off"
              placeholder="Enter Search Text.."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1} sx={alignmenmt}>
            <Button
              sx={{
                backgroundColor: "#064987",
                width: "100%",
                color: "white",
              }}
              onClick={() => getData()}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersSecurityFilter;
