import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

// get today's date
var today = new Date();
// get the date 2 weeks before
today?.setDate(today.getDate() - 14);

const initialBar = { data: [], error: undefined, loading: true };

const HRInitialState = {
  allEmployees: [],
  masterAllEmployees: [],
  HRFilter: {
    search: "",
    check: "all",
    break: "all",
    businessUnit: "",
    department: "",
    position: "",
    employees: [],
    fromdate: moment(today).format("YYYY-MM-DD"),
    todate: moment(new Date()).format("YYYY-MM-DD"),
  },
  attendanceData: initialBar,
  attendanceBreakData: initialBar,
  attendanceDaysData: initialBar,
  attendanceDetails: [],
  masterInfoData: [],
  masterInfoDataChanges: [],
  addNewAddressBookData: {
    title: "",
    bu: "",
    dpt: "",
    post: "",
    fullName: "",
    phoneCode: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    email: "",
    idNumber: "",
  },
  departmentsChanges: [],
  positionsChanges: [],
  holidaysList: [],
  holidaysListChanges: [],
};

export const HRSlice = createSlice({
  name: "HR",
  initialState: HRInitialState,
  reducers: {
    setAllEmployees: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.allEmployees = action.payload;
    },
    setMasterAllEmployees: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.masterAllEmployees = action.payload;
    },
    setHRFilter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.HRFilter = action.payload;
    },
    setAttendanceData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceData = action.payload;
    },
    setMasterInfoData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.masterInfoData = action.payload;
    },
    setAttendanceBreakData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceBreakData = action.payload;
    },
    setAttendanceDaysData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceDaysData = action.payload;
    },
    setAttendanceDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceDetails = action.payload;
    },
    setMasterInfoDataChanges: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload.type === "newChanges") {
        state.masterInfoDataChanges.push(action.payload);
      } else {
        state.masterInfoDataChanges = action.payload;
      }
    },
    setAddNewAddressBookData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.addNewAddressBookData = action.payload;
    },
    setDepartmentsChanges: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload.type === "newChanges") {
        state.departmentsChanges.push(action.payload);
      } else {
        state.departmentsChanges = action.payload;
      }
    },
    setPositionsChanges: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload.type === "newChanges") {
        state.positionsChanges.push(action.payload);
      } else {
        state.positionsChanges = action.payload;
      }
    },
    setHolidaysList: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.holidaysList = action.payload;
    },
    setHolidaysListChanges: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload.type === "newChanges") {
        state.holidaysListChanges.push(action.payload);
      } else {
        state.holidaysListChanges = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllEmployees,
  setMasterAllEmployees,
  setHRFilter,
  setAttendanceDetails,
  setAttendanceData,
  setAttendanceBreakData,
  setAttendanceDaysData,
  setMasterInfoData,
  setMasterInfoDataChanges,
  setAddNewAddressBookData,
  setDepartmentsChanges,
  setPositionsChanges,
  setHolidaysList,
  setHolidaysListChanges,
} = HRSlice.actions;

export default HRSlice.reducer;
