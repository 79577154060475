import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import gettingData from "../../../mrbc/MrbcItems/Services/supervisorsAccess";
import { useDispatch, useSelector } from "react-redux";
import MVComponentFilter from "./Filter";
import AlertDialog from "../../../components/AlertDialog";
import ADDNewAVIS from "./NewAVIS";
import { setNewAvisMVData } from "../../../../../../Services/redux/reducers/MVMRBCReducer";
import moment from "moment";
import { Ring } from "@uiball/loaders";
import { setNotificationSnackBar } from "../../../../../../Services/redux/reducers/userMenuReducer";
import BodyMVAvisComponent from "./Body";

const MVAVISComponent = () => {
  const socket = useSelector((state) => state.menu.socket);
  const socketmrbc = useSelector((state) => state.menu.socketmrbc);
  const newAvisMVData = useSelector((state) => state.mvmrbc.newAvisMVData);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openAdding, setOpenAdding] = useState(false);

  useEffect(() => {
    gettingData.superAccessData(socket, dispatch);
  }, [socket]);

  const handleClose = () => {
    setOpenAdding(false);
    dispatch(
      setNewAvisMVData({
        divisionID: "",
        period: "",
        year: "",
        amount: "",
        currency: "",
        avisNb: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  };

  const onAddNewAVIS = () => {
    socketmrbc?.emit(
      "dspinternal:web:mrbc:spv:v1",
      {
        request: "mrbcEmission",
        mrbcEmission: {
          type: "mvAvisEntry",
          mvAvisEntry: {
            bu: newAvisMVData?.divisionID,
            period: newAvisMVData?.period,
            year: newAvisMVData?.year,
            avisDate: newAvisMVData?.date,
            currency: newAvisMVData?.currency,
            amount: newAvisMVData?.amount,
            avisNbr: newAvisMVData?.avisNb,
          },
        },
      },
      (response) => {
        if (response?.replyType === "message") {
          if (response?.message?.type === "success") {
            dispatch(
              setNewAvisMVData({
                divisionID: "",
                type: "",
                period: "",
                year: "",
                sayrafa: "",
                amount: "",
                date: moment(new Date()).format("YYYY-MM-DD"),
              })
            );
          }
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: response?.message?.text,
              type: response?.message?.type,
            })
          );
        }
        setOpenAdding(false);
      }
    );
  };

  const disabled =
    newAvisMVData?.divisionID === "" ||
    newAvisMVData?.period === "" ||
    newAvisMVData?.year === "" ||
    newAvisMVData?.amount === "" ||
    newAvisMVData?.currency === "" ||
    newAvisMVData?.avisNb === "";

  return (
    <Box>
      <Grid>
        <MVComponentFilter
          setIsLoading={setIsLoading}
          setOpenAdding={setOpenAdding}
        />
      </Grid>
      {!isLoading ? (
        <Grid sx={{ padding: "3%" }}>
          <BodyMVAvisComponent />
        </Grid>
      ) : (
        <Grid
          sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
        >
          <Ring size={40} lineWeight={5} speed={2} color="#064987" />
        </Grid>
      )}
      {openAdding && (
        <AlertDialog
          open={openAdding}
          handleClose={handleClose}
          title={"Add New MV AVIS"}
          content={<ADDNewAVIS />}
          buttonTitle={"Add"}
          buttonDisabled={disabled}
          buttonClick={onAddNewAVIS}
        />
      )}
    </Box>
  );
};

export default MVAVISComponent;
