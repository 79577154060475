import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const MVMRBCInitialState = {
  avisMVFilter: {
    emissionStatus: [],
    divisionsID: [],
    period: [],
    year: [],
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  },
  avisMVList: [],
  newAvisMVData: {
    divisionID: "",
    period: "",
    year: "",
    amount: "",
    currency: "",
    avisNb: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
  },
};

export const MVMRBCSlice = createSlice({
  name: "mvmrbc",
  initialState: MVMRBCInitialState,
  reducers: {
    setAvisMVFilter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.avisMVFilter = action.payload;
    },
    setAvisMVList: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.avisMVList = action.payload;
    },
    setNewAvisMVData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.newAvisMVData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAvisMVFilter, setAvisMVList, setNewAvisMVData } =
  MVMRBCSlice.actions;

export default MVMRBCSlice.reducer;
