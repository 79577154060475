import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import AddPage from "./page";

const AddPageContent = (props) => {
  const addPage = props.addPage;
  const setAddPage = props.setAddPage;
  const setBeSure = props.setBeSure;

  const handleChange = (e, item) => {
    setAddPage({
      ...addPage,
      [item]: e,
    });
  };

  return (
    <Box sx={{ marginY: "3%" }}>
      <AddPage addPage={addPage} handleChange={handleChange} />
      <Grid container sx={{ marginTop: "3%" }}>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: "#064987!important",
              color: "white",
              width: "100%",
            }}
            onClick={() => setBeSure(true)}
            disabled={
              addPage.text === "" || addPage.desc === "" || addPage.value === ""
            }
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

//pageupdate //lineid - text - desc - value - status

//pageadd

export default AddPageContent;
