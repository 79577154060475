import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../table/Table";
import { Box, Button, Grid } from "@mui/material";
import moment from "moment";
import AlertDialog from "../../../components/AlertDialog";
import ClosingEmission from "./ClosingPopUp";
import { setNotificationSnackBar } from "../../../../../../Services/redux/reducers/userMenuReducer";

const BodyEmissionsComponent = () => {
  const socketmrbc = useSelector((state) => state.menu.socketmrbc);
  const emissionsList = useSelector(
    (state) => state.mrbcEmissions.emissionsList
  );
  const [openSureClose, setOpenSureClose] = useState({
    open: false,
    data: {},
  });

  const [ClosingDate, setClosingDate] = useState("");
  const dispatch = useDispatch();

  const onButtonClick = (e, params) => {
    setOpenSureClose({
      open: true,
      data: params,
    });
  };

  const handleClose = () => {
    setOpenSureClose(false);
  };

  const emissionsListColumns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "BOBU", headerName: "Business Unit ID", width: 100 },
    { field: "BU", headerName: "Business Unit", width: 150 },
    { field: "BOPERIOD", headerName: "Period", width: 150 },
    { field: "BOYEAR", headerName: "Emission Year", width: 150 },
    { field: "BOEMISSIONDATE", headerName: "Recieve Date", width: 150 },
    { field: "BOSTATUS", headerName: "Emission Status", width: 150 },
    { field: "BOKW", headerName: "KW", width: 150 },
    { field: "BOAMOUNT", headerName: "Emission Amount", width: 150 },
    { field: "BOCORRECTED", headerName: "Correction amount", width: 150 },
    { field: "BOCOLLECTED", headerName: "Collected amount", width: 150 },
    { field: "BOPERC", headerName: "Collection %", width: 150 },
    { field: "BOCOLLECTEDLBP", headerName: "Collected amount LBP", width: 150 },
    {
      field: "BOCOLLECTEDLBPPERC",
      headerName: "Collection LBP %",
      width: 150,
    },
    { field: "BOCOLLECTEDUSD", headerName: "Collected amount USD", width: 150 },
    {
      field: "BOCOLLECTEDUSDPERC",
      headerName: "Collection USD %",
      width: 150,
    },
    { field: "BOCLOSEDATE", headerName: "Closure Date", width: 200 },
    { field: "BODAYS", headerName: "Emission Days", width: 150 },
    { field: "BOSAYRAFADATE", headerName: "Sayrafa Date", width: 150 },
    { field: "BOSAYRAFARATE", headerName: "Sayrafa Rate", width: 150 },
    { field: "BOTYPE", headerName: "Emission Type", width: 150 },
    {
      field: "action",
      headerName: "Closing",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor:
              params.row.BOSTATUS === "Close" ? "#9ca1a7" : "#890202",
            width: "80%",
            height: "50%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: params.row.BOSTATUS === "Close" ? "#9ca1a7" : "white",
          }}
          onClick={(e) => onButtonClick(e, params.row)}
          disabled={params.row.BOSTATUS === "Close"}
        >
          {params.row.BOSTATUS === "Close" ? "Closed" : "Close"}
        </Button>
      ),
    },
  ];

  var i;
  var _emissionsListData = [];

  for (i = 0; i < emissionsList?.length; i++) {
    _emissionsListData[i] = {
      id: emissionsList?.[i].BOLINEID,
      BOAMOUNT: emissionsList?.[i].BOAMOUNT,
      BOBU: emissionsList?.[i].BOBU,
      BOCLOSEDATE: moment(emissionsList?.[i].BOCLOSEDATE)?.format(
        "ddd DD-MM-YYYY"
      ),
      BOCOLLECTED: emissionsList?.[i].BOCOLLECTED,
      BOCOLLECTEDLBP: emissionsList?.[i].BOCOLLECTEDLBP,
      BOCOLLECTEDLBPPERC: emissionsList?.[i].BOCOLLECTEDLBPPERC,
      BOCOLLECTEDUSD: emissionsList?.[i].BOCOLLECTEDUSD,
      BOCOLLECTEDUSDPERC: emissionsList?.[i].BOCOLLECTEDUSDPERC,
      BOCORRECTED: emissionsList?.[i].BOCORRECTED,
      BODAYS: emissionsList?.[i].BODAYS,
      BOEMISSIONDATE: moment(emissionsList?.[i].BOEMISSIONDATE)?.format(
        "ddd DD-MM-YYYY"
      ),
      BOKW: emissionsList?.[i].BOKW,
      BOPERC: emissionsList?.[i].BOPERC,
      BOPERIOD: emissionsList?.[i].BOPERIOD,
      BOSAYRAFADATE: emissionsList?.[i].BOSAYRAFADATE,
      BOSAYRAFARATE: emissionsList?.[i].BOSAYRAFARATE,
      BOSTATUS: emissionsList?.[i].BOSTATUS === "A" ? "Open" : "Close",
      BOTYPE: emissionsList?.[i].BOTYPE,
      BOYEAR: emissionsList?.[i].BOYEAR,
      BU: emissionsList?.[i].BU,
    };
  }

  const onCloseEmission = () => {
    socketmrbc?.emit(
      "dspinternal:web:mrbc:admin:v1",
      {
        request: "mrbcEmission",
        mrbcEmission: {
          type: "emissionClose",
          emissionClose: {
            bu: openSureClose?.data?.BOBU,
            period: openSureClose?.data?.BOPERIOD,
            year: openSureClose?.data?.BOYEAR,
            closeDate: ClosingDate,
          },
        },
      },
      (response) => {
        if (response?.replyType === "message") {
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: response?.message?.text,
              type: response?.message?.type,
            })
          );
        }
        setOpenSureClose({
          open: false,
          data: {},
        });
      }
    );
  };

  return (
    <Box>
      {emissionsList?.length > 0 && (
        <Grid>
          <Table columns={emissionsListColumns} _newData={_emissionsListData} />
        </Grid>
      )}
      {openSureClose?.open && (
        <AlertDialog
          open={openSureClose?.open}
          handleClose={handleClose}
          title={"Are You Sure?"}
          content={
            <ClosingEmission
              data={openSureClose?.data}
              setClosingDate={setClosingDate}
              ClosingDate={ClosingDate}
            />
          }
          buttonTitle={"Yes"}
          buttonClick={onCloseEmission}
        />
      )}
    </Box>
  );
};

export default BodyEmissionsComponent;
