import { createSlice } from "@reduxjs/toolkit";
import { userLoginInfo } from "../../GlobalVar/commonVar";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const userMenuInitialState = {
  socket: cookies.get("_socket") ? cookies.get("_socket") : null,
  socketLabels: cookies.get("_socketLabels")
    ? cookies.get("_socketLabels")
    : null,
  socketmrbc: cookies.get("_socketmrbc") ? cookies.get("_socketmrbc") : null,
  socketHR: cookies.get("_socketHR") ? cookies.get("_socketHR") : null,
  socketadmin: cookies.get("_socketadmin") ? cookies.get("_socketadmin") : null,
  client: cookies.get("_client") ? cookies.get("_client") : null,
  user: cookies.get("_user") ? cookies.get("_user") : false,
  divisionData: cookies.get("_divisionData")
    ? cookies.get("_divisionData")
    : [],
  userMenu: JSON.parse(localStorage.getItem("_userMenu"))
    ? JSON.parse(localStorage.getItem("_userMenu"))
    : "-",
  userInfo: cookies.get("_userInfo") ? cookies.get("_userInfo") : "-",
  userData: JSON.parse(localStorage.getItem("_userData"))
    ? JSON.parse(localStorage.getItem("_userData"))
    : "-",
  userAll: cookies.get("_userAll") ? cookies.get("_userAll") : "-",
  selectedPage: cookies.get("_selectedPage")
    ? cookies.get("_selectedPage")
    : JSON.parse(localStorage.getItem("_userMenu"))?.[0]?.AIDESC,
  selectedApp: cookies.get("_selectedApp")
    ? cookies.get("_selectedApp")
    : JSON.parse(localStorage.getItem("_userMenu"))?.[0]?.AIDESC,
  conversationMessages: [
    {
      ticketid: 0,
      sender: {
        senderName: "",
        avatar: "link",
        senderId: 3,
      },
      message: {
        status: "sent",
        messageDay: "2023-12-11",
        messageTime: "04:00:01",
        messageId: Math.random(),
        type: "text",
        text: "",
      },
    },
  ],
  selectedConversation: {},
  snackbarForgetPassword: {
    open: false,
    severity: "",
    message: "",
  },
  notificationSnackBar: {
    open: false,
    severity: "",
    message: "",
  },
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: userMenuInitialState,
  reducers: {
    setSocket: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.socket = action.payload;
    },
    setSocketLabels: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.socketLabels = action.payload;
    },
    setSocketMRBC: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.socketmrbc = action.payload;
    },
    setSocketHR: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.socketHR = action.payload;
    },
    setSocketAdmin: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.socketadmin = action.payload;
    },
    setClient: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.client = action.payload;
    },
    setUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload === false) {
        state.user = action.payload;
        cookies.remove("_user");
        state.userMenu = [];
        cookies.remove("_userMenu");
        state.userData = [];
        cookies.remove("_userData");
        state.userAll = [];
        cookies.remove("_userAll");
        state.userInfo = [];
        cookies.remove("_userInfo");
        state.selectedPage = [];
        cookies.remove("_selectedPage");
        state.divisionData = [];
        cookies.remove("_divisionData");
        cookies.set("_location", "/main", {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
        if (state.socket) {
          state.socket.disconnect();
        }
        if (state.client) {
          state.client.end();
        }
        state.socket = null;
        cookies.remove("_socket");
      } else {
        state.user = action.payload;
      }
    },
    setDivisionData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.divisionData = action.payload;
    },
    setUserMenu: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userMenu = action.payload;
    },
    setUserInfo: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userInfo = action.payload;
    },
    setUserAll: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userAll = action.payload;
    },
    setUserData: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userData = userLoginInfo[0];
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSelectedApp: (state, action) => {
      state.selectedApp = action.payload;
    },
    setConversationHistory: (state, action) => {
      state.conversationMessages = action.payload;
    },
    setConversationNewMessages: (state, action) => {
      state.conversationMessages.push(action.payload);
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    setSnackBarForgetPassword: (state, action) => {
      state.snackbarForgetPassword = action.payload;
    },
    setNotificationSnackBar: (state, action) => {
      state.notificationSnackBar = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSocket,
  setSocketLabels,
  setSocketMRBC,
  setSocketHR,
  setSocketAdmin,
  setClient,
  setUser,
  setDivisionData,
  setUserMenu,
  setUserInfo,
  setUserData,
  setSelectedPage,
  setSelectedApp,
  setUserAll,
  setConversationHistory,
  setConversationNewMessages,
  setSelectedConversation,
  setSnackBarForgetPassword,
  setNotificationSnackBar,
} = menuSlice.actions;

export default menuSlice.reducer;
