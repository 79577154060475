import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid } from "@mui/material";
import LocationMap from "../../../components/LocationMap";
import { LoadScript } from "@react-google-maps/api";
import MapPath from "../../../components/LocationMap/MapPath";
import { useSelector } from "react-redux";
import SwipeableTextMobileStepper from "../../../components/Carousel";
import moment from "moment";
import { MUIDialog } from "../../../Styles/overrides";

function MIPopUp(props) {
  const data = props.detailsData;
  const detailsMedia = useSelector((state) => state.lvmrbc.detailsMedia);

  const imageTime = detailsMedia?.map((media) =>
    moment(media?.BPDATETIME)?.format("DD-MM-YYYY hh:mm")
  );

  const images = detailsMedia?.map((media) => media?.BPURL);

  const readingData = props.readingData;

  var j;
  var _readData = [];

  for (j = 0; j < readingData?.length; j++) {
    _readData[j] = {
      location: {
        id: `${readingData?.[j]?.id}`,
        lat: Number(readingData?.[j]?.lat),
        lng: Number(readingData?.[j]?.lon),
      },
    };
  }

  const center = {
    lat: Number(data?.lat),
    lng: Number(data?.lon),
  };

  return (
    <Box>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={MUIDialog}
      >
        {!props.openPath && (
          <DialogTitle
            id="alert-dialog-title"
            display={"flex"}
            justifyContent={"center"}
          >
            {props.isMap ? "Location" : "Images"}
          </DialogTitle>
        )}
        {props.openPath && (
          <DialogTitle
            id="alert-dialog-title"
            display={"flex"}
            justifyContent={"center"}
          >
            {"Path"}
          </DialogTitle>
        )}
        <DialogContent>
          <Grid id="alert-dialog-description">
            <Grid
              margin={"2%"}
              sx={{
                ".MuiInputLabel-root": { color: "white !important" },
                ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                  borderColor: "white !important",
                  width: "100%",
                },
              }}
            >
              {!props.openPath && (
                <Grid>
                  {props.isMap && (center.lat !== 0 || center.lng !== 0) ? (
                    <Grid>
                      <LocationMap center={center} height={"400px"} />
                    </Grid>
                  ) : (
                    <Grid sx={{ display: "flex", justifyContent: "center" }}>
                      {/* <img src="/logo512.png" width={"50%"} alt="" /> */}
                      {detailsMedia?.length > 0 ? (
                        <SwipeableTextMobileStepper
                          images={images}
                          text={imageTime}
                        />
                      ) : (
                        <Grid>No Media to show!</Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
              {props.openPath && readingData?.length > 0 && (
                <Grid>
                  {window.google === undefined ? (
                    <LoadScript
                      googleMapsApiKey={
                        "AIzaSyAxn4MYHO8iJSrFdWS8Fdx_tLp1VRrjmnw"
                      }
                    >
                      <MapPath data={_readData} />
                    </LoadScript>
                  ) : (
                    <MapPath data={_readData} />
                  )}
                </Grid>
              )}
              {props.openPath && readingData?.length <= 0 && (
                <Grid>No Path To Show!</Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button>See Photo</Button> */}
          <Button autoFocus onClick={props.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MIPopUp;
