import React from "react";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { Box } from "@mui/material";
import AddNewLeaveRequestForm from "./AddNewLeaveRequestForm";
import AlertDialog from "../../AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  setNewRequest,
  setSearchRequests,
  setSelectedAddressNbSearch,
} from "../../../../../../Services/redux/reducers/superMyHRReducer";
import SearchByAddressNbr from "../SearchByAddressNumber";
import moment from "moment";
import PreviewFiles from "./PreviewFiles";

const AddNewLeaveRequest = ({
  open,
  setOpen,
  type,
  onApplyRequest,
  setOpenAddressNbrSearch,
  openAddressNbrSearch,
  getBalance,
}) => {
  const [openSure, setOpenSure] = useState(false);
  const userInfo = useSelector((state) => state.menu.userInfo);
  const newRequest = useSelector((state) => state.superMyHR.newRequest);
  const searchRequests = useSelector((state) => state.superMyHR.searchRequests);
  const [searchedAddressNbr, setSearchedAddressNbr] = useState("");
  const balanceDays = useSelector((state) => state.superMyHR.balanceDays);
  const dispatch = useDispatch();
  const socketHR = useSelector((state) => state.menu.socketHR);
  const [openFilesPreview, setOpenFilesPreview] = useState(false);
  // employee info
  const [employeeInfo, setEmployeeInfo] = useState({
    isExist: false,
    data: type === "myHR" ? userInfo : {},
  });
  const selectedAddressNbSearch = useSelector(
    (state) => state.superMyHR.selectedAddressNbSearch
  );
  // on closing new leave req popup
  const handleClose = () => {
    if (type !== "myHR") {
      setOpenAddressNbrSearch({ isOpen: false, type: "" });
      setEmployeeInfo({ isExist: false, data: {} });
    }
    setOpen(false);
    setOpenSure(false);
    dispatch(
      setNewRequest({
        addressNbr: "",
        type: "",
        halfDay: "FD",
        reqReason: "",
        reasonComment: "",
        reqDate: moment(new Date()).format("YYYY-MM-DD"),
        fromDate: moment(new Date()).format("YYYY-MM-DD"),
        toDate: moment(new Date()).format("YYYY-MM-DD"),
        requestedDays: 1,
        contactName: "",
        contactPhone: "",
        flightStatus: false,
        attachment: [],
      })
    );
  };

  // on closing address number
  const handleAddressNbrClose = () => {
    setOpenAddressNbrSearch({ isOpen: false, type: "" });
    if (type !== "myHR") {
      setEmployeeInfo({ isExist: false, data: {} });
    }
  };
  // on closing address number
  const handleFilesPreviewClose = () => {
    setOpenFilesPreview(false);
  };
  // on closing are you sure popup
  const handleCloseSure = () => {
    setOpenSure(false);
  };
  // handling adding new leave request
  const sureClicked = () => {
    onApplyRequest(newRequest);
    setOpen(false);
    setOpenSure(false);
    handleClose();
  };
  // to check if the address nbr exists
  const onCheckAddressNbr = (e) => {
    socketHR?.emit(
      "dspinternal:web:hr:admin:v1",
      {
        request: "addressBook",
        addressBook: {
          type: "checkId",
          checkId: {
            idType: "E", //type 'E' if you are checking for employee existince
            addressNbr: e, //addressnumber of employee
          },
        },
      },
      (response) => {
        if (response?.data?.length > 0) {
          setEmployeeInfo({
            isExist: true,
            data: response?.data?.[0],
          });
        } else {
          setEmployeeInfo({
            isExist: false,
            data: {},
          });
        }
        getBalance([e]);
      }
    );
  };
  // on selecting an address nbr from the search
  const onSelectAddressNbr = () => {
    if (openAddressNbrSearch?.type === "filterSearch") {
      dispatch(
        setSearchRequests({
          ...searchRequests,
          addressNbr: selectedAddressNbSearch?.map((x) => x?.ABLINEID),
        })
      );
    }
    if (type !== "myHR") {
      getBalance([searchedAddressNbr?.[0]]);
    }
    dispatch(
      setNewRequest({
        ...newRequest,
        addressNbr: searchedAddressNbr?.[0],
      })
    );
    setOpenAddressNbrSearch({ isOpen: false, type: "" });
    onCheckAddressNbr(searchedAddressNbr?.[0]);
    dispatch(setSelectedAddressNbSearch([]));
  };
  // get the calculation of requested days nbr
  const handleRequestedDays = (addressNbr, fromDate, toDate, halfDay) => {
    socketHR.emit(
      "dspinternal:web:hr:spv:v1",
      {
        request: "hrForms",
        hrForms: {
          type: "leaveData",
          leaveData: {
            action: "leaveDaysCalc",
            addressNbr: addressNbr,
            fromDate: fromDate,
            toDate: toDate,
            halfDay: halfDay,
          },
        },
      },
      (response) => {
        dispatch(
          setNewRequest({
            ...newRequest,
            requestedDays: response?.data,
            fromDate: fromDate,
            toDate: toDate,
            halfDay: halfDay,
          })
        );
      }
    );
  };

  const sendButtonDisabled =
    (newRequest?.requestedDays > balanceDays?.[0]?.BVVALUE &&
      balanceDays?.[0]?.BVVALUE !== 0) ||
    newRequest?.type === "" ||
    newRequest?.reqReason === "" ||
    newRequest?.contactName === "" ||
    newRequest?.contactPhone === "";

  return (
    <Box>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        title={"Leave Request"}
        content={
          <AddNewLeaveRequestForm
            type={type}
            setOpen={setOpenAddressNbrSearch}
            onCheckAddressNbr={onCheckAddressNbr}
            employeeInfo={employeeInfo}
            handleRequestedDays={handleRequestedDays}
            setOpenFilesPreview={setOpenFilesPreview}
          />
        }
        buttonTitle={"Send"}
        buttonClick={() => setOpenSure(true)}
        width={"1000px !important"}
        buttonDisabled={sendButtonDisabled}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        buttonTitle={"Yes"}
        buttonClick={sureClicked}
      />
      {openAddressNbrSearch && (
        <AlertDialog
          open={openAddressNbrSearch?.isOpen}
          handleClose={handleAddressNbrClose}
          title={"Address Number"}
          content={
            <SearchByAddressNbr
              setSearchedAddressNbr={setSearchedAddressNbr}
              openAddressNbrSearch={openAddressNbrSearch}
            />
          }
          buttonTitle={"Select"}
          buttonClick={onSelectAddressNbr}
          width={"900px !important"}
          // buttonDisabled={buttonDisabled}
        />
      )}
      <AlertDialog
        open={openFilesPreview}
        handleClose={handleFilesPreviewClose}
        title={"Files Preview"}
        content={
          <PreviewFiles
            setOpenFilesPreview={setOpenFilesPreview}
            openFilesPreview={openFilesPreview}
          />
        }
        buttonTitle={""}
        width={"900px !important"}
        // buttonDisabled={buttonDisabled}
      />
    </Box>
  );
};

export default AddNewLeaveRequest;
