import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPositionsChanges } from "../../../../../../../Services/redux/reducers/HRReducer";
import { HandsontableTableComponent } from "../../../../components/HandsontableTable";
import { setPositions } from "../../../../../../../Services/redux/reducers/adminsReducer";
import { setNotificationSnackBar } from "../../../../../../../Services/redux/reducers/userMenuReducer";
import AlertDialog from "../../../../components/AlertDialog";
import { useState } from "react";

export const BodyPositions = ({ socket }) => {
  const positionsChanges = useSelector((state) => state.hr.positionsChanges);
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.admins.positions);

  const [openSure, setOpenSure] = useState(false);

  const handleCloseSure = () => {
    setOpenSure(false);
  };

  const columnHeaders = ["ID", "Value", "Description"];
  var i;
  var _positionsData = [];

  const columns = [
    {
      data: "id",
      editor: false,
    },
    {
      data: "value",
      editor: "text",
    },
    {
      data: "description",
      editor: "text",
    },
  ];

  for (i = 0; i < positions.length; i++) {
    const state = positionsChanges?.filter(
      (data) => data?.UVID === positions?.[i]?.UVID
    )?.[0];
    _positionsData[i] = {
      id: positions?.[i]?.UVID,
      value:
        state && "UVVALUE" in state ? state?.UVVALUE : positions?.[i]?.UVVALUE,
      description:
        state && "UVDESC" in state ? state?.UVDESC : positions?.[i]?.UVDESC,
    };
  }

  const onGridChange = (e, currentTodos, currentPage, todosPerPage) => {
    const currentIndex =
      (currentPage - 1) * todosPerPage +
      (todosPerPage - (todosPerPage - e?.[0]?.[0]));
    const changedPositions = positions[currentIndex];
    const changeIndex = positionsChanges?.findIndex(
      (ob) => ob?.UVID === changedPositions?.UVID
    );
    const headerChanged =
      (e?.[0]?.[1] === "value" && "UVVALUE") ||
      (e?.[0]?.[1] === "description" && "UVDESC");
    if (changedPositions) {
      if (
        positionsChanges?.filter(
          (data) => data?.UVID === changedPositions?.UVID
        )?.length > 0
      ) {
        dispatch(
          setPositionsChanges([
            ...positionsChanges?.slice(0, changeIndex), // everything before current post
            {
              ...positionsChanges[changeIndex],
              [headerChanged]: e?.[0]?.[3],
              type: "oldChanges",
              action: changedPositions?.action === "add" ? "add" : "update",
            },
            ...positionsChanges?.slice(changeIndex + 1), // everything after current post
          ])
        );
      } else {
        dispatch(
          setPositionsChanges({
            UVID: changedPositions?.UVID,
            UVVALUE:
              headerChanged === "UVVALUE"
                ? e?.[0]?.[3]
                : changedPositions?.UVVALUE,
            UVDESC:
              headerChanged === "UVDESC"
                ? e?.[0]?.[3]
                : changedPositions?.UVDESC,
            UVTYPE1: changedPositions?.UVTYPE1,
            UVTYPE2: changedPositions?.UVTYPE2,
            type: "newChanges",
            action: "update",
          })
        );
      }
    } else {
      dispatch(
        setPositions([
          ...positions,
          {
            UVID: (positions[currentIndex - 1]?.UVID ?? 0) + 1,
            UVVALUE: headerChanged === "UVVALUE" ? e?.[0]?.[3] : "",
            UVDESC: headerChanged === "UVDESC" ? e?.[0]?.[3] : "",
            type: "newChanges",
            UVTYPE1: "HR",
            UVTYPE2: "POST",
            action: "add",
          },
        ])
      );
      dispatch(
        setPositionsChanges({
          UVID: (positions[currentIndex - 1]?.UVID ?? 0) + 1,
          UVVALUE: headerChanged === "UVVALUE" ? e?.[0]?.[3] : "",
          UVDESC: headerChanged === "UVDESC" ? e?.[0]?.[3] : "",
          type: "newChanges",
          UVTYPE1: "HR",
          UVTYPE2: "POST",
          action: "add",
        })
      );
    }
  };

  const onRemoveRow = (e) => {
    const removedPositions = positions[e];
    dispatch(
      setPositions(
        positions?.filter((item) => item?.UVID !== removedPositions.UVID)
      )
    );
    dispatch(
      setPositionsChanges({
        UVID: removedPositions?.UVID,
        type: "newChanges",
        action: "remove",
      })
    );
  };

  const onConfirm = () => {
    socket?.emit(
      "dspinternal:control:data",
      {
        request: "dataAccess",
        dataAccess: {
          type: "udcUpdate",
          udcUpdate: positionsChanges,
        },
      },
      (response) => {
        setOpenSure(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.type,
          })
        );
        dispatch(setPositionsChanges([]));
      }
    );
  };

  return (
    <Grid sx={{ width: "50%" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: "2%",
          paddingX: "3%",
        }}
      >
        <Button
          onClick={() => setOpenSure(true)}
          disabled={positionsChanges?.length <= 0}
          sx={{ backgroundColor: "#064987", color: "white" }}
        >
          Confirm Changes
        </Button>
      </Grid>
      <HandsontableTableComponent
        colHeaders={columnHeaders}
        data={_positionsData}
        onGridChange={onGridChange}
        onRemoveRow={onRemoveRow}
        columns={columns}
        colWidths={[100, 200, 300]}
        specialKey1={"id"}
        specialKey2={"id"}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        buttonTitle={"Yes"}
        buttonClick={onConfirm}
      />
    </Grid>
  );
};
