import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Cookies from "universal-cookie";

const Header = ({ subtitle }) => {
  const cookies = new Cookies();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography variant="h2" color="white" fontWeight="bold">
        {cookies.get("_selectedApp")}
      </Typography>
      <Typography variant="h5" color={colors.black}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
