import { createSlice } from "@reduxjs/toolkit";

const initialBar = { data: [], error: undefined, loading: true };

const LVMRBCInitialState = {
  readingData: [],
  collectionData: [],
  detailsMedia: [],
  dashboardSummary: initialBar,
};

export const LVMRBCSlice = createSlice({
  name: "lvmrbc",
  initialState: LVMRBCInitialState,
  reducers: {
    setReadingData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.readingData = action.payload;
    },
    setCollectionData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.collectionData = action.payload;
    },
    setDetailsMedia: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.detailsMedia = action.payload;
    },
    setDashboardSummary: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.dashboardSummary = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setReadingData,
  setCollectionData,
  setDetailsMedia,
  setDashboardSummary,
} = LVMRBCSlice.actions;

export default LVMRBCSlice.reducer;
