import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { MUICheckbox } from "../../../Styles/overrides";

const FilterAdminsPages = (props) => {
  const getData = props.getData;
  const setOpenAdd = props.setOpenAdd;
  const filter = props.filter;
  const setFilter = props.setFilter;

  const platforms = [
    {
      UVDESC: "Mobile",
      UVVALUE: "mob",
    },
    {
      UVDESC: "Web",
      UVVALUE: "web",
    },
  ];

  const handleChange = (e, item) => {
    setFilter({
      ...filter,
      [item]: e,
    });
  };

  const handleSelectCheckChange = (e, item) => {
    const selectAll = e.includes("all");
    const allItemsSelected =
      item === "platform" && platforms?.length === filter?.platform?.length;

    const data = item === "platform" && platforms;

    if (selectAll && !allItemsSelected) {
      setFilter({
        ...filter,
        [item]: data?.map((data) => data?.UVVALUE),
      });
    } else if (selectAll && allItemsSelected) {
      setFilter({
        ...filter,
        [item]: [],
      });
    } else {
      setFilter({
        ...filter,
        [item]: e,
      });
    }
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Platform</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={filter?.platform?.length <= 0 ? [] : filter?.platform}
              onChange={(e) =>
                handleSelectCheckChange(e.target.value, "platform")
              }
              input={<OutlinedInput label="Platform" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox
                  sx={MUICheckbox}
                  checked={platforms?.length === filter?.platform?.length}
                />
                <ListItemText primary={"Select All"} />
              </MenuItem>
              {platforms?.map((plat) => (
                <MenuItem key={plat?.UVVALUE} value={plat?.UVVALUE}>
                  <Checkbox
                    sx={MUICheckbox}
                    checked={filter?.platform?.indexOf(plat?.UVVALUE) > -1}
                  />
                  <ListItemText
                    primary={`${plat?.UVVALUE} - ${plat?.UVDESC}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="search"
            value={filter.search}
            onChange={(e) => handleChange(e.target.value, "search")}
            label="Search"
            variant="outlined"
            autoComplete="off"
            placeholder="Search"
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => {
              getData({
                search: filter.search,
                platform: filter.platform,
              });
            }}
            // disabled={filter.search === ""}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => setOpenAdd(true)}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Box>
  );
};

export default FilterAdminsPages;
