import React, { useState } from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { Ring } from "@uiball/loaders";
import Table from "../../../table/Table";
import UserDetails from "../../Users/UserDetails";
import PagesDetails from "../../Pages/Details";
import AccordionPages from "./AccordionPages";
import { useDispatch, useSelector } from "react-redux";
import { setAllEdits } from "../../../../../../Services/redux/reducers/adminsReducer";
import AlertDialog from "../../../components/AlertDialog";
import SaveChangesReview from "./SaveChangesReview";
import { setNotificationSnackBar } from "../../../../../../Services/redux/reducers/userMenuReducer";

const BodyAdmins = (props) => {
  const updatedPagesData = useSelector(
    (state) => state.admins.updatedPagesData
  );
  const socketadmin = useSelector((state) => state.menu.socketadmin);
  const allEdits = useSelector((state) => state.admins.allEdits);
  const data = props.data;
  const open = props.open;
  const setOpen = props.setOpen;
  const rowData = props.rowData;
  const isLoading = props.isLoading;
  const httpError = props.httpError;
  const adminsDataColumns = props.adminsDataColumns;
  const _adminsData = props._adminsData;
  const detailsData = props.detailsData;
  const admins = props.admins;
  const dispatch = useDispatch();
  const [openSure, setOpenSure] = useState(false);

  const handleCloseSure = () => {
    setOpenSure(false);
  };

  const sendEditsData = () => {
    socketadmin.emit(
      "dspinternal:web:admin:usr:v1",
      {
        request: "pagesUpdate",
        pagesUpdate: allEdits,
      },
      (response) => {
        setOpenSure(false);
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: "Pages Changed Successfully!",
            type: "success",
          })
        );
        dispatch(setAllEdits([]));
        props.getDataForCancel();
      }
    );
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={open ? 8 : 12}>
          {admins === "pages" ? (
            <Grid>
              {updatedPagesData?.length > 0 && (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1%",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#064987",
                      color: "white",
                      marginRight: "1%",
                    }}
                    onClick={() => setOpenSure(true)}
                    disabled={allEdits?.length === 0}
                  >
                    Save Changes
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#9f9f9f",
                      color: "white",
                      marginRight: "1%",
                    }}
                    onClick={props.getDataForCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
              {updatedPagesData?.map((data, i) => (
                <div key={data.AILINEID} style={{ padding: "1%" }}>
                  <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                    Page #{i + 1}
                  </div>
                  <AccordionPages data={data} index={i} />
                  <Divider sx={{ backgroundColor: "white" }} />
                </div>
              ))}
            </Grid>
          ) : (
            <Grid>
              {!isLoading && !httpError && data?.length > 0 && (
                <Table columns={adminsDataColumns} _newData={_adminsData} />
              )}
            </Grid>
          )}

          {!isLoading && !httpError && data?.length <= 0 && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                marginY: "4%",
                color: "#064987",
                fontWeight: "bold",
                fontSize: "1.2em",
              }}
            >
              No Records To Display!
            </Grid>
          )}
          {isLoading && (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "4%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )}
          {!isLoading && httpError && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                marginY: "4%",
                color: "#064987",
              }}
            >
              {httpError}
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} sx={{ display: open ? "block" : "none" }}>
          {open ? (
            <Grid>
              {admins === "users" && (
                <UserDetails
                  setOpen={setOpen}
                  rowData={rowData}
                  detailsData={detailsData}
                  getData={props.getData}
                />
              )}
              {admins === "pages" && (
                <PagesDetails
                  setOpen={setOpen}
                  rowData={rowData}
                  getData={props.getData}
                  editDetails={props.editDetails}
                  setEditDetails={props.setEditDetails}
                  setBeSure={props.setBeSure}
                  isDelete={props.isDelete}
                />
              )}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {openSure && (
        <AlertDialog
          open={openSure}
          handleClose={handleCloseSure}
          title={"Are You Sure?"}
          content={<SaveChangesReview />}
          buttonTitle={"Yes"}
          buttonClick={sendEditsData}
        />
      )}
    </Box>
  );
};

export default BodyAdmins;
