import React from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Box } from "@mui/material";

function LocationMap(props) {
  const containerStyle = {
    width: "100%",
    height: props.height,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAxn4MYHO8iJSrFdWS8Fdx_tLp1VRrjmnw",
  });

  const [map, setMap] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(props.center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <Box sx={{ width: "100%" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={props.center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {open && (
          <InfoWindow
            position={props.center}
            onCloseClick={() => setOpen(false)}
          >
            <div style={{ color: "black" }}>
              <p>This is the right point!</p>
            </div>
          </InfoWindow>
        )}
        <Marker onClick={() => setOpen(true)} position={props.center} />
        {/* <></> */}
      </GoogleMap>
    </Box>
  ) : (
    <></>
  );
}

export default React.memo(LocationMap);
