import {
  setAddedApplications,
  setAddedPages,
  setAddedSections,
  setAllEdits,
  setBusinessUnits,
  setDepartments,
  setEditedPagesData,
  setPagesData,
  setPositions,
  setRemovedPages,
  setUpdatedPagesData,
  setUsersSecurityList,
  setUsersSecurityListChanges,
} from "../../../../../Services/redux/reducers/adminsReducer";
import {
  setAddNewAddressBookData,
  setAllEmployees,
  setAttendanceBreakData,
  setAttendanceData,
  setAttendanceDaysData,
  setAttendanceDetails,
  setDepartmentsChanges,
  setHRFilter,
  setHolidaysList,
  setMasterAllEmployees,
  setMasterInfoData,
  setMasterInfoDataChanges,
  setPositionsChanges,
} from "../../../../../Services/redux/reducers/HRReducer";
import {
  setCollectionData,
  setDashboardSummary,
  setDetailsMedia,
  setReadingData,
} from "../../../../../Services/redux/reducers/LVMRBCReducer";
import {
  setEmissionsFilter,
  setEmissionsList,
  setIntegrationFilter,
  setNewEmissionData,
} from "../../../../../Services/redux/reducers/mrbcEmissionsReducer";
import {
  setAvisMVFilter,
  setAvisMVList,
  setNewAvisMVData,
} from "../../../../../Services/redux/reducers/MVMRBCReducer";
import {
  setBalanceDays,
  setLeaveReasons,
  setLeaveRequestsList,
  setLeaveTypes,
  setNewRequest,
  setSearchRequests,
  setSelectedAddressNbSearch,
} from "../../../../../Services/redux/reducers/superMyHRReducer";
import moment from "moment";

const initialBar = { data: [], error: undefined, loading: true };
// get today's date
var today = new Date();
// get the date 2 weeks before
today?.setDate(today.getDate() - 14);

const RemoveReduxStates = (dispatch, cookies) => {
  // adminsReducer
  dispatch(setPagesData([]));
  dispatch(setUpdatedPagesData([]));
  dispatch(setEditedPagesData([]));
  dispatch(setAllEdits([]));
  dispatch(setAddedPages([]));
  dispatch(setRemovedPages([]));
  dispatch(setAddedSections([]));
  dispatch(setAddedApplications([]));
  dispatch(setBusinessUnits([]));
  dispatch(setDepartments([]));
  dispatch(setPositions([]));
  dispatch(setUsersSecurityList([]));
  dispatch(setUsersSecurityListChanges([]));
  // HRReducer
  dispatch(setAllEmployees([]));
  dispatch(setMasterAllEmployees([]));
  dispatch(
    setHRFilter({
      search: "",
      check: "all",
      break: "all",
      businessUnit: "",
      department: "",
      position: "",
      employees: [],
      fromdate: moment(today).format("YYYY-MM-DD"),
      todate: moment(new Date()).format("YYYY-MM-DD"),
    })
  );
  dispatch(setAttendanceData(initialBar));
  dispatch(setMasterInfoData(initialBar));
  dispatch(setAttendanceBreakData(initialBar));
  dispatch(setAttendanceDaysData([]));
  dispatch(setAttendanceDetails([]));
  dispatch(setMasterInfoDataChanges([]));
  dispatch(
    setAddNewAddressBookData({
      title: "",
      bu: "",
      dpt: "",
      post: "",
      fullName: "",
      phoneCode: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      email: "",
      idNumber: "",
    })
  );
  dispatch(setDepartmentsChanges([]));
  dispatch(setPositionsChanges([]));
  dispatch(setHolidaysList([]));
  // LVMRBCReducer
  dispatch(setReadingData([]));
  dispatch(setCollectionData([]));
  dispatch(setDetailsMedia([]));
  dispatch(setDashboardSummary(initialBar));
  // mrbcEmissionsReducer
  dispatch(
    setEmissionsFilter({
      divisionsID: [],
      status: "",
      type: "",
    })
  );
  dispatch(setEmissionsList([]));
  dispatch(
    setNewEmissionData({
      divisionID: "",
      type: "",
      period: "",
      year: "",
      sayrafa: "",
      amount: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
    })
  );
  dispatch(
    setIntegrationFilter({
      emissionType: "",
      bu: "",
      period: "",
      year: "",
    })
  );
  // MVMRBCReducer
  dispatch(
    setAvisMVFilter({
      emissionStatus: [],
      divisionsID: [],
      period: [],
      year: [],
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
    })
  );
  dispatch(setAvisMVList([]));
  dispatch(
    setNewAvisMVData({
      divisionID: "",
      period: "",
      year: "",
      amount: "",
      currency: "",
      avisNb: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
    })
  );
  // superMyHRReducer
  dispatch(
    setSearchRequests({
      select: false,
      addressNbr: [],
      leaveType: [],
      leaveReason: [],
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
    })
  );
  dispatch(
    setNewRequest({
      addressNbr: "",
      type: "",
      halfDay: "FD",
      reqReason: "",
      reasonComment: "",
      reqDate: moment(new Date()).format("YYYY-MM-DD"),
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
      requestedDays: 1,
      contactName: "",
      contactPhone: "",
      flightStatus: false,
      attachment: [],
    })
  );
  dispatch(setLeaveTypes([]));
  dispatch(setLeaveReasons([]));
  dispatch(setSelectedAddressNbSearch([]));
  dispatch(setLeaveRequestsList([]));
  dispatch(setBalanceDays([]));
  // ticketsReducer

  //   cookies.remove("_divisionData");
};

const RemoveStates = {
  RemoveReduxStates,
};

export default RemoveStates;
