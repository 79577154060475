import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HandsontableTableComponent } from "../../HandsontableTable";
import { Ring } from "@uiball/loaders";
import { setSelectedAddressNbSearch } from "../../../../../../Services/redux/reducers/superMyHRReducer";

const SearchByAddressNbr = ({
  setSearchedAddressNbr,
  openAddressNbrSearch,
}) => {
  const socketHR = useSelector((state) => state.menu.socketHR);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addressNbrByName, setAddressNbrByName] = useState([]);
  const selectedAddressNbSearch = useSelector(
    (state) => state.superMyHR.selectedAddressNbSearch
  );
  const dispatch = useDispatch();
  // address numbers column headers
  const columnHeaders = [
    "No.",
    "Name",
    "Business Unit",
    "Department",
    "Position",
  ];
  // address numbers columns
  const columns = [
    {
      data: "ABLINEID",
      editor: false,
    },
    {
      data: "ABFULLNAME",
      editor: false,
    },
    {
      data: "BUDESC",
      editor: false,
    },
    {
      data: "DPTDESC",
      editor: false,
    },
    {
      data: "POSTDESC",
      editor: false,
    },
  ];

  var i;
  var _employeeByNameData = [];
  // handling on search for address numbers
  const onSearhAddressNbr = (e) => {
    setIsLoading(true);
    socketHR?.emit(
      "dspinternal:web:hr:admin:v1",
      {
        request: "addressBook",
        addressBook: {
          type: "searchAddressBook",
          searchAddressBook: {
            idType: "E", //type 'E' if you are checking for employee existince
            name: e, //name of employee
          },
        },
      },
      (response) => {
        setAddressNbrByName(response?.data);
        setIsLoading(false);
      }
    );
  };
  // setting the data of address numbers list
  for (i = 0; i < addressNbrByName?.length; i++) {
    // to get the selected address number for the select options
    const selectedID = selectedAddressNbSearch?.map((x) => x?.ABLINEID);
    _employeeByNameData[i] = {
      select: selectedID?.includes(addressNbrByName?.[i]?.ABLINEID)
        ? true
        : false,
      ABLINEID: addressNbrByName?.[i]?.ABLINEID,
      ABFULLNAME: addressNbrByName?.[i]?.ABFULLNAME,
      BUDESC: addressNbrByName?.[i]?.BUDESC,
      DPTDESC: addressNbrByName?.[i]?.DPTDESC,
      POSTDESC: addressNbrByName?.[i]?.POSTDESC,
    };
  }
  // to handle all the grid changes
  const onGridChange = (e, currentTodos) => {
    const currentSelected = currentTodos?.[e?.[0]?.[0]];
    if (
      selectedAddressNbSearch
        ?.map((x) => x?.ABLINEID)
        ?.includes(currentSelected?.ABLINEID)
    ) {
      dispatch(
        setSelectedAddressNbSearch(
          selectedAddressNbSearch?.filter(
            (item) => item?.ABLINEID !== currentSelected?.ABLINEID
          )
        )
      );
    } else {
      dispatch(
        setSelectedAddressNbSearch({
          select: e?.[0]?.[3],
          ABLINEID: currentSelected?.ABLINEID,
          type: "new",
        })
      );
    }
  };

  return (
    <Box sx={{ marginY: "2%" }}>
      <Grid container>
        <TextField
          variant="outlined"
          size="small"
          style={{
            width: "30%",
          }}
          id="name"
          label="Name"
          value={name ? name : ""}
          onChange={(e) => setName(e.target.value)}
        />
        <Grid width={"5%"}></Grid>
        <Button
          sx={{ backgroundColor: "#064987", color: "white", width: "10%" }}
          onClick={() => onSearhAddressNbr(name)}
        >
          Search
        </Button>
      </Grid>
      {!isLoading ? (
        <Grid container>
          {_employeeByNameData?.length > 0 && (
            <Grid sx={{ width: "100%" }}>
              <HandsontableTableComponent
                colHeaders={
                  openAddressNbrSearch?.type === "filterSearch"
                    ? ["Select", ...columnHeaders]
                    : columnHeaders
                }
                onGridChange={onGridChange}
                data={_employeeByNameData}
                columns={
                  openAddressNbrSearch?.type === "filterSearch"
                    ? [
                        {
                          data: "select",
                          type: "checkbox",
                        },
                        ...columns,
                      ]
                    : columns
                }
                colWidths={
                  openAddressNbrSearch?.type === "filterSearch"
                    ? [60, 60, 200, 110, 200, 200]
                    : [60, 200, 110, 200, 200]
                }
                onClick={
                  openAddressNbrSearch?.type === "filterSearch"
                    ? false
                    : function (r, c) {
                        var data = this.getDataAtRow(r);
                        setSearchedAddressNbr(data);
                      }
                }
                type={"readOnly"}
                specialKey1={"ABLINEID"}
                specialKey2={"ABLINEID"}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
        >
          <Ring size={40} lineWeight={5} speed={2} color="#064987" />
        </Grid>
      )}
    </Box>
  );
};

export default SearchByAddressNbr;
