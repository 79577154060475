import { Box, Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import FilterComponent from "../Filter";
import Table from "../../../table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AlertDialog from "../../AlertDialog/index";
import GeneralPath from "./GeneralPath";
import { Ring } from "@uiball/loaders";
import allData from "../../../../../../Services/Data";
import { setUser } from "../../../../../../Services/redux/reducers/userMenuReducer";
import gettingData from "../Services/supervisorsAccess";
import {
  setDetailsMedia,
  setReadingData,
} from "../../../../../../Services/redux/reducers/LVMRBCReducer";

const MeterReadingComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const divisionData = useSelector((state) => state.menu.divisionData);
  const readingData = useSelector((state) => state.lvmrbc.readingData);
  const [searchedData, setSearchedData] = useState([]);
  const [pathData, setPathData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [detailsData, setDetailsData] = useState(false);
  const [moreDetailsData, setMoreDetailsData] = useState(false);
  const [isMap, setIsMap] = useState(false);
  const [openPath, setOpenPath] = useState(false);
  const [openGeneralPath, setOpenGeneralPath] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.menu.socket);

  useEffect(() => {
    gettingData.superAccessData(socket, dispatch);
  }, [socket]);

  var i;
  var j;
  var _meterReadingData = [];
  var _readingData = [];

  function getData(x) {
    setIsLoading(true);
    setHttpError("");
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "emissiondata",
        x,
        "mrbc/spv/v1/emission"
      )
      .then(
        (response) => {
          setSearchedData(response);
          setIsLoading(false);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
        }
      );
  }

  function getPathData(x) {
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "crpath",
        x,
        "mrbc/spv/v1/emission"
      )
      .then(
        (response) => {
          setPathData(response);
          setOpenGeneralPath(true);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
        }
      );
  }

  function sendMyID(x) {
    allData
      .getReadingData(`${userAll?.userid}`, `${userAll?.token}`, x, "mr")
      .then(
        (response) => {
          dispatch(setReadingData(response?.data?.notesdata?.[0]));
          dispatch(setDetailsMedia(response?.data?.notesdata?.[1]));
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
        }
      );
  }

  const onButtonClick = (e, row) => {
    setOpenDetails(true);
    setDetailsData(row);
    sendMyID(row.id);
    //do whatever you want with the row
  };

  const onMapClick = (e, row) => {
    setOpenMore(true);
    setMoreDetailsData(row);
    setIsMap(true);
  };

  const onImageClick = () => {
    setOpenMore(true);
    // setMoreDetailsData(row);
    setIsMap(false);
  };

  const onPathClick = () => {
    setOpenMore(true);
    setOpenPath(true);
  };

  const handleClose = () => {
    setOpenDetails(false);
    setOpenPath(false);
    setOpenGeneralPath(false);
    setPathData([]);
  };

  const handleMIClose = () => {
    setOpenMore(false);
    setIsMap(false);
    setOpenPath(false);
  };

  const meterReadingColumns = [
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
          }}
          onClick={(e) => onButtonClick(e, params.row)}
        >
          Details
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "period", headerName: "Period", width: 100 },
    { field: "year", headerName: "Year", width: 100 },
    { field: "villageID", headerName: "Village ID", width: 100 },
    { field: "villageName", headerName: "Village Name", width: 100 },
    { field: "round", headerName: "Round", width: 100 },
    { field: "installation", headerName: "Installation", width: 150 },
    { field: "branch", headerName: "Branch", width: 100 },
    { field: "meter", headerName: "Meter SN", width: 100 },
    { field: "customer", headerName: "Customer", width: 100 },
    { field: "crid", headerName: "CR ID", width: 100 },
    { field: "crname", headerName: "CR Name", width: 200 },
    { field: "currentbas", headerName: "Current Bas", width: 100 },
    { field: "newbas", headerName: "New Bas", width: 100 },
    { field: "obs", headerName: "OBS", width: 100 },
    { field: "obsdesc", headerName: "OBS Desc", width: 100 },
    { field: "usage", headerName: "Usage", width: 100 },
    { field: "usageDesc", headerName: "Usage Desc", width: 100 },
    { field: "editix", headerName: "Editix", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
  ];

  for (i = 0; i < searchedData?.data?.emissiondata?.length; i++) {
    const status = searchedData?.data?.emissiondata?.[i].MRCAT1;
    _meterReadingData[i] = {
      id: searchedData?.data?.emissiondata?.[i].MRID,
      period: searchedData?.data?.emissiondata?.[i].MRMONTH,
      year: searchedData?.data?.emissiondata?.[i].MRYEAR,
      villageID: searchedData?.data?.emissiondata?.[i].MRVILLAGEID,
      villageName: searchedData?.data?.emissiondata?.[i].MRVILLAGENAME,
      round: searchedData?.data?.emissiondata?.[i].MRROUND,
      installation: searchedData?.data?.emissiondata?.[i].MRINSTALLATION,
      branch: searchedData?.data?.emissiondata?.[i].MRBRANCH,
      meter: searchedData?.data?.emissiondata?.[i].MRMETERID,
      customer: searchedData?.data?.emissiondata?.[i].MRSUBNAME,
      crid: searchedData?.data?.emissiondata?.[i].MRCRID,
      crname: searchedData?.data?.emissiondata?.[i].AHALPH,
      currentbas: searchedData?.data?.emissiondata?.[i].MRCURRBAS,
      newbas: searchedData?.data?.emissiondata?.[i].MRNEWBAS,
      obs: searchedData?.data?.emissiondata?.[i].MROBSID,
      obsdesc: searchedData?.data?.emissiondata?.[i].MROBSDESC,
      usage: searchedData?.data?.emissiondata?.[i].MRUSAGEID,
      usageDesc: searchedData?.data?.emissiondata?.[i].MRUSAGEDESC,
      editix: searchedData?.data?.emissiondata?.[i].MREDITIXFLAG,
      status:
        (status === "D" && "Done") ||
        (status === "N" && "Unvisited") ||
        (status === "V1" && "Visited Once") ||
        (status === "V2" && "Visited Twice"),
    };
  }

  const readingColumns = [
    { field: "time", headerName: "Date", width: 150 },
    { field: "type", headerName: "Type", width: 100 },
    { field: "noteType", headerName: "Note Type", width: 150 },
    { field: "note", headerName: "Notes", width: 250 },
    {
      field: "map",
      headerName: "Map",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
            borderRadius: "0px",
          }}
          onClick={(e) => onMapClick(e, params.row)}
        >
          Map
        </Button>
      ),
    },
  ];

  for (j = 0; j < readingData?.length; j++) {
    const status = readingData?.[j].MRACAT1;
    _readingData[j] = {
      id: readingData?.[j].MRALINEID,
      time: readingData?.[j].MRAUPDATETIME,
      type:
        (status === "D" && "Done") ||
        (status === "N" && "Unvisited") ||
        (status === "V1" && "Visited Once") ||
        (status === "V2" && "Visited Twice"),
      noteType: readingData?.[j].BCANOTETYPE,
      note: readingData?.[j].MRANOTE,
      lat: readingData?.[j].MRALAT,
      lon: readingData?.[j].MRALONT,
    };
  }

  return (
    <Box>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          color: "#064987",
          fontSize: "1.5rem",
          marginY: "1%",
        }}
      >
        Meter Reading Operation
      </Grid>
      <Box>
        <FilterComponent
          divisionData={divisionData}
          getData={getData}
          getPathData={getPathData}
          type={"mr"}
          pathType={"gPath"}
        />
        <GeneralPath
          open={openGeneralPath}
          handleClose={handleClose}
          pathData={pathData}
        />
        {!isLoading &&
          !httpError &&
          searchedData?.data?.emissiondata?.length > 0 && (
            <Table columns={meterReadingColumns} _newData={_meterReadingData} />
          )}
        {!isLoading &&
          !httpError &&
          searchedData?.data?.emissiondata?.length <= 0 && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                marginY: "4%",
                color: "#064987",
                fontWeight: "bold",
                fontSize: "1.2em",
              }}
            >
              No Records To Display!
            </Grid>
          )}
        {isLoading && (
          <Grid
            sx={{ display: "flex", justifyContent: "center", marginY: "4%" }}
          >
            <Ring size={40} lineWeight={5} speed={2} color="#064987" />
          </Grid>
        )}
        {!isLoading && httpError && (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              marginY: "4%",
              color: "#064987",
            }}
          >
            {httpError}
          </Grid>
        )}
      </Box>
      <Box>
        <AlertDialog
          detailsData={detailsData}
          open={openDetails}
          openPop={openMore}
          moreDetailsData={moreDetailsData}
          handleClose={handleClose}
          handleMIClose={handleMIClose}
          type={"mr"}
          columns={readingColumns}
          _newData={_readingData}
          isMap={isMap}
          openPath={openPath}
          onPathClick={onPathClick}
          onImageClick={onImageClick}
        />
      </Box>
    </Box>
  );
};

export default MeterReadingComponent;
